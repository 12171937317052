import { Injectable } from "@angular/core";
import { DataSharedService } from "./data-shared.service";
import { DataExportResponse } from "../../app-model/utils/dto/data-export-response.model";
import { DateUtcTimezonePipe } from "app/app-web/app-core/date/date-utc-timezone.pipe";
import { DateService } from "app/app-web/app-core/date/date.service";
import { TranslationPipe } from "app/app-web/app-core/translation/translation.pipe";
import { PatientFamilyHistory } from "app/app-web/app-model/dto/common/patient-family-history.model";
import { PatientMedicalHistory } from "app/app-web/app-model/dto/common/patient-medical-history.model";
import { PatientAllergyHistory } from "app/app-web/app-model/dto/common/patient-allergie-history.model";
import { PatientContraceptionHistory } from "app/app-web/app-model/dto/common/patient-contraception-history.model";
import { PatientSurgicalHistory } from "app/app-web/app-model/dto/common/patient-surgical-history.model";
import { ValidationEnum } from "app/app-web/app-model/enum/common/validation.enum";
import { SurgicalApproachEnum } from "app/app-web/app-model/enum/common/surgical-approach.enum";
import { PatientPregnancyHistory } from "app/app-web/app-model/dto/gynecology/patient-pregnancy-history.model";
import { FertilityStrategieTherapeutique } from "app/app-web/app-model/dto/gynecology/fertility-strategie-therapeutique.model";
import { SexEnum } from "app/app-web/app-model/enum/common/sex.enum";
import { FertilityControlledOvarianHyperstimulationDays } from "app/app-web/app-model/dto/gynecology/fertility-controlled-ovarian-hyperstimulation-days.model";
import { FertilityInfertilityEtiology } from "app/app-web/app-model/dto/gynecology/fertility-infertility-etiology.model";
import moment from "moment";
import { FertilityControlledOvarianHyperstimulationDrug } from "app/app-web/app-model/dto/gynecology/fertility-controlled-ovarian-hyperstimulation-drug.model";
import { FertilityControlledOvarianHyperstimulationHormone } from "app/app-web/app-model/dto/gynecology/fertility-controlled-ovarian-hyperstimulation-hormone.model";
import { PregnancyGlycemicCycleUnitEnum } from "app/app-web/app-model/enum/gynecology/pregnancy-glycemic-cycle-unit.enum";
import { LevelEnum } from "app/app-web/app-model/enum/gynecology/level.enum";
import { Gyneco } from "app/app-web/app-model/dto/gynecology/gyneco.model";
import { StatusEnum } from "app/app-web/app-model/enum/common/status.enum";
import { Fertility } from "app/app-web/app-model/dto/gynecology/fertility.model";
import { InfertilityTypeEnum } from "app/app-web/app-model/enum/gynecology/infertility-type.enum";
import { CivilStatusEnum } from "app/app-web/app-model/enum/common/civil-status.enum";
import { MenstrualCycleRegularityEnum } from "app/app-web/app-model/enum/gynecology/menstrual-cycle-regularity.enum";
import { Patient } from "app/app-web/app-model/dto/common/patient.model";
import { ResultEnum } from "app/app-web/app-model/enum/common/result.enum";
import { Pregnancy } from "app/app-web/app-model/dto/gynecology/pregnancy.model";
import { ConceptionTypeEnum } from "app/app-web/app-model/enum/gynecology/conception-type.enum";
import { TrimesterEnum } from "app/app-web/app-model/enum/gynecology/trimester.enum";
import { SignEnum } from "app/app-web/app-model/enum/common/sign.enum";
import { PresentationEnum } from "app/app-web/app-model/enum/gynecology/presentation.enum";
import { AppearanceEnum } from "app/app-web/app-model/enum/gynecology/appearance.enum";
import { AmnioticFluidEnum } from "app/app-web/app-model/enum/gynecology/amniotic-fluid.enum";
import { DegreeEnum } from "app/app-web/app-model/enum/gynecology/degree.enum";
import { PregnancyUltrasoundEarlyHeartEnum } from "app/app-web/app-model/enum/gynecology/pregnancy-ultrasound-early-heart.enum";
import { DeliveryModeEnum } from "app/app-web/app-model/enum/gynecology/delivery-mode.enum";
import { BhcgEnum } from "app/app-web/app-model/enum/gynecology/bhcg.enum";
import { TransferEmbryoDayEnum } from "app/app-web/app-model/enum/gynecology/transfer-embryo-type.enum";
import { OutcomeEnum } from "app/app-web/app-model/enum/gynecology/outcome.enum";
import { LocationEnum } from "app/app-web/app-model/enum/gynecology/location.enum";
import { DouglasEnum } from "app/app-web/app-model/enum/gynecology/douglas.enum";
import { UterusSizeEnum } from "app/app-web/app-model/enum/gynecology/uterus-size.enum";
import { MyometreEnum } from "app/app-web/app-model/enum/gynecology/myometre.enum";
import { OvaryEnum } from "app/app-web/app-model/enum/gynecology/ovary.enum";
import { BloodGroupEnum } from "app/app-web/app-model/enum/common/blood-group.enum";
import { DrugFormEnum } from "app/app-web/app-model/enum/common/drug-form.enum";
import { MorphologyStandardEnum } from "app/app-web/app-model/enum/gynecology/morphology-standard.enum";
import { BiologyInterpretationEnum } from "app/app-web/app-model/enum/common/biology-interpretation.enum";
import { BacteriologyInterpretationEnum } from "app/app-web/app-model/enum/common/bacteriology-interpretation.enum";
import { SerologyInterpretationEnum } from "app/app-web/app-model/enum/common/serology-interpretation.enum";
import { BacteriologyGerm } from "app/app-web/app-model/dto/common/bacteriology-germ.model";
import { OperativeReportType } from "app/app-web/app-model/dto/common/operative-report-type.model";
import { GynecoPelvicMassPresumption } from "app/app-web/app-model/dto/gynecology/gyneco-pelvic-mass-presumption.model";
import { Data } from "app/app-web/app-model/utils/dto/data.model";
import { PatientLifeStyleHistory } from "app/app-web/app-model/dto/common/patient-life-style-history.model";
import { LifeStyleValidationEnum } from "app/app-web/app-model/enum/common/life-style-validation.enum";
import { BloodGroup } from "app/app-web/app-model/dto/common/blood-group.model";
import { ExpectedDeliveryModeEnum } from "app/app-web/app-model/enum/gynecology/expected-delivery-mode.enum";
import { PatientPregnancyBabyHistory } from "app/app-web/app-model/dto/gynecology/patient-pregnancy-baby-history.model";
import { UtrasoundRouteEnum } from "app/app-web/app-model/enum/common/utra-sound-route.enum";
import { AppEnum } from "app/app-web/app-model/enum/common/app.enum";
import { PatientPregnancyGeneralHistory } from "app/app-web/app-model/dto/general-medicine/patient-pregnancy-general-history.model";
import { GeneralMedicine } from "app/app-web/app-model/dto/general-medicine/general-medicine.model";
import { EventEnum } from "app/app-web/app-model/enum/gynecology/event.enum";
import { PlacentaEnum } from "app/app-web/app-model/enum/gynecology/placenta.enum";
import { ExamConditionEnum } from "app/app-web/app-model/enum/gynecology/exam-condition.enum";
import { PregnancyUltrasoundEarly } from "app/app-web/app-model/dto/gynecology/pregnancy-ultrasound-early.model";
import { PregnancyUltrasoundSecondT } from "app/app-web/app-model/dto/gynecology/pregnancy-ultrasound-second-t.model";
import { PregnancyUltrasoundFirstTBaby } from "app/app-web/app-model/dto/gynecology/pregnancy-ultrasound-first-t-baby.model";
import { PregnancyUltrasoundEarlyBaby } from "app/app-web/app-model/dto/gynecology/pregnancy-ultrasound-early-baby.model";
import { Cardiology } from "app/app-web/app-model/dto/cardiology/cardiology.model";


@Injectable()
export class DataMedicalRecordExportService {

	private dataExportList: any = [];
	private generateDefaultData: boolean;

	constructor(private dataSharedService: DataSharedService, private translationPipe: TranslationPipe,
		private dateUtcTimezonePipe: DateUtcTimezonePipe,  private dateService: DateService) { }

	onFormatSelectedData(dataExportResponse: DataExportResponse ,formatOptions: any) {
		this.dataExportList = [];
		this.generateDefaultData = formatOptions.generateDefaultData;
		if ( formatOptions.generateDefaultData !== undefined ) {
			if ( formatOptions.generateDefaultData === true ) {
				this.formatDefaultData(dataExportResponse);
			}
		}
		switch(this.dataSharedService.appId) {
			case AppEnum.GYNECO.id:
				if (formatOptions.generateDefaultData !== undefined) {
					if (formatOptions.generateDefaultData === true) {
						this.initPatientPregnancyHistoryList(this.dataSharedService.patientPregnancyHistoryList);
					}
				}
				this.formatSelectedGynecoData(dataExportResponse);
				break;
			case AppEnum.CARDIOLOGY.id:
				this.initPatientPregnancyGeneralHistoryList(this.dataSharedService.patientPregnancyGeneralHistoryList);
				this.formatSelectedCardiologyData(dataExportResponse);
				break;
			default:
				this.initPatientPregnancyGeneralHistoryList(this.dataSharedService.patientPregnancyGeneralHistoryList);
				this.formatSelectedGeneralMedicineData(dataExportResponse);
				break;
		}
		return this.dataExportList;
	}
	
	formatSelectedGeneralMedicineData(dataExportResponse: DataExportResponse) {
		if (dataExportResponse.generalMedicineList && dataExportResponse.generalMedicineList.length > 0) {
			this.initGeneralMedicinePatientListFormat(dataExportResponse.generalMedicineList);
		}
		if (dataExportResponse.consultationGeneralMedicineDetailsList && dataExportResponse.consultationGeneralMedicineDetailsList.length > 0) {
			this.onFormatconsultationGeneralMedicineDetailsList(dataExportResponse.consultationGeneralMedicineDetailsList);
		}
		if (dataExportResponse.physicalExaminationList && dataExportResponse.physicalExaminationList.length > 0) {
			this.onFormatphysicalExaminationList(dataExportResponse.physicalExaminationList);
		}
		if (dataExportResponse.bloodGroupList && dataExportResponse.bloodGroupList.length > 0) {
			this.onFormatBloodGroupList(dataExportResponse.bloodGroupList);
		}
		if (dataExportResponse.bacteriologyList && dataExportResponse.bacteriologyList.length > 0) {
			this.onFormatbacteriologyData(dataExportResponse.bacteriologyList);
		}
		if (dataExportResponse.biologyList && dataExportResponse.biologyList.length > 0) {
			this.onFormatbiologyData(dataExportResponse.biologyList);
		}
		if (dataExportResponse.serologyList && dataExportResponse.serologyList.length > 0) {
			this.onFormatserologyData(dataExportResponse.serologyList);
		}
		if (dataExportResponse.radiologyList && dataExportResponse.radiologyList.length > 0) {
			this.onFormatradiologyData(dataExportResponse.radiologyList);
		}
		if (dataExportResponse.pregnancyGeneticList && dataExportResponse.pregnancyGeneticList.length > 0) {
			this.onFormatgeneticData(dataExportResponse.pregnancyGeneticList);
		}
		if (dataExportResponse.cytologyPathologyList && dataExportResponse.cytologyPathologyList.length > 0) {
			this.onFormatcytologyPathologyData(dataExportResponse.cytologyPathologyList);
		}
		if (dataExportResponse.pregnancyGlycemicCycleList && dataExportResponse.pregnancyGlycemicCycleList.length > 0) {
			this.onFormatPregnancyGlycemicCycleData(dataExportResponse.pregnancyGlycemicCycleList);
		}
		if (dataExportResponse.pregnancyBloodPressureProfileList && dataExportResponse.pregnancyBloodPressureProfileList.length > 0) {
			this.onFormatPregnancyBloodPressureProfileData(dataExportResponse.pregnancyBloodPressureProfileList);
		}
		if (dataExportResponse.prescriptionList && dataExportResponse.prescriptionList.length > 0) {
			this.onFormatprescriptionData(dataExportResponse.prescriptionList);
		}
		if (dataExportResponse.operativeReportList && dataExportResponse.operativeReportList.length > 0) {
			this.onFormatoperativeReportList(dataExportResponse.operativeReportList);
		}
		if (dataExportResponse.consultationGeneralMedicineFreeSpaceList && dataExportResponse.consultationGeneralMedicineFreeSpaceList.length > 0) {
			this.onFormatFreeSpaceList(dataExportResponse.consultationGeneralMedicineFreeSpaceList);
		}
	}
	
	formatSelectedCardiologyData(dataExportResponse: DataExportResponse) {
		if (dataExportResponse.cardiologyList && dataExportResponse.cardiologyList.length > 0) {
			this.initCardiologyPatientListFormat(dataExportResponse.cardiologyList);
		}
		if (dataExportResponse.consultationCardiologyDetailsList && dataExportResponse.consultationCardiologyDetailsList.length > 0) {
			this.onFormatconsultationCardiologyDetailsList(dataExportResponse.consultationCardiologyDetailsList);
		}
		if (dataExportResponse.physicalExaminationList && dataExportResponse.physicalExaminationList.length > 0) {
			this.onFormatphysicalExaminationList(dataExportResponse.physicalExaminationList);
		}
		if (dataExportResponse.bloodGroupList && dataExportResponse.bloodGroupList.length > 0) {
			this.onFormatBloodGroupList(dataExportResponse.bloodGroupList);
		}
		if (dataExportResponse.bacteriologyList && dataExportResponse.bacteriologyList.length > 0) {
			this.onFormatbacteriologyData(dataExportResponse.bacteriologyList);
		}
		if (dataExportResponse.biologyList && dataExportResponse.biologyList.length > 0) {
			this.onFormatbiologyData(dataExportResponse.biologyList);
		}
		if (dataExportResponse.serologyList && dataExportResponse.serologyList.length > 0) {
			this.onFormatserologyData(dataExportResponse.serologyList);
		}
		if (dataExportResponse.radiologyList && dataExportResponse.radiologyList.length > 0) {
			this.onFormatradiologyData(dataExportResponse.radiologyList);
		}
		if (dataExportResponse.pregnancyGeneticList && dataExportResponse.pregnancyGeneticList.length > 0) {
			this.onFormatgeneticData(dataExportResponse.pregnancyGeneticList);
		}
		if (dataExportResponse.cytologyPathologyList && dataExportResponse.cytologyPathologyList.length > 0) {
			this.onFormatcytologyPathologyData(dataExportResponse.cytologyPathologyList);
		}
		if (dataExportResponse.pregnancyGlycemicCycleList && dataExportResponse.pregnancyGlycemicCycleList.length > 0) {
			this.onFormatPregnancyGlycemicCycleData(dataExportResponse.pregnancyGlycemicCycleList);
		}
		if (dataExportResponse.pregnancyBloodPressureProfileList && dataExportResponse.pregnancyBloodPressureProfileList.length > 0) {
			this.onFormatPregnancyBloodPressureProfileData(dataExportResponse.pregnancyBloodPressureProfileList);
		}
		if (dataExportResponse.prescriptionList && dataExportResponse.prescriptionList.length > 0) {
			this.onFormatprescriptionData(dataExportResponse.prescriptionList);
		}
		if (dataExportResponse.operativeReportList && dataExportResponse.operativeReportList.length > 0) {
			this.onFormatoperativeReportList(dataExportResponse.operativeReportList);
		}
		if (dataExportResponse.consultationCardiologyFreeSpaceList && dataExportResponse.consultationCardiologyFreeSpaceList.length > 0) {
			this.onFormatFreeSpaceList(dataExportResponse.consultationCardiologyFreeSpaceList);
		}
		if (dataExportResponse.consultationCardiologyUltrasoundEmptyList && dataExportResponse.consultationCardiologyUltrasoundEmptyList.length > 0) {
			this.onFormatCardiologyUltrasoundEmptyList(dataExportResponse.consultationCardiologyUltrasoundEmptyList);
		}
	}

	formatSelectedGynecoData(dataExportResponse: DataExportResponse) {
		if (dataExportResponse.pregnancyList && dataExportResponse.pregnancyList.length > 0) {
			this.initPregnancyPatientListFormat(dataExportResponse.pregnancyList);
		}
		if (dataExportResponse.gynecoList && dataExportResponse.gynecoList.length > 0) {
			this.initGynecoPatientListFormat(dataExportResponse.gynecoList);
		}
		if (dataExportResponse.fertilityList && dataExportResponse.fertilityList.length > 0) {
			this.initFertilityPatientListFormat(dataExportResponse.fertilityList);
		}
		if (dataExportResponse.consultationFertilityDetailsList && dataExportResponse.consultationFertilityDetailsList.length > 0) {
			this.onFormatconsultationFertilityDetailsList(dataExportResponse.consultationFertilityDetailsList);
		}
		if (dataExportResponse.consultationGynecoDetailsList && dataExportResponse.consultationGynecoDetailsList.length > 0) {
			this.onFormatconsultationGynecoDetailsList(dataExportResponse.consultationGynecoDetailsList);
		}
		if (dataExportResponse.consultationPregnancyDetailsList && dataExportResponse.consultationPregnancyDetailsList.length > 0) {
			this.onFormatconsultationPregnancyDetailsList(dataExportResponse.consultationPregnancyDetailsList)
		}
		if (dataExportResponse.fertilityControlledOvarianHyperstimulationList && dataExportResponse.fertilityControlledOvarianHyperstimulationList.length > 0) {
			this.onFormatOvarianHyperstimulationList(dataExportResponse.fertilityControlledOvarianHyperstimulationList);
		}
		if (dataExportResponse.physicalExaminationList && dataExportResponse.physicalExaminationList.length > 0) {
			this.onFormatphysicalExaminationList(dataExportResponse.physicalExaminationList);
		}
		if (dataExportResponse.consultationPregnancyUltrasoundEarlyList && dataExportResponse.consultationPregnancyUltrasoundEarlyList.length > 0) {
			this.onFormatPregnancyUltrasoundEarlyData(dataExportResponse.consultationPregnancyUltrasoundEarlyList);
		}
		if (dataExportResponse.consultationPregnancyUltrasoundFirstTList && dataExportResponse.consultationPregnancyUltrasoundFirstTList.length > 0) {
			this.onFormatPregnancyUltrasoundFirstTList(dataExportResponse.consultationPregnancyUltrasoundFirstTList);
		}
		if (dataExportResponse.consultationPregnancyUltrasoundSecondTList && dataExportResponse.consultationPregnancyUltrasoundSecondTList.length > 0) {
			this.onFormatPregnancyUltrasoundSecondTList(dataExportResponse.consultationPregnancyUltrasoundSecondTList);
		}
		if (dataExportResponse.consultationPregnancyUltrasoundThirdTList && dataExportResponse.consultationPregnancyUltrasoundThirdTList.length > 0) {
			this.onFormatPregnancyUltrasoundThirdTData(dataExportResponse.consultationPregnancyUltrasoundThirdTList);
		}
		if (dataExportResponse.consultationPregnancyUltrasoundEmptyList && dataExportResponse.consultationPregnancyUltrasoundEmptyList.length > 0) {
			this.onFormatPregnancyUltrasoundEmptyList(dataExportResponse.consultationPregnancyUltrasoundEmptyList);
		}
		if (dataExportResponse.gynecoUltrasoundList && dataExportResponse.gynecoUltrasoundList.length > 0) {
			this.onFormatUltrasoundList(dataExportResponse.gynecoUltrasoundList);
		}
		if (dataExportResponse.bloodGroupList && dataExportResponse.bloodGroupList.length > 0) {
			this.onFormatBloodGroupList(dataExportResponse.bloodGroupList);
		}
		if (dataExportResponse.bacteriologyList && dataExportResponse.bacteriologyList.length > 0) {
			this.onFormatbacteriologyData(dataExportResponse.bacteriologyList);
		}
		if (dataExportResponse.biologyList && dataExportResponse.biologyList.length > 0) {
			this.onFormatbiologyData(dataExportResponse.biologyList);
		}
		if (dataExportResponse.serologyList && dataExportResponse.serologyList.length > 0) {
			this.onFormatserologyData(dataExportResponse.serologyList);
		}
		if (dataExportResponse.radiologyList && dataExportResponse.radiologyList.length > 0) {
			this.onFormatradiologyData(dataExportResponse.radiologyList);
		}
		if (dataExportResponse.pregnancyGeneticList && dataExportResponse.pregnancyGeneticList.length > 0) {
			this.onFormatgeneticData(dataExportResponse.pregnancyGeneticList);
		}
		if (dataExportResponse.cytologyPathologyList && dataExportResponse.cytologyPathologyList.length > 0) {
			this.onFormatcytologyPathologyData(dataExportResponse.cytologyPathologyList);
		}
		if (dataExportResponse.consultationPregnancySerumMarkersList && dataExportResponse.consultationPregnancySerumMarkersList.length > 0) {
			this.onFormatPregnancySerumMarkersList(dataExportResponse.consultationPregnancySerumMarkersList);
		}
		if (dataExportResponse.pregnancyGlycemicCycleList && dataExportResponse.pregnancyGlycemicCycleList.length > 0) {
			this.onFormatPregnancyGlycemicCycleData(dataExportResponse.pregnancyGlycemicCycleList);
		}
		if (dataExportResponse.pregnancyBloodPressureProfileList && dataExportResponse.pregnancyBloodPressureProfileList.length > 0) {
			this.onFormatPregnancyBloodPressureProfileData(dataExportResponse.pregnancyBloodPressureProfileList);
		}
		if (dataExportResponse.fertilitySpermogramList && dataExportResponse.fertilitySpermogramList.length > 0) {
			this.onFormatSpermogramList(dataExportResponse.fertilitySpermogramList);
		}
		if (dataExportResponse.fertilityHysterosalpingographyList && dataExportResponse.fertilityHysterosalpingographyList.length > 0) {
			this.onFormatHysterosalpingographyList(dataExportResponse.fertilityHysterosalpingographyList);
		}
		if (dataExportResponse.prescriptionList && dataExportResponse.prescriptionList.length > 0) {
			this.onFormatprescriptionData(dataExportResponse.prescriptionList);
		}
		if (dataExportResponse.operativeReportList && dataExportResponse.operativeReportList.length > 0) {
			this.onFormatoperativeReportList(dataExportResponse.operativeReportList);
		}
		if (dataExportResponse.consultationPregnancyOutcomeList && dataExportResponse.consultationPregnancyOutcomeList.length > 0) {
			this.onFormatPregnancyOutcomeData(dataExportResponse.consultationPregnancyOutcomeList);
		}
		if (dataExportResponse.fertilityControlledOvarianHyperstimulationIuiList && dataExportResponse.fertilityControlledOvarianHyperstimulationIuiList.length > 0) {
			this.onFormatOvarianHyperstimulationIuiList(dataExportResponse.fertilityControlledOvarianHyperstimulationIuiList);
		}
		if (dataExportResponse.fertilityControlledOvarianHyperstimulationScheduledReportList && dataExportResponse.fertilityControlledOvarianHyperstimulationScheduledReportList.length > 0) {
			this.onFormatOvarianHyperstimulationScheduledReportList(dataExportResponse.fertilityControlledOvarianHyperstimulationScheduledReportList);
		}
		if (dataExportResponse.fertilityControlledOvarianHyperstimulationIvfList && dataExportResponse.fertilityControlledOvarianHyperstimulationIvfList.length > 0) {
			this.onFormatOvarianHyperstimulationIvfList(dataExportResponse.fertilityControlledOvarianHyperstimulationIvfList);
		}
		if (dataExportResponse.fertilityInfertilityEtiologyList && dataExportResponse.fertilityInfertilityEtiologyList.length > 0) {
			this.onFormatFertilityInfertilityEtiologyList(dataExportResponse.fertilityInfertilityEtiologyList);
		}
		if (dataExportResponse.fertilityStrategieTherapeutiqueList && dataExportResponse.fertilityStrategieTherapeutiqueList.length > 0) {
			this.onFormatFertilityStrategieTherapeutiqueList(dataExportResponse.fertilityStrategieTherapeutiqueList);
		}
		if (dataExportResponse.consultationFertilityFreeSpaceList && dataExportResponse.consultationFertilityFreeSpaceList.length > 0) {
			this.onFormatFreeSpaceList(dataExportResponse.consultationFertilityFreeSpaceList);
		}
		if (dataExportResponse.consultationPregnancyFreeSpaceList && dataExportResponse.consultationPregnancyFreeSpaceList.length > 0) {
			this.onFormatFreeSpaceList(dataExportResponse.consultationPregnancyFreeSpaceList);
		}
		if (dataExportResponse.consultationGynecoFreeSpaceList && dataExportResponse.consultationGynecoFreeSpaceList.length > 0) {
			this.onFormatFreeSpaceList(dataExportResponse.consultationGynecoFreeSpaceList);
		}
		this.formatSelectedFileDicomList(dataExportResponse);
	}
	
	formatDefaultData(dataExportResponse: DataExportResponse) {
		let patient = this.dataSharedService.patient;
		this.initPatientFormat(patient);
		this.initPatientFamilyHistoryList(this.dataSharedService.patientFamilyHistoryList);
		this.initPatientAllergyHistoryList(this.dataSharedService.patientAlleryHistoryList);
		this.initPatientLifeStyleHistory(this.dataSharedService.patientLifeStyleHistory);
		this.initPatientMedicalHistoryList(this.dataSharedService.patientMedicalHistoryList);
		this.initPatientSurgicalHistoryList(this.dataSharedService.patientSurgicalHistoryList);
		this.initPatientContraceptionHistoryList(this.dataSharedService.patientContraceptionHistoryList);
	}
	
	formatSelectedFileDicomList(dataExportResponse: DataExportResponse) {
		const pregnancyUltrasoundMappingList = [
			{
				list: dataExportResponse.consultationPregnancyUltrasoundEarlyList,
				label: "lblPregnancyUltrasoundEarlyList"
			},
			{
				list: dataExportResponse.consultationPregnancyUltrasoundFirstTList,
				label: "lblPregnancyUltrasoundFirstTList"
			},
			{
				list: dataExportResponse.consultationPregnancyUltrasoundSecondTList,
				label: "lblPregnancyUltrasoundSecondTList"
			},
			{
				list: dataExportResponse.consultationPregnancyUltrasoundThirdTList,
				label: "lblPregnancyUltrasoundThirdTList"
			},
			{
				list: dataExportResponse.consultationPregnancyUltrasoundEmptyList,
				label: "lblPregnancyUltrasoundEmptyList"
			},
			{
				list: dataExportResponse.gynecoUltrasoundList,
				label: "lblGynecoUltrasoundList"
			},
			{
				list: dataExportResponse.consultationCardiologyUltrasoundEmptyList,
				label: "lblCardiologyUltrasoundEmptyList"
			}
		];
		pregnancyUltrasoundMappingList.forEach(pregnancyUltrasound => {
			if (pregnancyUltrasound.list && pregnancyUltrasound.list.length > 0) {
				this.onFormatPregnancyUltrasoundFileDicomList(pregnancyUltrasound.list, pregnancyUltrasound.label);
			}
		});
	}
	
	onFormatPregnancyUltrasoundFileDicomList(pregnancyUltrasoundList: any[], labelKey: string) {
		pregnancyUltrasoundList.forEach(pregnancyUltrasound => {
			const fileDicomList = pregnancyUltrasound.fileDicomList;
			if (fileDicomList && fileDicomList.length > 0) {
				this.onFormatFileDicomList(pregnancyUltrasound, labelKey);
			}
		});
	}
	
	onFormatFileDicomList(pregnancyUltrasound: any, labelKey: string) {
		const fileDicomList = pregnancyUltrasound.fileDicomList;
		const datePregnancy = this.getPregnancyUltrasoundDate(pregnancyUltrasound);
		const sectionName = datePregnancy
			? `${this.translationPipe.transform(labelKey)} (${this.dateUtcTimezonePipe.transform(datePregnancy)})`
			: this.translationPipe.transform(labelKey);
		const pregnancyUltrasoundFileDicomSection = {
			sectionName,
			columnHeader: null,
			displayImage: true,
			imagesPerRow: 3,
			dataList: fileDicomList.map(fileDicom => fileDicom.data)
		};
		this.dataExportList.push(pregnancyUltrasoundFileDicomSection);
	}
	
	onFormatBloodGroupList(bloodGroupList: BloodGroup[]) {
		let bloodGroupSection = {
			sectionName: this.translationPipe.transform("lblBloodGroupList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "bloodGroup", value: this.translationPipe.transform("lblBloodGroup") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: []
		}
		bloodGroupList.sort((a, b) => a.date - b.date);
		for (let i = 0; i < bloodGroupList.length; i++) {
			let element = bloodGroupList[i];
			let commentResult = "<ul>";
			if (element.comment !== undefined && element.comment !== null) {
				commentResult += "<li>" + element.comment + "</li>";
			}
			if (this.dataSharedService.appId === AppEnum.GYNECO.id) {
				if (element.husband !== undefined && element.husband !== null && element.husband === true) {
					commentResult += "<li>" + this.translationPipe.transform("lblHusband") + "</li>";
				}
			}
			commentResult+="</ul>"
			let data = {
				date: this.dateUtcTimezonePipe.transform(element?.date),
				bloodGroup: this.getElementFromList("id", element.bloodGroup?.id, BloodGroupEnum.getBloodGroupList(), "", "unique"),
				comment: commentResult
			}
			bloodGroupSection.dataList?.push(data)
		}
		this.dataExportList.push(bloodGroupSection);
	}

	initPatientLifeStyleHistory(patientLifeStyleHistory: PatientLifeStyleHistory) {
		if ( patientLifeStyleHistory !== null ) {
			let patientLifeStyleHistorySection = {
				sectionName: this.translationPipe.transform(""),
				style: { fontStyle: 'bold' },
				columnHeader: [
					{ key: "tabacco", value: this.translationPipe.transform("lblTabacco") },
					{ key: "alcohol", value: this.translationPipe.transform("lblAlcohol") },
				],
				viewStructure: [2],
				dataList: [],
			}
			let data = {
				tabacco: this.getElementFromList("id", patientLifeStyleHistory.tabacco?.id, LifeStyleValidationEnum.getLifeStyleValidationList(), "", "unique"),
				alcohol: this.getElementFromList("id", patientLifeStyleHistory.alcohol?.id, LifeStyleValidationEnum.getLifeStyleValidationList(), "", "unique"),
			}
			patientLifeStyleHistorySection.dataList?.push(data)
			this.dataExportList.push(patientLifeStyleHistorySection);
		}
	}
    
	initPatientFamilyHistoryList(patientFamilyHistoryList: PatientFamilyHistory[]) {
		let patientFamilyHistorySection = {
			sectionName: this.translationPipe.transform("lblFamilyHistory"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "familyPathology", value: this.translationPipe.transform("lblPathology") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < patientFamilyHistoryList.length; i++) {
			let element = patientFamilyHistoryList[i];
			let data = {
				familyPathology: this.getElementFromList("idFamilyPathology", element.familyPathology?.idFamilyPathology, this.dataSharedService.familyPathologyList, "", "unique"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			patientFamilyHistorySection.dataList?.push(data)
		}
		if (patientFamilyHistorySection.dataList.length !== 0) {
			this.dataExportList.push(patientFamilyHistorySection);
		} else {
			let sectionName = this.translationPipe.transform("lblFamilyHistory") + ": 0";
			this.initSimpleText(sectionName);
		}
	}
	
	initPatientAllergyHistoryList(patientAllergyHistoryList: PatientAllergyHistory[]) {
		let patientAllergyHistorySection = {
			sectionName: this.translationPipe.transform("lblAllergyHistory"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "allergy", value: this.translationPipe.transform("lblAllergy") },
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < patientAllergyHistoryList.length; i++) {
			let element = patientAllergyHistoryList[i];
			let data = {
				allergy: this.getElementFromList("idAllergy", element.allergy?.idAllergy, this.dataSharedService.allergyList, "", "unique"),
				date: this.dateService.getStringUtcDate(element.date, "MM/YYYY"),
				comment: element?.comment?.replace(/\n/g, '<br>'),
			}
			patientAllergyHistorySection.dataList?.push(data)
		}
		if (patientAllergyHistorySection.dataList.length !== 0) {
			this.dataExportList.push(patientAllergyHistorySection);
		} else {
			let sectionName = this.translationPipe.transform("lblAllergyHistory") + ": 0";
			this.initSimpleText(sectionName);
		}
	}
	
	initPatientMedicalHistoryList(patientHistoryList: PatientMedicalHistory[]) {
		let patientMedicalHistorySection = {
			sectionName: this.translationPipe.transform("lblPatientMedicalHistory"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "medicalPathology", value: this.translationPipe.transform("lblPathology") },
				{ key: "doctor", value: this.translationPipe.transform("lblDoctor") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < patientHistoryList.length; i++) {
			let element = patientHistoryList[i];
			let data = {
				date: this.dateService.getStringUtcDate(element.date, "MM/YYYY"),
				medicalPathology: this.getElementFromList("idMedicalPathology", element.medicalPathology?.idMedicalPathology, this.dataSharedService.medicalPathologyList, "", "unique"),
				doctor: element?.doctor,
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			patientMedicalHistorySection.dataList?.push(data)
		}
		if (patientMedicalHistorySection.dataList.length !== 0) {
			this.dataExportList.push(patientMedicalHistorySection);
		} else {
			let sectionName = this.translationPipe.transform("lblPatientMedicalHistory") + ": 0";
			this.initSimpleText(sectionName);
		}
	}

	initSimpleText(sectionName: string) {
		let textSection = {
			sectionName: sectionName,
			style: { fontStyle: 'bold' },
			dataList: []
		}
		this.dataExportList.push(textSection);
	}

	initPatientContraceptionHistoryList(patientContraceptionHistoryList: PatientContraceptionHistory[]) {
		let patientContraceptionHistorySection = {
			sectionName: this.translationPipe.transform("lblPatientContraceptionHistory"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "dateBegin", value: this.translationPipe.transform("lblDateBegin") },
				{ key: "dateEnd", value: this.translationPipe.transform("lblDateEnd") },
				{ key: "contraceptiveMethod", value: this.translationPipe.transform("lblContraceptiveMethod") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < patientContraceptionHistoryList.length; i++) {
			let element = patientContraceptionHistoryList[i];
			let data = {
				dateBegin: this.dateService.getStringUtcDate(element.dateBegin, "MM/YYYY"),
				dateEnd: this.dateService.getStringUtcDate(element.dateEnd, "MM/YYYY"),
				contraceptiveMethod: this.getElementFromList("idContraceptiveMethod", element.contraceptiveMethod?.idContraceptiveMethod, this.dataSharedService.contraceptiveMethodList, "", "unique"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			patientContraceptionHistorySection.dataList?.push(data)
		}
		if (patientContraceptionHistorySection.dataList.length !== 0) {
			this.dataExportList.push(patientContraceptionHistorySection);
		} else {
			let sectionName = this.translationPipe.transform("lblPatientContraceptionHistory") + ": 0";
			this.initSimpleText(sectionName);
		}
	}

	initPatientSurgicalHistoryList(patientSurgicalHistoryList: PatientSurgicalHistory[]) {
		let patientSurgicalHistorySection = {
			sectionName: this.translationPipe.transform("lblPatientSurgicalHistory"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "surgicalPathology", value: this.translationPipe.transform("lblPathology") },
				{ key: "surgicalApproach", value: this.translationPipe.transform("lblSurgicalApproach") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < patientSurgicalHistoryList.length; i++) {
			let element = patientSurgicalHistoryList[i];
			let data = {
				date: this.dateService.getStringUtcDate(element.date, "MM/YYYY"),
				surgicalPathology: this.getElementFromList("idSurgicalPathology", element.surgicalPathology?.idSurgicalPathology, this.dataSharedService.surgicalPathologyList, "", "unique"),
				surgicalApproach: this.getElementFromList("id", element.surgicalApproach?.id, SurgicalApproachEnum.getSurgicalApproachList(), "", "unique"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			patientSurgicalHistorySection.dataList?.push(data)
		}
		if (patientSurgicalHistorySection.dataList.length !== 0) {
			this.dataExportList.push(patientSurgicalHistorySection);
		} else {
			let sectionName = this.translationPipe.transform("lblPatientSurgicalHistory") + ": 0";
			this.initSimpleText(sectionName);
		}
	}

	initPatientPregnancyGeneralHistoryList(patientPregnancyGeneralHistoryList: PatientPregnancyGeneralHistory[]) {
		let outcomeList: Data[] = OutcomeEnum.getOutcomeList();
		let patientPregnancyGeneralHistorySection = {
			sectionName: this.translationPipe.transform("lblPatientPregnancyHistory"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "outcome", value: this.translationPipe.transform("lblOutcome") },
				{ key: "intervention", value: this.translationPipe.transform("lblIntervention") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < patientPregnancyGeneralHistoryList.length; i++) {
			let element = patientPregnancyGeneralHistoryList[i];
			let commentDataList = "<ul>";
			let comment: string = element.comment;
			if (comment != null && comment != undefined) {
				commentDataList += "<li> " + comment + "</li>"
			}
			commentDataList += "</ul>";
			let outcome: Data;
			let intervention: Data;
			if ( element.outcome !== undefined && element.outcome !== null ) {
				outcome = outcomeList.find( o => o.id == element.outcome.id );
				let interventionList = outcome.dataList;
				intervention = interventionList.find( o => o.id == element.intervention.id );
	        }
			let data = {
				date: this.dateService.getStringUtcDate(element.date, "MM/YYYY"),
				outcome: this.translationPipe.transform(outcome?.name),
				intervention: this.translationPipe.transform(intervention?.name),
				comment: commentDataList,
			}
			patientPregnancyGeneralHistorySection.dataList?.push(data);
		}
		if (patientPregnancyGeneralHistorySection.dataList.length !== 0) {
			this.dataExportList.push(patientPregnancyGeneralHistorySection);
		} else {
			let sectionName = this.translationPipe.transform("lblPatientPregnancyHistory") + ": 0";
			this.initSimpleText(sectionName);
		}
	}

	initPatientPregnancyHistoryList(patientPregnancyHistoryList: PatientPregnancyHistory[]) {
		let patientPregnancyHistorySection = {
			sectionName: this.translationPipe.transform("lblPatientPregnancyHistory"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "outcome", value: this.translationPipe.transform("lblOutcome") },
				{ key: "intervention", value: this.translationPipe.transform("lblIntervention") },
				{ key: "surgicalPathology", value: this.translationPipe.transform("lblPathology") },
				{ key: "operationPlace", value: this.translationPipe.transform("lblPlace") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		let yesLabel = this.translationPipe.transform(ValidationEnum.YES.name);
		let sexList: Data[] = SexEnum.getSexList();
		for (let i = 0; i < patientPregnancyHistoryList.length; i++) {
			let element = patientPregnancyHistoryList[i];
			let custumerdata = "<ul>"
			let diabetes = this.getElementFromList("id", element.diabetes?.id, ValidationEnum.getValidationList(), "", "unique");
			let prematureDelivery = this.getElementFromList("id", element.prematureDelivery?.id, ValidationEnum.getValidationList(), "", "unique");
			let instrumentalDelivery = this.getElementFromList("id", element.instrumentalDelivery?.id, ValidationEnum.getValidationList(), "", "unique");
			let hbp = this.getElementFromList("id", element.hbp?.id, ValidationEnum.getValidationList(), "", "unique");
			let intraUterineFoetalDeath = this.getElementFromList("id", element.intraUterineFoetalDeath?.id, ValidationEnum.getValidationList(), "", "unique");
			let postPartumHemargea = this.getElementFromList("id", element.postPartumHemargea?.id, ValidationEnum.getValidationList(), "", "unique");
			let placentaPrevia = this.getElementFromList("id", element.placentaPrevia?.id, ValidationEnum.getValidationList(), "", "unique");
			if (diabetes != null && diabetes != undefined && diabetes === yesLabel) {
				custumerdata += "<li> " + this.translationPipe.transform("lblDiabetes") + "</li>";
			}
			if (prematureDelivery != null && prematureDelivery != undefined && prematureDelivery === yesLabel) {
				custumerdata += "<li> " + this.translationPipe.transform("lblPrematureDelivery") + "</li>";
			}
			if (instrumentalDelivery != null && instrumentalDelivery != undefined && instrumentalDelivery === yesLabel) {
				custumerdata += "<li> " + this.translationPipe.transform("lblInstrumentalDelivery") + "</li>";
			}
			if (hbp != null && hbp != undefined && hbp === yesLabel) {
				custumerdata += "<li> " + this.translationPipe.transform("lblHbp") + "</li>";
			}
			if (intraUterineFoetalDeath != null && intraUterineFoetalDeath != undefined && intraUterineFoetalDeath === yesLabel) {
				custumerdata += "<li> " + this.translationPipe.transform("lblIntraUterineFoetalDeath") + "</li>";
			}
			if (placentaPrevia != null && placentaPrevia != undefined && placentaPrevia === yesLabel) {
				custumerdata += "<li> " + this.translationPipe.transform("lblPlacentaPrevia") + "</li>";
			}
			if (postPartumHemargea != null && postPartumHemargea != undefined && postPartumHemargea === yesLabel) {
				custumerdata += "<li> " + this.translationPipe.transform("lblPostPartumHemargea") + "</li>";
			}
			custumerdata += "</ul>"
			let commentDataList = "<ul>";
			let comment: string = element.comment;
			if (comment != null && comment != undefined) {
				commentDataList+= "<li> " + comment + "</li>" 
			}
			let patientPregnancyBabyHistoryList: PatientPregnancyBabyHistory[] = element.patientPregnancyBabyHistoryList;
			if (patientPregnancyBabyHistoryList != null && patientPregnancyBabyHistoryList != undefined) {
				patientPregnancyBabyHistoryList.forEach(patientPregnancyBabyHistory => {
					let result = this.translationPipe.transform('lblPatientPregnancyBabyHistory') + " : ";
					let firstElement = true;
					if (patientPregnancyBabyHistory.sex != null && patientPregnancyBabyHistory.sex != undefined) {
						let sex = sexList.find(sex => sex.id == patientPregnancyBabyHistory.sex.id);
						result = result + this.translationPipe.transform(sex.name);
						firstElement = false;
					}
					if (patientPregnancyBabyHistory.weight != null && patientPregnancyBabyHistory.weight != undefined) {
						if (!firstElement) {
							result = result + " / ";
						}
						result = result + patientPregnancyBabyHistory.weight + " " + this.translationPipe.transform('lblGramme');
						firstElement = false;
					}
					if (patientPregnancyBabyHistory.foetopathy != null && patientPregnancyBabyHistory.foetopathy != undefined
						&& patientPregnancyBabyHistory.foetopathy.id == ValidationEnum.YES.id) {
						if (!firstElement) {
							result = result + " / ";
						}
						result = result + this.translationPipe.transform('lblFoetopathy');
						firstElement = false;
					}
					if (patientPregnancyBabyHistory.neonatalDeath != null && patientPregnancyBabyHistory.neonatalDeath != undefined
						&& patientPregnancyBabyHistory.neonatalDeath.id == ValidationEnum.YES.id) {
						if (!firstElement) {
							result = result + " / ";
						}
						result = result + this.translationPipe.transform('lblNeonatalDeath');
						firstElement = false;
					}
					if (patientPregnancyBabyHistory.comment != null && patientPregnancyBabyHistory.comment != undefined
						&& patientPregnancyBabyHistory.comment.length != 0) {
						if (!firstElement) {
							result = result + " / ";
						}
						result = result + patientPregnancyBabyHistory.comment;
						firstElement = false;
					}
					commentDataList+= "<li>"+result + "</li>";
				});
				}	
				commentDataList+="</ul>"		
			let data = {
				date: this.dateService.getStringUtcDate(element.date, "MM/YYYY"),
				outcome: this.translationPipe.transform(element?.outcome?.name),
				intervention: this.translationPipe.transform(element?.intervention?.name),
				surgicalPathology: custumerdata,
				operationPlace: this.getElementFromList("idOperationPlace", element.operationPlace?.idOperationPlace, this.dataSharedService.operationPlaceList, "", "unique"),
				comment: commentDataList,
			}
			patientPregnancyHistorySection.dataList?.push(data);
		}
		if (patientPregnancyHistorySection.dataList.length !== 0) {
			this.dataExportList.push(patientPregnancyHistorySection);
		} else {
			let sectionName = this.translationPipe.transform("lblPatientPregnancyHistory") + ": 0";
			this.initSimpleText(sectionName);
		}
	}
	
    onFormatPregnancyBabyHistoryList(patientPregnancyBabyHistoryList: any){
		let listPregnancyBabyHistory : any[] = [];
		let data = {
				date: "<b>" +" &#10551; " +this.translationPipe.transform("lblPatientPregnancyBabyHistory") +"<b>",
				outcome: undefined,
				intervention: undefined,
				surgicalPathology:  undefined,
				operationPlace: undefined,
				comment: undefined,
			}
			listPregnancyBabyHistory.push(data);
		for (let i = 0; i< patientPregnancyBabyHistoryList.length; i++){
			let element = patientPregnancyBabyHistoryList[i];
			let sex = element?.sex !== undefined ? true : false;
			let foetopathy = element?.foetopathy !== undefined ? true : false;
			let neonatalDeath = element?.neonatalDeath !== undefined ? true : false; 
			let data = {
				date: undefined,
				outcome: sex == true ? "<b>" +this.translationPipe.transform("lblSex") +"</b>" + ':' + this.getElementFromList("id", element.sex?.id, SexEnum.getSexList(), "", "unique"):undefined,
				intervention: element?.weight !== undefined  ? "<b>" +this.translationPipe.transform("lblWeight") +"</b> " +":"+ element?.weight + ' ' + this.translationPipe.transform("lblGramme"): undefined,
				surgicalPathology: foetopathy === true ? "<b>"+ this.translationPipe.transform("lblFoetopathy") +"</b>"+ ':' +  this.getElementFromList("id", element.foetopathy?.id, ValidationEnum.getValidationList(), "", "unique"): undefined,
				operationPlace: neonatalDeath === true ? "<b>" +this.translationPipe.transform("lblNeonatalDeath") +"</b>"+ ':' + this.getElementFromList("id", element.neonatalDeath?.id, ValidationEnum.getValidationList(), "", "unique"): undefined,
				comment:   element?.comment?.replace(/\n/g, '<br>')
			}
			listPregnancyBabyHistory.push(data);
		}
		return listPregnancyBabyHistory;
		
	}
	
	onFormatFertilityStrategieTherapeutiqueList(fertilityStrategieTherapeutiqueList: FertilityStrategieTherapeutique[]) {
		let fertilityStrategieTherapeutiqueSection = {
			sectionName: this.translationPipe.transform("lblFertilityStrategieTherapeutique"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "ordre", value: this.translationPipe.transform("lblOrdre") },
				{ key: "fertilityTherapeutiqueList", value: this.translationPipe.transform("lblActions") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < fertilityStrategieTherapeutiqueList.length; i++) {
			let element = fertilityStrategieTherapeutiqueList[i];
			let data = {
				ordre: element?.ordre,
				fertilityTherapeutiqueList: this.getFertilityTherapeutiqueList(element?.fertilityTherapeutiqueList),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			fertilityStrategieTherapeutiqueSection.dataList?.push(data)
		}
		if (fertilityStrategieTherapeutiqueSection.dataList.length != 0) {
			this.dataExportList.push(fertilityStrategieTherapeutiqueSection);
		}
	}

	onFormatFertilityInfertilityEtiologyList(fertilityInfertilityEtiologyList: FertilityInfertilityEtiology[]) {
		let fertilityInfertilityEtiologySection = {
			sectionName: this.translationPipe.transform("lblInfertilityEtiology"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "fertilityEtiology", value: this.translationPipe.transform("lblEtiology") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < fertilityInfertilityEtiologyList.length; i++) {
			let element = fertilityInfertilityEtiologyList[i];
			let data = {
				fertilityEtiology: this.getElementFromList("idFertilityEtiology", element.fertilityEtiology.idFertilityEtiology, this.dataSharedService.fertilitySettings.fertilityEtiologyList, "", "unique"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			fertilityInfertilityEtiologySection.dataList?.push(data)
		}
		if (fertilityInfertilityEtiologySection.dataList.length != 0) {
			this.dataExportList.push(fertilityInfertilityEtiologySection);
		}
	}
	
	onFormatDopplers(pregnancyUltrasound: any) {
		let showData: boolean =
			pregnancyUltrasound?.dopplerNotchLeft?.id || pregnancyUltrasound?.dopplerUterineLeft || pregnancyUltrasound?.dopplerPulsatilityIndexLeft
				|| pregnancyUltrasound?.dopplerNotchRight?.id || pregnancyUltrasound?.dopplerUterineRight || pregnancyUltrasound?.dopplerPulsatilityIndexRight
				? true
				: false;
		if (showData) {
			let headerList: any[] = [];
			headerList.unshift({ key: "title", value: null });
			headerList.push({ key: "dopplerNotch", value: this.translationPipe.transform("lblDopplerNotchLeft") }, { key: "dopplerUterine", value: this.translationPipe.transform("lblDopplerUterineLeft") }, { key: "dopplerPulsatilityIndex", value: this.translationPipe.transform("lblDopplerPulsatilityIndexLeft") });
			let dataList: any[] = [];
			let dopplerUterineLeftTitle = { "title": this.translationPipe.transform("lblDopplerUterineLeftTitle") };
			if (pregnancyUltrasound.dopplerNotchLeft !== undefined && pregnancyUltrasound.dopplerNotchLeft !== null) {
				dopplerUterineLeftTitle["dopplerNotch"] = this.getElementFromList("id", pregnancyUltrasound.dopplerNotchLeft?.id, ValidationEnum.getValidationList(), "", "unique");
			}
			if (pregnancyUltrasound.dopplerUterineLeft !== undefined && pregnancyUltrasound.dopplerUterineLeft !== null) {
				dopplerUterineLeftTitle["dopplerUterine"] = pregnancyUltrasound.dopplerUterineLeft;
			}
			if (pregnancyUltrasound.dopplerPulsatilityIndexLeft !== undefined && pregnancyUltrasound.dopplerPulsatilityIndexLeft !== null) {
				dopplerUterineLeftTitle["dopplerPulsatilityIndex"] = pregnancyUltrasound.dopplerPulsatilityIndexLeft;
			}
			if (dopplerUterineLeftTitle["dopplerNotch"] !== undefined || dopplerUterineLeftTitle["dopplerUterine"] !== undefined || dopplerUterineLeftTitle["dopplerPulsatilityIndex"] !== undefined) {
				dataList.push(dopplerUterineLeftTitle);
			}
			let dopplerUterineRightTitle = { "title": this.translationPipe.transform("lblDopplerUterineRightTitle") };
			if (pregnancyUltrasound.dopplerNotchRight !== undefined && pregnancyUltrasound.dopplerNotchRight !== null) {
				dopplerUterineRightTitle["dopplerNotch"] = this.getElementFromList("id", pregnancyUltrasound.dopplerNotchRight?.id, ValidationEnum.getValidationList(), "", "unique");
			}
			if (pregnancyUltrasound.dopplerUterineRight !== undefined && pregnancyUltrasound.dopplerUterineRight !== null) {
				dopplerUterineRightTitle["dopplerUterine"] = pregnancyUltrasound.dopplerUterineRight;
			}
			if (pregnancyUltrasound.dopplerPulsatilityIndexRight !== undefined && pregnancyUltrasound.dopplerPulsatilityIndexRight !== null) {
				dopplerUterineRightTitle["dopplerPulsatilityIndex"] = pregnancyUltrasound.dopplerPulsatilityIndexRight;
			}
			if (dopplerUterineRightTitle["dopplerNotch"] !== undefined || dopplerUterineRightTitle["dopplerUterine"] !== undefined || dopplerUterineRightTitle["dopplerPulsatilityIndex"] !== undefined) {
				dataList.push(dopplerUterineRightTitle);
			}
			let dopplers: { dataList: string[], columnHeader: any[], style: any, displayHeader: boolean, sectionName: string } = { sectionName: this.translationPipe.transform("lblDopplersUterins"), style: { fontStyle: 'bold' , underline: true}, columnHeader: headerList, dataList: dataList, displayHeader: true };
			this.dataExportList.push(dopplers);
		}
	}
	
	onFormatPregnancyFirstDopplerFoetaux(pregnancyUltrasoundFirstTBaby: PregnancyUltrasoundFirstTBaby) {
		let showData: boolean =
			pregnancyUltrasoundFirstTBaby?.craniocaudalLength || pregnancyUltrasoundFirstTBaby?.craniocaudalLengthPercentile || pregnancyUltrasoundFirstTBaby?.craniocaudalLengthGestationnalAge
				|| pregnancyUltrasoundFirstTBaby?.nuchalTranslucency || pregnancyUltrasoundFirstTBaby?.nuchalTranslucencyPercentile || pregnancyUltrasoundFirstTBaby?.biparietalDiameter
				|| pregnancyUltrasoundFirstTBaby?.biparietalDiameterPercentile || pregnancyUltrasoundFirstTBaby?.biparietalDiameterGestationnalAge || pregnancyUltrasoundFirstTBaby?.femurLength
				|| pregnancyUltrasoundFirstTBaby?.femurLengthPercentile || pregnancyUltrasoundFirstTBaby?.femurLengthGestationnalAge
				? true
				: false;
		if (showData) {
			let headerList: any[] = [];
			headerList.unshift({ key: "title", value: null });
			headerList.push({ key: "length", value: this.translationPipe.transform("lblLength") }, { key: "percentile", value: this.translationPipe.transform("lblPercentile") }, { key: "gestationnalAge", value: this.translationPipe.transform("lblGestationnalAge") });
			let dataList: any[] = [];
			let craniocaudalLength = { "title": this.translationPipe.transform("lblCraniocaudalLength") };
			if (pregnancyUltrasoundFirstTBaby.craniocaudalLength !== undefined && pregnancyUltrasoundFirstTBaby.craniocaudalLength !== null) {
				craniocaudalLength["length"] = pregnancyUltrasoundFirstTBaby.craniocaudalLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasoundFirstTBaby.craniocaudalLengthPercentile !== undefined && pregnancyUltrasoundFirstTBaby.craniocaudalLengthPercentile !== null) {
				craniocaudalLength["percentile"] = pregnancyUltrasoundFirstTBaby.craniocaudalLengthPercentile;
			}
			if (pregnancyUltrasoundFirstTBaby.craniocaudalLengthGestationnalAge !== undefined && pregnancyUltrasoundFirstTBaby.craniocaudalLengthGestationnalAge !== null) {
				craniocaudalLength["gestationnalAge"] = pregnancyUltrasoundFirstTBaby.craniocaudalLengthGestationnalAge;
			}
			if (craniocaudalLength["length"] || craniocaudalLength["percentile"] || craniocaudalLength["gestationnalAge"]) {
				dataList.push(craniocaudalLength);
			}
			let nuchalTranslucency = { "title": this.translationPipe.transform("lblNuchalTranslucency") };
			if (pregnancyUltrasoundFirstTBaby.nuchalTranslucency !== undefined && pregnancyUltrasoundFirstTBaby.nuchalTranslucency !== null) {
				nuchalTranslucency["length"] = pregnancyUltrasoundFirstTBaby.nuchalTranslucency + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasoundFirstTBaby.nuchalTranslucencyPercentile !== undefined && pregnancyUltrasoundFirstTBaby.nuchalTranslucencyPercentile !== null) {
				nuchalTranslucency["percentile"] = pregnancyUltrasoundFirstTBaby.nuchalTranslucencyPercentile;
			}
			if (nuchalTranslucency["length"] || nuchalTranslucency["percentile"]) {
				dataList.push(nuchalTranslucency);
			}
			let biparietalDiameter = { "title": this.translationPipe.transform("lblBiparietalDiameter") };
			if (pregnancyUltrasoundFirstTBaby.biparietalDiameter !== undefined && pregnancyUltrasoundFirstTBaby.biparietalDiameter !== null) {
				biparietalDiameter["length"] = pregnancyUltrasoundFirstTBaby.biparietalDiameter + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasoundFirstTBaby.biparietalDiameterPercentile !== undefined && pregnancyUltrasoundFirstTBaby.biparietalDiameterPercentile !== null) {
				biparietalDiameter["percentile"] = pregnancyUltrasoundFirstTBaby.biparietalDiameterPercentile;
			}
			if (pregnancyUltrasoundFirstTBaby.biparietalDiameterGestationnalAge !== undefined && pregnancyUltrasoundFirstTBaby.biparietalDiameterGestationnalAge !== null) {
				biparietalDiameter["gestationnalAge"] = pregnancyUltrasoundFirstTBaby.biparietalDiameterGestationnalAge;
			}
			if (biparietalDiameter["length"] || biparietalDiameter["percentile"] || biparietalDiameter["gestationnalAge"]) {
				dataList.push(biparietalDiameter);
			}
			let femurLength = { "title": this.translationPipe.transform("lblFemurLength") };
			if (pregnancyUltrasoundFirstTBaby.femurLength !== undefined && pregnancyUltrasoundFirstTBaby.femurLength !== null) {
				femurLength["length"] = pregnancyUltrasoundFirstTBaby.femurLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasoundFirstTBaby.femurLengthPercentile !== undefined && pregnancyUltrasoundFirstTBaby.femurLengthPercentile !== null) {
				femurLength["percentile"] = pregnancyUltrasoundFirstTBaby.femurLengthPercentile;
			}
			if (pregnancyUltrasoundFirstTBaby.femurLengthGestationnalAge !== undefined && pregnancyUltrasoundFirstTBaby.femurLengthGestationnalAge !== null) {
				femurLength["gestationnalAge"] = pregnancyUltrasoundFirstTBaby.femurLengthGestationnalAge;
			}
			if (femurLength["length"] || femurLength["percentile"] || femurLength["gestationnalAge"]) {
				dataList.push(femurLength);
			}
			let pregnancyFirstDopplerFoetaux: { dataList: string[], columnHeader: any[], style: any, displayHeader: boolean, sectionName: string } = { sectionName: "", style: { fontStyle: 'bold' , underline: true}, columnHeader: headerList, dataList: dataList, displayHeader: true };
			this.dataExportList.push(pregnancyFirstDopplerFoetaux);
		}
	}
	
	FormatEarlyBabyDopplersFoetaux(pregnancyUltrasoundEarlyBaby: PregnancyUltrasoundEarlyBaby) {
		let showData: boolean =
			pregnancyUltrasoundEarlyBaby?.craniocaudalLength || pregnancyUltrasoundEarlyBaby?.craniocaudalLengthPercentile || pregnancyUltrasoundEarlyBaby?.craniocaudalLengthGestationnalAge
				|| pregnancyUltrasoundEarlyBaby?.gestationnalSac || pregnancyUltrasoundEarlyBaby?.gestationnalSacPercentile || pregnancyUltrasoundEarlyBaby?.gestationnalSacGestationnalAge
				? true
				: false;
		if (showData) {
			let headerList: any[] = [];
			headerList.unshift({ key: "title", value: null });
			headerList.push({ key: "length", value: this.translationPipe.transform("lblLength") }, { key: "percentile", value: this.translationPipe.transform("lblPercentile") }, { key: "gestationnalAge", value: this.translationPipe.transform("lblGestationnalAge") });
			let dataList: any[] = [];
			let craniocaudalLength = { "title": this.translationPipe.transform("lblCraniocaudalLength") };
			if (pregnancyUltrasoundEarlyBaby.craniocaudalLength !== undefined && pregnancyUltrasoundEarlyBaby.craniocaudalLength !== null) {
				craniocaudalLength["length"] = pregnancyUltrasoundEarlyBaby.craniocaudalLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasoundEarlyBaby.craniocaudalLengthPercentile !== undefined && pregnancyUltrasoundEarlyBaby.craniocaudalLengthPercentile !== null) {
				craniocaudalLength["percentile"] = pregnancyUltrasoundEarlyBaby.craniocaudalLengthPercentile;
			}
			if (pregnancyUltrasoundEarlyBaby.craniocaudalLengthGestationnalAge !== undefined && pregnancyUltrasoundEarlyBaby.craniocaudalLengthGestationnalAge !== null) {
				craniocaudalLength["gestationnalAge"] = pregnancyUltrasoundEarlyBaby.craniocaudalLengthGestationnalAge;
			}
			if (craniocaudalLength["length"] || craniocaudalLength["percentile"] || craniocaudalLength["gestationnalAge"]) {
				dataList.push(craniocaudalLength);
			}
			let gestationnalSac = { "title": this.translationPipe.transform("lblGestationnalSac") };
			if (pregnancyUltrasoundEarlyBaby.gestationnalSac !== undefined && pregnancyUltrasoundEarlyBaby.gestationnalSac !== null) {
				gestationnalSac["length"] = pregnancyUltrasoundEarlyBaby.gestationnalSac + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasoundEarlyBaby.gestationnalSacPercentile !== undefined && pregnancyUltrasoundEarlyBaby.gestationnalSacPercentile !== null) {
				gestationnalSac["percentile"] = pregnancyUltrasoundEarlyBaby.gestationnalSacPercentile;
			}
			if (pregnancyUltrasoundEarlyBaby.gestationnalSacGestationnalAge !== undefined && pregnancyUltrasoundEarlyBaby.gestationnalSacGestationnalAge !== null) {
				gestationnalSac["gestationnalAge"] = pregnancyUltrasoundEarlyBaby.gestationnalSacGestationnalAge;
			}
			if (gestationnalSac["length"] || gestationnalSac["percentile"] || gestationnalSac["gestationnalAge"]) {
				dataList.push(gestationnalSac);
			}
			let pregnancyEarlyDopplerFoetaux: { dataList: string[], columnHeader: any[], style: any, displayHeader: boolean, sectionName: string } = { sectionName: "", style: { fontStyle: 'bold', underline: true }, columnHeader: headerList, dataList: dataList, displayHeader: true };
			this.dataExportList.push(pregnancyEarlyDopplerFoetaux);
		}
	}

	onFormatFetalBiometries(pregnancyUltrasound: any) {
		let showData: boolean = pregnancyUltrasound?.biparietalDiameter || pregnancyUltrasound?.biparietalDiameterPercentilepregnancyUltrasound ||
			pregnancyUltrasound?.biparietalDiameterGestationnalAge || pregnancyUltrasound?.headCircumference ||
			pregnancyUltrasound?.headCircumferencePercentilepregnancyUltrasound || pregnancyUltrasound?.headCircumferenceGestationnalAge ||
			pregnancyUltrasound?.abdominalCircumference || pregnancyUltrasound?.abdominalCircumferencePercentilepregnancyUltrasound ||
			pregnancyUltrasound?.abdominalCircumferenceGestationnalAge || pregnancyUltrasound?.femurLength ||
			pregnancyUltrasound?.femurLengthPercentilepregnancyUltrasound || pregnancyUltrasound?.femurLengthGestationnalAge ||
			pregnancyUltrasound?.fetalWeight || pregnancyUltrasound?.fetalWeightPercentilepregnancyUltrasound || pregnancyUltrasound?.fetalWeightGestationnalAge
			? true
			: false;
		if (showData) {
			let headerList: any[] = [];
			headerList.unshift({ key: "title", value: null });
			headerList.push({ key: "length", value: this.translationPipe.transform("lblLength") }, { key: "percentile", value: this.translationPipe.transform("lblPercentile") }, { key: "gestationnalAge", value: this.translationPipe.transform("lblGestationnalAge") });
			let dataList: any[] = [];
			let biparietalDiameter = { "title": this.translationPipe.transform("lblBiparietalDiameter") };
			if (pregnancyUltrasound.biparietalDiameter !== undefined && pregnancyUltrasound.biparietalDiameter !== null) {
				biparietalDiameter["length"] = pregnancyUltrasound?.biparietalDiameter + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			let biparietalDiameterPercentileResult: string = "";
			if (pregnancyUltrasound.biparietalDiameterPercentileValue !== undefined && pregnancyUltrasound.biparietalDiameterPercentileValue !== null) {
				biparietalDiameterPercentileResult += pregnancyUltrasound.biparietalDiameterPercentileValue;
			}
			if (pregnancyUltrasound.biparietalDiameterPercentile !== undefined && pregnancyUltrasound.biparietalDiameterPercentile !== null) {
				biparietalDiameterPercentileResult += "(" + this.getElementFromList("id", pregnancyUltrasound.biparietalDiameterPercentile?.id, DegreeEnum.getDegreeList(), "", "unique") + ")";
			}
			biparietalDiameter["percentile"] = biparietalDiameterPercentileResult;
			if (pregnancyUltrasound.biparietalDiameterGestationnalAge !== undefined && pregnancyUltrasound.biparietalDiameterGestationnalAge !== null) {
				biparietalDiameter["gestationnalAge"] = pregnancyUltrasound?.biparietalDiameterGestationnalAge;
			}
			if (biparietalDiameter["length"] !== undefined || biparietalDiameter["percentile"] !== "" || biparietalDiameter["gestationnalAge"] !== undefined) {
				dataList.push(biparietalDiameter);
			}
			let headCircumference = { title: this.translationPipe.transform("lblHeadCircumference") };
			if (pregnancyUltrasound.headCircumference !== undefined && pregnancyUltrasound.headCircumference !== null) {
				headCircumference["length"] = pregnancyUltrasound?.headCircumference + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			let headCircumferencePercentileResult: string = "";
			if (pregnancyUltrasound.headCircumferencePercentileValue !== undefined && pregnancyUltrasound.headCircumferencePercentileValue !== null) {
				headCircumferencePercentileResult += pregnancyUltrasound.headCircumferencePercentileValue;
			}
			if (pregnancyUltrasound.headCircumferencePercentile !== undefined && pregnancyUltrasound.headCircumferencePercentile !== null) {
				headCircumferencePercentileResult += "(" +
					this.getElementFromList("id", pregnancyUltrasound.headCircumferencePercentile?.id, DegreeEnum.getDegreeList(), "", "unique") + ")";
			}
			headCircumference["percentile"] = headCircumferencePercentileResult;
			if (pregnancyUltrasound.headCircumferenceGestationnalAge !== undefined && pregnancyUltrasound.headCircumferenceGestationnalAge !== null) {
				headCircumference["gestationnalAge"] = pregnancyUltrasound?.headCircumferenceGestationnalAge;
			}
			if (headCircumference["length"] || headCircumference["percentile"] !== "" || headCircumference["gestationnalAge"]) {
				dataList.push(headCircumference);
			}
			let abdominalCircumference = { title: this.translationPipe.transform("lblAbdominalCircumference") };
			if (pregnancyUltrasound.abdominalCircumference !== undefined && pregnancyUltrasound.abdominalCircumference !== null) {
				abdominalCircumference["length"] = pregnancyUltrasound?.abdominalCircumference + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			let abdominalCircumferencePercentileResult: string = "";
			if (pregnancyUltrasound.abdominalCircumferencePercentileValue !== undefined && pregnancyUltrasound.abdominalCircumferencePercentileValue !== null) {
				abdominalCircumferencePercentileResult += pregnancyUltrasound.abdominalCircumferencePercentileValue;
			}
			if (pregnancyUltrasound.abdominalCircumferencePercentile !== undefined && pregnancyUltrasound.abdominalCircumferencePercentile !== null) {
				abdominalCircumferencePercentileResult += "(" +
					this.getElementFromList("id", pregnancyUltrasound.abdominalCircumferencePercentile?.id, DegreeEnum.getDegreeList(), "", "unique") + ")";
			}
			abdominalCircumference["percentile"] = abdominalCircumferencePercentileResult;
			if (pregnancyUltrasound.abdominalCircumferenceGestationnalAge !== undefined && pregnancyUltrasound.abdominalCircumferenceGestationnalAge !== null) {
				abdominalCircumference["gestationnalAge"] = pregnancyUltrasound?.abdominalCircumferenceGestationnalAge;
			}
			if (abdominalCircumference["length"] || abdominalCircumference["percentile"] !== "" || abdominalCircumference["gestationnalAge"]) {
				dataList.push(abdominalCircumference);
			}
			let femurLength = { title: this.translationPipe.transform("lblFemurLength") };
			if (pregnancyUltrasound.femurLength !== undefined && pregnancyUltrasound.femurLength !== null) {
				femurLength["length"] = pregnancyUltrasound?.femurLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			let femurLengthPercentileResult: string = "";
			if (pregnancyUltrasound.femurLengthPercentileValue !== undefined && pregnancyUltrasound.femurLengthPercentileValue !== null) {
				femurLengthPercentileResult += pregnancyUltrasound.femurLengthPercentileValue;
			}
			if (pregnancyUltrasound.femurLengthPercentile !== undefined && pregnancyUltrasound.femurLengthPercentile !== null) {
				femurLengthPercentileResult += "(" +
					this.getElementFromList("id", pregnancyUltrasound.femurLengthPercentile?.id, DegreeEnum.getDegreeList(), "", "unique") + ")";
			}
			femurLength["percentile"] = femurLengthPercentileResult;
			if (pregnancyUltrasound.femurLengthGestationnalAge !== undefined && pregnancyUltrasound.femurLengthGestationnalAge !== null) {
				femurLength["gestationnalAge"] = pregnancyUltrasound?.femurLengthGestationnalAge;
			}
			if (femurLength["length"] || femurLength["percentile"] !== "" || femurLength["gestationnalAge"]) {
				dataList.push(femurLength);
			}
			let fetalWeight = { title: this.translationPipe.transform("lblFetalWeight") };
			if (pregnancyUltrasound.fetalWeight !== undefined && pregnancyUltrasound.fetalWeight !== null) {
				fetalWeight["length"] = pregnancyUltrasound?.fetalWeight + ' ' + this.translationPipe.transform("lblGramme");
			}
			let fetalWeightPercentileResult: string = "";
			if (pregnancyUltrasound.fetalWeightPercentileValue !== undefined && pregnancyUltrasound.fetalWeightPercentileValue !== null) {
				fetalWeightPercentileResult += pregnancyUltrasound.fetalWeightPercentileValue;
			}
			if (pregnancyUltrasound.fetalWeightPercentile !== undefined && pregnancyUltrasound.fetalWeightPercentile !== null) {
				fetalWeightPercentileResult += "(" +
					this.getElementFromList("id", pregnancyUltrasound.fetalWeightPercentile?.id, DegreeEnum.getDegreeList(), "", "unique") + ")";
			}
			fetalWeight["percentile"] = fetalWeightPercentileResult;
			if (pregnancyUltrasound.fetalWeightGestationnalAge !== undefined && pregnancyUltrasound.fetalWeightGestationnalAge !== null) {
				fetalWeight["gestationnalAge"] = pregnancyUltrasound?.fetalWeightGestationnalAge;
			}
			if (fetalWeight["length"] || fetalWeight["percentile"] !== "" || fetalWeight["gestationnalAge"]) {
				dataList.push(fetalWeight);
			}
			let fetalBiometries: { dataList: string[], columnHeader: any[], style: any, displayHeader: boolean, sectionName: string } = { sectionName: this.translationPipe.transform("lblFetalBiometries"), style: { fontStyle: 'bold' , underline: true}, columnHeader: headerList, dataList: dataList, displayHeader: true };
			this.dataExportList.push(fetalBiometries);
		}
	}
	
	onFormatMembersBiometries(pregnancyUltrasound: any) {
		let showData: boolean = pregnancyUltrasound?.humerusLength || pregnancyUltrasound?.humerusLengthPercentile ||
			pregnancyUltrasound?.humerusLengthGestationnalAge || pregnancyUltrasound?.radiusLength ||
			pregnancyUltrasound?.radiusLengthPercentile || pregnancyUltrasound?.radiusLengthGestationnalAge ||
			pregnancyUltrasound?.ulnaLength || pregnancyUltrasound?.ulnaLengthPercentile ||
			pregnancyUltrasound?.ulnaLengthGestationnalAge || pregnancyUltrasound?.tibiaLength ||
			pregnancyUltrasound?.tibiaLengthPercentile || pregnancyUltrasound?.tibiaLengthGestationnalAge ||
			pregnancyUltrasound?.fibulaLength || pregnancyUltrasound?.fibulaLengthPercentile ||
			pregnancyUltrasound?.fibulaLengthGestationnalAge || pregnancyUltrasound?.footLength ||
			pregnancyUltrasound?.footLengthPercentile || pregnancyUltrasound?.footLengthGestationnalAge
			? true : false;
		if (showData) {
			let headerList: any[] = [];
			headerList.unshift({ key: "title", value: null });
			headerList.push({ key: "length", value: this.translationPipe.transform("lblLength") }, { key: "percentile", value: this.translationPipe.transform("lblPercentile") }, { key: "gestationnalAge", value: this.translationPipe.transform("lblGestationnalAge") });
			let dataList: any[] = [];
			let humerusLength = { "title": this.translationPipe.transform("lblHumerusLength") };
			if (pregnancyUltrasound.humerusLength !== undefined && pregnancyUltrasound.humerusLength !== null) {
				humerusLength["length"] = pregnancyUltrasound?.humerusLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasound.humerusLengthPercentile !== undefined && pregnancyUltrasound.humerusLengthPercentile !== null) {
				humerusLength["percentile"] = pregnancyUltrasound.humerusLengthPercentile;
			}
			if (pregnancyUltrasound.humerusLengthGestationnalAge !== undefined && pregnancyUltrasound.humerusLengthGestationnalAge !== null) {
				humerusLength["gestationnalAge"] = pregnancyUltrasound.humerusLengthGestationnalAge;
			}
			if (humerusLength["length"] || humerusLength["percentile"] || humerusLength["gestationnalAge"]) {
				dataList.push(humerusLength);
			}
			let radiusLength = { title: this.translationPipe.transform("lblRadiusLength") };
			if (pregnancyUltrasound.radiusLength !== undefined && pregnancyUltrasound.radiusLength !== null) {
				radiusLength["length"] = pregnancyUltrasound.radiusLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasound.radiusLengthPercentile !== undefined && pregnancyUltrasound.radiusLengthPercentile !== null) {
				radiusLength["percentile"] = pregnancyUltrasound.radiusLengthPercentile;
			}
			if (pregnancyUltrasound.radiusLengthGestationnalAge !== undefined && pregnancyUltrasound.radiusLengthGestationnalAge !== null) {
				radiusLength["gestationnalAge"] = pregnancyUltrasound.radiusLengthGestationnalAge;
			}
			if (radiusLength["length"] || radiusLength["percentile"] || radiusLength["gestationnalAge"]) {
				dataList.push(radiusLength);
			}
			let ulnaLength = { title: this.translationPipe.transform("lblUlnaLenght") };
			if (pregnancyUltrasound.ulnaLength !== undefined && pregnancyUltrasound.ulnaLength !== null) {
				ulnaLength["length"] = pregnancyUltrasound.ulnaLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasound.ulnaLengthPercentile !== undefined && pregnancyUltrasound.ulnaLengthPercentile !== null) {
				ulnaLength["percentile"] = pregnancyUltrasound.ulnaLengthPercentile;
			}
			if (pregnancyUltrasound.ulnaLengthGestationnalAge !== undefined && pregnancyUltrasound.ulnaLengthGestationnalAge !== null) {
				ulnaLength["gestationnalAge"] = pregnancyUltrasound.ulnaLengthGestationnalAge;
			}
			if (ulnaLength["length"] || ulnaLength["percentile"] || ulnaLength["gestationnalAge"]) {
				dataList.push(ulnaLength);
			}
			let tibiaLength = { title: this.translationPipe.transform("lblTibiaLength") };
			if (pregnancyUltrasound.tibiaLength !== undefined && pregnancyUltrasound.tibiaLength !== null) {
				tibiaLength["length"] = pregnancyUltrasound.tibiaLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasound.tibiaLengthPercentile !== undefined && pregnancyUltrasound.tibiaLengthPercentile !== null) {
				tibiaLength["percentile"] = pregnancyUltrasound.tibiaLengthPercentile;
			}
			if (pregnancyUltrasound.tibiaLengthGestationnalAge !== undefined && pregnancyUltrasound.tibiaLengthGestationnalAge !== null) {
				tibiaLength["gestationnalAge"] = pregnancyUltrasound.tibiaLengthGestationnalAge;
			}
			if (tibiaLength["length"] || tibiaLength["percentile"] || tibiaLength["gestationnalAge"]) {
				dataList.push(tibiaLength);
			}
			let fibulaLength = { title: this.translationPipe.transform("lblFibulaLength") };
			if (pregnancyUltrasound.fibulaLength !== undefined && pregnancyUltrasound.fibulaLength !== null) {
				fibulaLength["length"] = pregnancyUltrasound.fibulaLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasound.fibulaLengthPercentile !== undefined && pregnancyUltrasound.fibulaLengthPercentile !== null) {
				fibulaLength["percentile"] = pregnancyUltrasound.fibulaLengthPercentile;
			}
			if (pregnancyUltrasound.fibulaLengthGestationnalAge !== undefined && pregnancyUltrasound.fibulaLengthGestationnalAge !== null) {
				fibulaLength["gestationnalAge"] = pregnancyUltrasound.fibulaLengthGestationnalAge;
			}
			if (fibulaLength["length"] || fibulaLength["percentile"] || fibulaLength["gestationnalAge"]) {
				dataList.push(fibulaLength);
			}
			let footLength = { title: this.translationPipe.transform("lblFootLength") };
			if (pregnancyUltrasound.footLength !== undefined && pregnancyUltrasound.footLength !== null) {
				footLength["length"] = pregnancyUltrasound.footLength + ' ' + this.translationPipe.transform("lblUnitMm");
			}
			if (pregnancyUltrasound.footLengthPercentile !== undefined && pregnancyUltrasound.footLengthPercentile !== null) {
				footLength["percentile"] = pregnancyUltrasound.footLengthPercentile;
			}
			if (pregnancyUltrasound.footLengthGestationnalAge !== undefined && pregnancyUltrasound.footLengthGestationnalAge !== null) {
				footLength["gestationnalAge"] = pregnancyUltrasound.footLengthGestationnalAge;
			}
			if (footLength["length"] || footLength["percentile"] || footLength["gestationnalAge"]) {
				dataList.push(footLength);
			}
			let membersBiometries: { dataList: string[], columnHeader: any[], style: any, displayHeader: boolean, sectionName: string } = { sectionName: this.translationPipe.transform("lblMembersBiometries"), style: { fontStyle: 'bold', underline: true }, columnHeader: headerList, dataList: dataList, displayHeader: true };
			this.dataExportList.push(membersBiometries);
		}
	}
	
	onFormatDopplersFoetaux(pregnancyUltrasound: any) {
		let showData: boolean = pregnancyUltrasound?.dopplerOmbilical || pregnancyUltrasound?.dopplerOmbilicalPercentile ||
			pregnancyUltrasound?.dopplerOmbilicalPulsatilityIndex || pregnancyUltrasound?.dopplerOmbilicalPercentileValue ||
			pregnancyUltrasound?.dopplerCerebral || pregnancyUltrasound?.dopplerCerebralPercentile ||
			pregnancyUltrasound?.dopplerCerebralPulsatilityIndex || pregnancyUltrasound?.dopplerCerebralPercentileValue ||
			pregnancyUltrasound?.dopplerArentius || pregnancyUltrasound?.dopplerArentiusPercentile ||
			pregnancyUltrasound?.dopplerArentiusPulsatilityIndex || pregnancyUltrasound?.dopplerArentiusPercentileValue
			? true
			: false;
		if (showData) {
			let headerList: any[] = [];
			headerList.unshift({ key: "title", value: null });
			headerList.push({ key: "doppler", value: this.translationPipe.transform("lblDoppler") }, { key: "percentile", value: this.translationPipe.transform("lblPercentile") }, { key: "pulsatilityIndex", value: this.translationPipe.transform("lblPulsatilityIndex") }, { key: "percentileValue", value: this.translationPipe.transform("lblPercentile") });
			let dataList: any[] = [];
			let ombilicalArtery = { "title": this.translationPipe.transform("lblOmbilicalArtery") };
			if (pregnancyUltrasound.dopplerOmbilical !== undefined && pregnancyUltrasound.dopplerOmbilical !== null) {
				ombilicalArtery["doppler"] = pregnancyUltrasound?.dopplerOmbilical;
			}
			if (pregnancyUltrasound.dopplerOmbilicalPercentile !== undefined && pregnancyUltrasound.dopplerOmbilicalPercentile !== null) {
				ombilicalArtery["percentile"] = pregnancyUltrasound?.dopplerOmbilicalPercentile;
			}
			if (pregnancyUltrasound.dopplerOmbilicalPulsatilityIndex !== undefined && pregnancyUltrasound.dopplerOmbilicalPulsatilityIndex !== null) {
				ombilicalArtery["pulsatilityIndex"] = pregnancyUltrasound?.dopplerOmbilicalPulsatilityIndex;
			}
			if (pregnancyUltrasound.dopplerOmbilicalPercentileValue !== undefined && pregnancyUltrasound.dopplerOmbilicalPercentileValue !== null) {
				ombilicalArtery["percentileValue"] = pregnancyUltrasound?.dopplerOmbilicalPercentileValue;
			}
			if (ombilicalArtery["doppler"] || ombilicalArtery["percentile"] || ombilicalArtery["pulsatilityIndex"] || ombilicalArtery["percentileValue"]) {
				dataList.push(ombilicalArtery);
			}
			let cerebralArtery = { title: this.translationPipe.transform("lblCerebralArtery") };
			if (pregnancyUltrasound.dopplerCerebral !== undefined && pregnancyUltrasound.dopplerCerebral !== null) {
				cerebralArtery["doppler"] = pregnancyUltrasound?.dopplerCerebral;
			}
			if (pregnancyUltrasound.dopplerCerebralPercentile !== undefined && pregnancyUltrasound.dopplerCerebralPercentile !== null) {
				cerebralArtery["percentile"] = pregnancyUltrasound?.dopplerCerebralPercentile;
			}
			if (pregnancyUltrasound.dopplerCerebralPulsatilityIndex !== undefined && pregnancyUltrasound.dopplerCerebralPulsatilityIndex !== null) {
				cerebralArtery["pulsatilityIndex"] = pregnancyUltrasound?.dopplerCerebralPulsatilityIndex;
			}
			if (pregnancyUltrasound.dopplerCerebralPercentileValue !== undefined && pregnancyUltrasound.dopplerCerebralPercentileValue !== null) {
				cerebralArtery["percentileValue"] = pregnancyUltrasound?.dopplerCerebralPercentileValue;
			}
			if (cerebralArtery["doppler"] || cerebralArtery["percentile"] || cerebralArtery["pulsatilityIndex"] || ombilicalArtery["percentileValue"]) {
				dataList.push(cerebralArtery);
			}
			let arentiusCanal = { title: this.translationPipe.transform("lblArentiusCanal") };
			if (pregnancyUltrasound.dopplerArentius !== undefined && pregnancyUltrasound.dopplerArentius !== null) {
				arentiusCanal["doppler"] = pregnancyUltrasound?.dopplerArentius;
			}
			if (pregnancyUltrasound.dopplerArentiusPercentile !== undefined && pregnancyUltrasound.dopplerArentiusPercentile !== null) {
				arentiusCanal["percentile"] = pregnancyUltrasound?.dopplerArentiusPercentile;
			}
			if (pregnancyUltrasound.dopplerArentiusPulsatilityIndex !== undefined && pregnancyUltrasound.dopplerArentiusPulsatilityIndex !== null) {
				arentiusCanal["pulsatilityIndex"] = pregnancyUltrasound?.dopplerArentiusPulsatilityIndex;
			}
			if (pregnancyUltrasound.dopplerArentiusPercentileValue !== undefined && pregnancyUltrasound.dopplerArentiusPercentileValue !== null) {
				arentiusCanal["percentileValue"] = pregnancyUltrasound?.dopplerArentiusPercentileValue;
			}
			if (arentiusCanal["doppler"] || arentiusCanal["percentile"] || arentiusCanal["pulsatilityIndex"] || ombilicalArtery["percentileValue"]) {
				dataList.push(arentiusCanal);
			}
			let dopplersFoetaux: { dataList: string[], columnHeader: any[], style: any, displayHeader: boolean, sectionName: string } = { sectionName: this.translationPipe.transform("lblDopplersFoetaux"), style: { fontStyle: 'bold', underline: true }, columnHeader: headerList, dataList: dataList, displayHeader: true };
			this.dataExportList.push(dopplersFoetaux);
		}
	}

	onFormatFertilityControlledOvarianHyperstimulationDaysDataTable(fertilityControlledOvarianHyperstimulationDaysList: FertilityControlledOvarianHyperstimulationDays[]) {
		fertilityControlledOvarianHyperstimulationDaysList = this.sortFertilityControlledOvarianHyperstimulationDaysList(fertilityControlledOvarianHyperstimulationDaysList);
		let dateList: any[] = fertilityControlledOvarianHyperstimulationDaysList.map(day => {
			let date = day.date;
			if (moment.isMoment(date)) {
				date = this.dateService.getUtcMsFromMoment(date);
			}
			return { key: date, value: this.dateService.getStringUtcDate(date, "DD/MM") };
		});
		dateList.unshift({ key: "title", value: null })
		let dataList: any[] = [];
		let cycleDay = { "title": this.translationPipe.transform('lblCycleDay') };
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			let date = day.date;
			if (moment.isMoment(date)) {
				date = this.dateService.getUtcMsFromMoment(date);
			}
			cycleDay[date] = day.cycleDay;
		});
		dataList.push(cycleDay);
		let stimulationDay = { "title": this.translationPipe.transform('lblStimulationDay') };
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			let date = day.date;
			if (moment.isMoment(date)) {
				date = this.dateService.getUtcMsFromMoment(date);
			}
			stimulationDay[date] = day.stimulationDay;
		});
		dataList.push(stimulationDay);
		this.setFertilityControlledOvarianHyperstimulationTreatmentMap(fertilityControlledOvarianHyperstimulationDaysList, dataList);
		this.setFertilityControlledOvarianHyperstimulationBiologyMap(fertilityControlledOvarianHyperstimulationDaysList, dataList);
		let ovaryLeft = { "title": this.translationPipe.transform('lblOvaryLeft') };
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			let date = day.date;
			if (moment.isMoment(date)) {
				date = this.dateService.getUtcMsFromMoment(date);
			}
			let fertilityControlledOvarianHyperstimulationDaysOvaryLeftListResult = "";
			let fertilityControlledOvarianHyperstimulationDaysOvaryLeftList = day.fertilityControlledOvarianHyperstimulationDaysOvaryLeftList;
			if (fertilityControlledOvarianHyperstimulationDaysOvaryLeftList != undefined && fertilityControlledOvarianHyperstimulationDaysOvaryLeftList != null) {
				fertilityControlledOvarianHyperstimulationDaysOvaryLeftList.sort((f1, f2) => {
					if (f1.ovarySize > f2.ovarySize) { return 1; }
					if (f1.ovarySize < f2.ovarySize) { return -1; }
					return 0;
				}).forEach(fertilityControlledOvarianHyperstimulationDaysOvaryLeft => {
					let value = " " + fertilityControlledOvarianHyperstimulationDaysOvaryLeft.ovarySize + " (" + fertilityControlledOvarianHyperstimulationDaysOvaryLeft.ovaryNumber + ") \n";
					fertilityControlledOvarianHyperstimulationDaysOvaryLeftListResult = fertilityControlledOvarianHyperstimulationDaysOvaryLeftListResult + value;
				});
			}
			ovaryLeft[date] = fertilityControlledOvarianHyperstimulationDaysOvaryLeftListResult;
		});
		dataList.push(ovaryLeft);
		let ovaryRight = { "title": this.translationPipe.transform('lblOvaryRight') };
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			let date = day.date;
			if (moment.isMoment(date)) {
				date = this.dateService.getUtcMsFromMoment(date);
			}
			let fertilityControlledOvarianHyperstimulationDaysOvaryRightListResult = "";
			let fertilityControlledOvarianHyperstimulationDaysOvaryRightList = day.fertilityControlledOvarianHyperstimulationDaysOvaryRightList;
			if (fertilityControlledOvarianHyperstimulationDaysOvaryRightList != undefined && fertilityControlledOvarianHyperstimulationDaysOvaryRightList != null) {
				fertilityControlledOvarianHyperstimulationDaysOvaryRightList.sort((f1, f2) => {
					if (f1.ovarySize > f2.ovarySize) { return 1; }
					if (f1.ovarySize < f2.ovarySize) { return -1; }
					return 0;
				}).forEach(fertilityControlledOvarianHyperstimulationDaysOvaryRight => {
					let value = " " + fertilityControlledOvarianHyperstimulationDaysOvaryRight.ovarySize + " (" + fertilityControlledOvarianHyperstimulationDaysOvaryRight.ovaryNumber + ") \n";
					fertilityControlledOvarianHyperstimulationDaysOvaryRightListResult = fertilityControlledOvarianHyperstimulationDaysOvaryRightListResult + value;
				});
			}
			ovaryRight[date] = fertilityControlledOvarianHyperstimulationDaysOvaryRightListResult;
		});
		dataList.push(ovaryRight);
		let endometriumThickness = { "title": this.translationPipe.transform('lblEndometriumThickness') };
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			let date = day.date;
			if (moment.isMoment(date)) {
				date = this.dateService.getUtcMsFromMoment(date);
			}
			endometriumThickness[date] = day.endometriumThickness;
		});
		dataList.push(endometriumThickness);
		let endometriumType = { "title": this.translationPipe.transform('lblEndometriumType') };
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			let date = day.date;
			if (moment.isMoment(date)) {
				date = this.dateService.getUtcMsFromMoment(date);
			}
			endometriumType[date] = day.endometriumType;
		});
		dataList.push(endometriumType);
		let comment = { "title": this.translationPipe.transform('lblComment') };
		let evenments: Data[] = EventEnum.getEventList();
		const regex = /<ul>.*?<li>.*?<\/li>.*?<\/ul>/s;
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			let custumerdata = "<ul>";
			let date = day.date;
			if (moment.isMoment(date)) {
				date = this.dateService.getUtcMsFromMoment(date);
			}
			if (day.comment !== undefined && day.comment !== null) {
				custumerdata += "<li>" + day.comment + "</li>";
			}
			if (day.event !== undefined && day.event !== null) {
				custumerdata += "<li>" + this.translationPipe.transform(evenments.find(e => e.id === day.event.id).name) + "</li>";
			}
			custumerdata += "</ul>";
			if (regex.test(custumerdata)) {
				comment[date] = custumerdata;
			} else {
				comment[date] = '';
			}
		});
		dataList.push(comment);
		let fertilityControlledOvarianHyperstimulationDaysMap: { dataList: string[], columnHeader: any[], style: any, displayHeader: boolean, sectionName: string } = { sectionName: this.translationPipe.transform("lblDays"), style: { fontStyle: 'bold' }, columnHeader: dateList, dataList: dataList, displayHeader: true };
		this.dataExportList.push(fertilityControlledOvarianHyperstimulationDaysMap);
	}

	setFertilityControlledOvarianHyperstimulationTreatmentMap(fertilityControlledOvarianHyperstimulationDaysList: FertilityControlledOvarianHyperstimulationDays[], dataList: any[]) {
		let drugList: FertilityControlledOvarianHyperstimulationDrug[] = [];
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			day.fertilityControlledOvarianHyperstimulationTreatmentList.forEach(treatment => {
				drugList.push(treatment.fertilityControlledOvarianHyperstimulationDrug);
			});
		});
		let treatment = { "title": this.translationPipe.transform('lblTreatment') };
		dataList.push(treatment);
		drugList = drugList.sort((d1, d2) => {
			if (d1.name > d2.name) { return 1; }
			if (d1.name < d2.name) { return -1; }
			return 0;
		});
		drugList = drugList.filter((drug, index, self) =>
			index === self.findIndex((d) => (
				d.idFertilityControlledOvarianHyperstimulationDrug === drug.idFertilityControlledOvarianHyperstimulationDrug
			))
		);
		drugList.forEach((drug, index) => {
			let drugName = "=> " + this.translationPipe.transform(drug.name) + " (" + this.translationPipe.transform(drug.unit) + ")";
			let drugDay = { "title": drugName };
			fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
				let date = day.date;
				if (moment.isMoment(date)) {
					date = this.dateService.getUtcMsFromMoment(date);
				}
				let treatmentList = day.fertilityControlledOvarianHyperstimulationTreatmentList;
				let treatment = treatmentList.find(treatment => {
					return drug.idFertilityControlledOvarianHyperstimulationDrug
						=== treatment.fertilityControlledOvarianHyperstimulationDrug.idFertilityControlledOvarianHyperstimulationDrug;
				});
				if (treatment !== undefined && treatment !== null) {
					drugDay[date] = treatment.dose;
				}
			});
			dataList.push(drugDay);
		});
	}
	
	setFertilityControlledOvarianHyperstimulationBiologyMap(fertilityControlledOvarianHyperstimulationDaysList: FertilityControlledOvarianHyperstimulationDays[], dataList: any[]) {
		let hormoneList: FertilityControlledOvarianHyperstimulationHormone[] = [];
		fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
			day.fertilityControlledOvarianHyperstimulationBiologyList.forEach(biology => {
				hormoneList.push(biology.fertilityControlledOvarianHyperstimulationHormone);
			});
		});
		let biology = { "title": this.translationPipe.transform('lblBiology') };
		dataList.push(biology);
		hormoneList = hormoneList.sort((h1, h2) => {
			if (h1.name > h2.name) { return 1; }
			if (h1.name < h2.name) { return -1; }
			return 0;
		});
		hormoneList = hormoneList.filter((hormone, index, self) =>
			index === self.findIndex((h) => (
				h.idFertilityControlledOvarianHyperstimulationHormone === hormone.idFertilityControlledOvarianHyperstimulationHormone
			))
		);
		hormoneList.forEach((hormone, index) => {
			let hormoneName = "=> " + this.translationPipe.transform(hormone.name) + " (" + this.translationPipe.transform(hormone.unit) + ")";
			let hormoneDay = { "title": hormoneName };
			fertilityControlledOvarianHyperstimulationDaysList.forEach(day => {
				let date = day.date;
				if (moment.isMoment(date)) {
					date = this.dateService.getUtcMsFromMoment(date);
				}
				let biologyList = day.fertilityControlledOvarianHyperstimulationBiologyList;
				let biology = biologyList.find(biology => {
					return hormone.idFertilityControlledOvarianHyperstimulationHormone
						=== biology.fertilityControlledOvarianHyperstimulationHormone.idFertilityControlledOvarianHyperstimulationHormone;
				});
				if (biology !== undefined && biology !== null) {
					hormoneDay[date] = biology.level;
				}
			});
			dataList.push(hormoneDay);
		});
	}

	sortFertilityControlledOvarianHyperstimulationDaysList(fertilityControlledOvarianHyperstimulationDaysList: FertilityControlledOvarianHyperstimulationDays[]) {
		let listResult = fertilityControlledOvarianHyperstimulationDaysList.sort((d1, d2) => {
			let date1;
			if (moment.isMoment(d1.date)) {
				date1 = this.dateService.getUtcMsFromMoment(d1.date);
			} else {
				date1 = d1.date;
			}
			let date2;
			if (moment.isMoment(d2.date)) {
				date2 = this.dateService.getUtcMsFromMoment(d2.date);
			} else {
				date2 = d2.date;
			}
			if (date1 > date2) {
				return 1;
			}
			if (date1 < date2) {
				return -1;
			}
			return 0;
		});
		return listResult;
	}

	onFormatOvarianHyperstimulationList(fertilityControlledOvarianHyperstimulationList: any) {
		for (let i = 0; i < fertilityControlledOvarianHyperstimulationList.length; i++) {
			let element = fertilityControlledOvarianHyperstimulationList[i];
			this.onFormatOvarianHyperstimulationDetails(element);
			if (element.fertilityControlledOvarianHyperstimulationDaysList && element.fertilityControlledOvarianHyperstimulationDaysList.length > 0) {
				this.onFormatFertilityControlledOvarianHyperstimulationDaysDataTable(element.fertilityControlledOvarianHyperstimulationDaysList)
			}
		}
	}
	
	onFormatOvarianHyperstimulationDetails(element: any) {
		let fertilityControlledOvarianHyperstimulationDetails = {
			sectionName: this.translationPipe.transform("lblFertilityConsultation"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "lastPeriodDate", value: this.translationPipe.transform("lblLastPeriodDate") },
				{ key: "fertilityPretreatment", value: this.translationPipe.transform("lblPretreatment") },
				{ key: "fertilityProtocol", value: this.translationPipe.transform("lblProtocol") },
				{ key: "fertilityMap", value: this.translationPipe.transform("lblMap") },
				{ key: "interrogation", value: this.translationPipe.transform("lblInterrogation")}

			],
			viewStructure: [4,1],
			dataList: [],
		}
		fertilityControlledOvarianHyperstimulationDetails.dataList = [];
		let data = {
			lastPeriodDate: this.dateUtcTimezonePipe.transform(element.lastPeriodDate),
			fertilityPretreatment: this.getElementFromList("idFertilityPretreatment", element.fertilityPretreatment?.idFertilityPretreatment, this.dataSharedService.fertilitySettings.fertilityPretreatmentList, "", "unique"),
			fertilityProtocol: this.getElementFromList("idFertilityProtocol", element.fertilityProtocol?.idFertilityProtocol, this.dataSharedService.fertilitySettings.fertilityProtocolList, "", "unique"),
			fertilityMap: this.getElementFromList("idFertilityMap", element.fertilityMap?.idFertilityMap, this.dataSharedService.fertilitySettings.fertilityMapList, "", "unique"),
			interrogation: element.interrogation !== "" && element.interrogation !== '<br>' && element.interrogation !== undefined ?  '<br>' + element?.interrogation.replace(/<\/?p>/g, ""): undefined
		}
		fertilityControlledOvarianHyperstimulationDetails.dataList?.push(data)
		this.dataExportList.push(fertilityControlledOvarianHyperstimulationDetails);
	}
	
	onFormatPregnancyBloodPressureProfileData(pregnancyBloodPressureProfileList: any) {
		let pregnancyBloodPressureProfileSection = {
			sectionName: this.translationPipe.transform("lblPregnancyBloodPressureProfileList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "hour", value: this.translationPipe.transform("lblHour") },
				{ key: "systolicLevel", value: this.translationPipe.transform("lblSystolicLevel") },
				{ key: "diastolicLevel", value: this.translationPipe.transform("lblDiastolicLevel") },
				{ key: "interpretation", value: this.translationPipe.transform("lblInterpretation") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < pregnancyBloodPressureProfileList.length; i++) {
			let element = pregnancyBloodPressureProfileList[i];
			let data = {
				date: this.dateUtcTimezonePipe.transform(element.date),
				hour: element?.hour,
				systolicLevel: element?.systolicLevel,
				diastolicLevel: element?.diastolicLevel,
				interpretation: this.getElementFromList("id", element.interpretation?.id, StatusEnum.getStatusList(), "", "unique"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			pregnancyBloodPressureProfileSection.dataList?.push(data)
		}
		this.dataExportList.push(pregnancyBloodPressureProfileSection);
	}

	onFormatPregnancyGlycemicCycleData(pregnancyGlycemicCycleList: any) {
		let pregnancyGlycemicCycleSection = {
			sectionName: this.translationPipe.transform("lblPregnancyGlycemicCycleList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "hour", value: this.translationPipe.transform("lblHour") },
				{ key: "level", value: this.translationPipe.transform("lblLevel") },
				{ key: "unit", value: this.translationPipe.transform("lblUnit") },
				{ key: "interpretation", value: this.translationPipe.transform("lblInterpretation") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < pregnancyGlycemicCycleList.length; i++) {
			let element = pregnancyGlycemicCycleList[i];
			let data = {
				date: this.dateUtcTimezonePipe.transform(element.date),
				hour: element?.hour,
				level: element?.level,
				unit: this.getElementFromList("id", element.unit?.id, PregnancyGlycemicCycleUnitEnum.getPregnancyGlycemicCycleUnitList(), "", "unique"),
				interpretation: this.getElementFromList("id", element.interpretation?.id, LevelEnum.getLevelList(), "", "unique"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			pregnancyGlycemicCycleSection.dataList?.push(data)
		}
		this.dataExportList.push(pregnancyGlycemicCycleSection);
	}
	
	initGeneralMedicinePatientListFormat(generalMedicineList: GeneralMedicine[]) {
		for (let i = 0; i < generalMedicineList.length; i++) {
			let generalMedicine = generalMedicineList[i];
			this.initGeneralMedicineFormat(generalMedicine);
		}
	}
    
	initGeneralMedicineFormat(generalMedicine: any) {
		let generalMedicineSection = {
			sectionName: this.translationPipe.transform("lblPatient"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "age", value: this.translationPipe.transform("lblAge") },
				{ key: "profession", value: this.translationPipe.transform("lblProfession") },
				{ key: "civilStatus", value: this.translationPipe.transform("lblCivilStatus") },
				{ key: "questioning", value: this.translationPipe.transform("lblQuestioning") },
			],
			viewStructure: [4],
			dataList: [],
		}
		let data = {
			age: generalMedicine?.age + ' ' + this.translationPipe.transform("lblYears"),
			profession: generalMedicine?.profession,
			civilStatus: this.getElementFromList("id", generalMedicine.civilStatus?.id, CivilStatusEnum.getCivilStatusList(), "", "unique"),
			questioning: generalMedicine?.questioning
		}
		generalMedicineSection.dataList.push(data);
		this.dataExportList.push(generalMedicineSection);
	}
    
	initCardiologyPatientListFormat(cardiologyList: Cardiology[]) {
		for (let i = 0; i < cardiologyList.length; i++) {
			let cardiology = cardiologyList[i];
			this.initCardiologyFormat(cardiology);
		}
	}

	initCardiologyFormat(cardiology: any) {
		let cardiologySection = {
			sectionName: this.translationPipe.transform("lblPatient"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "age", value: this.translationPipe.transform("lblAge") },
				{ key: "profession", value: this.translationPipe.transform("lblProfession") },
				{ key: "civilStatus", value: this.translationPipe.transform("lblCivilStatus") },
				{ key: "questioning", value: this.translationPipe.transform("lblQuestioning") },
			],
			viewStructure: [4],
			dataList: [],
		}
		let data = {
			age: cardiology?.age + ' ' + this.translationPipe.transform("lblYears"),
			profession: cardiology?.profession,
			civilStatus: this.getElementFromList("id", cardiology.civilStatus?.id, CivilStatusEnum.getCivilStatusList(), "", "unique"),
			questioning: cardiology?.questioning
		}
		cardiologySection.dataList.push(data);
		this.dataExportList.push(cardiologySection);
	}

	initGynecoPatientListFormat(gynecoList: Gyneco[]) {
		for (let i = 0; i < gynecoList.length; i++) {
			let gyneco = gynecoList[i];
			this.initGynecoFormat(gyneco);
		}
	}
    
	initGynecoFormat(gyneco: any) {
		let gynecoSection = {
			sectionName: this.translationPipe.transform("lblPatient"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "age", value: this.translationPipe.transform("lblAge") },
				{ key: "gynecoReasonListDeprecated", value: this.translationPipe.transform("lblGynecoReason") },
				{ key: "profession", value: this.translationPipe.transform("lblProfession") },
				{ key: "civilStatus", value: this.translationPipe.transform("lblCivilStatus") },
				{ key: "gestity", value: this.translationPipe.transform("lblGestity") },
				{ key: "parity", value: this.translationPipe.transform("lblParity") },
				{ key: "hormonalStatus", value: this.translationPipe.transform("lblHormonalStatus") },
				{ key: "ageMenarche", value: this.translationPipe.transform("lblMenarcheAge") },
				{ key: "firstIntercourseDate", value: this.translationPipe.transform("lblFirstIntercourseDate") },
				{ key: "mariageDate", value: this.translationPipe.transform("lblMariageDate") },
				{ key: "menopauseDate", value: this.translationPipe.transform("lblMenopauseDate") },
				{ key: "dysmenorhea", value: this.translationPipe.transform("lblDysmenorhea") },
				{ key: "metrorrhagia", value: this.translationPipe.transform("lblMetrorrhagia") },
				{ key: "menstrualCycleRegularity", value: this.translationPipe.transform("lblMenstrualCycleRegularity") },
				{ key: "menstrualCycleLengthMin", value: this.translationPipe.transform("lblMenstrualCycleLengthMin") },
				{ key: "menstrualCycleLengthMax", value: this.translationPipe.transform("lblMenstrualCycleLengthMax") },
				{ key: "menstrualPeriodLengthMin", value: this.translationPipe.transform("lblMenstrualPeriodLengthMin") },
				{ key: "menstrualPeriodLengthMax", value: this.translationPipe.transform("lblMenstrualPeriodLengthMax") },
				{ key: "questioning", value: this.translationPipe.transform("lblQuestioning") },
			],
			viewStructure: [7, 6,5,1],
			dataList: [],
		}
		let data = {
			age: gyneco?.age + ' ' + this.translationPipe.transform("lblYears"),
			gynecoReasonListDeprecated: this.getGynecoReasonList(gyneco?.gynecoReasonListDeprecated),
			profession: gyneco?.profession,
			civilStatus: this.getElementFromList("id", gyneco.civilStatus?.id, CivilStatusEnum.getCivilStatusList(), "", "unique"),
			gestity: gyneco?.gestity,
			parity: gyneco?.parity,
			hormonalStatus: this.getElementFromList("idHormonalStatus", gyneco.hormonalStatus?.idHormonalStatus, this.dataSharedService.gynecoSettings.hormonalStatusList, "", "unique"),
			ageMenarche: gyneco?.ageMenarche + ' ' + this.translationPipe.transform("lblYears"),
			firstIntercourseDate: this.dateService.getStringUtcDate(gyneco?.firstIntercourseDate, "MM/YYYY"),
			mariageDate: this.dateService.getStringUtcDate(gyneco?.mariageDate, "MM/YYYY"),
			menopauseDate: this.dateService.getStringUtcDate(gyneco?.menopauseDate, "MM/YYYY"),
			dysmenorhea: this.getElementFromList("id", gyneco.dysmenorhea?.id, ValidationEnum.getValidationList(), "", "unique"),
			metrorrhagia: this.getElementFromList("id", gyneco.metrorrhagia?.id, ValidationEnum.getValidationList(), "", "unique") ,
            menstrualCycleRegularity: this.getElementFromList("id", gyneco.menstrualCycleRegularity?.id, MenstrualCycleRegularityEnum.getMenstrualCycleRegularityList(), "", "unique"),
			menstrualCycleLengthMin: gyneco?.menstrualCycleLengthMin + this.translationPipe.transform("lblDays"),
			menstrualCycleLengthMax: gyneco?.menstrualCycleLengthMax + this.translationPipe.transform("lblDays"),
			menstrualPeriodLengthMin: gyneco?.menstrualPeriodLengthMin + this.translationPipe.transform("lblDays"),
			menstrualPeriodLengthMax: gyneco?.menstrualPeriodLengthMax + this.translationPipe.transform("lblDays"),
			questioning: gyneco?.questioning
		}
		gynecoSection.dataList.push(data);
		this.dataExportList.push(gynecoSection);
	}
    
	initFertilityPatientListFormat(fertilityList: Fertility[]) {
		for (let i = 0; i < fertilityList.length; i++) {
			let fertility = fertilityList[i];
			this.initFertilityFormat(fertility);
		}
	}
	
	initFertilityFormat(fertility: Fertility) {
		let fertilityPatientSection = {
			sectionName: this.translationPipe.transform("lblPatient"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "age", value: this.translationPipe.transform("lblAge") },
				{ key: "mariageDate", value: this.translationPipe.transform("lblMariageDate") },
				{ key: "profession", value: this.translationPipe.transform("lblProfession") },
				{ key: "infertilityType", value: this.translationPipe.transform("lblInfertilityType") },
				{ key: "infertilityDuration", value: this.translationPipe.transform("lblInfertilityDuration") },
				{ key: "husbandRank", value: this.translationPipe.transform("lblHusbandRank") },
				{ key: "mariageRankPregnancy", value: this.translationPipe.transform("lblMariageRankPregnancy") },
				{ key: "tabacco", value: this.translationPipe.transform("lblTabacco") },
				{ key: "alcohol", value: this.translationPipe.transform("lblAlcohol") },
				{ key: "earlyMenopause", value: this.translationPipe.transform("lblEarlyMenopause") },
				{ key: "menstrualCycleRegularity", value: this.translationPipe.transform("lblMenstrualCycleRegularity") },
				{ key: "menstrualCycleLengthMin", value: this.translationPipe.transform("lblMenstrualCycleLengthMin") },
				{ key: "menstrualCycleLengthMax", value: this.translationPipe.transform("lblMenstrualCycleLengthMax") },
				{ key: "menstrualPeriodLengthMin", value: this.translationPipe.transform("lblMenstrualPeriodLengthMin") },
				{ key: "menstrualPeriodLengthMax", value: this.translationPipe.transform("lblMenstrualPeriodLengthMax") },
				{ key: "dysmenorhea", value: this.translationPipe.transform("lblDysmenorhea") },
				{ key: "metrorrhagia", value: this.translationPipe.transform("lblMetrorrhagia") },
				{ key: "civilStatus", value: this.translationPipe.transform("lblCivilStatus") },
			],
			viewStructure: [5, 5, 5, 3],
			dataList: [],
		}
		let dataPatient = {
			age: fertility?.age + this.translationPipe.transform("lblYears"),
			mariageDate: this.dateService.getStringUtcDate(fertility?.mariageDate, "MM/YYYY"),
			profession: fertility?.profession,
			infertilityType: this.getElementFromList("id", fertility.infertilityType?.id, InfertilityTypeEnum.getInfertilityTypeList(), "", "unique"),
			infertilityDuration: fertility?.infertilityDuration + this.translationPipe.transform("lblUnitMonth"),
			husbandRank: fertility?.husbandRank,
			mariageRankPregnancy: this.getElementFromList("id", fertility.mariageRankPregnancy?.id, ValidationEnum.getValidationList(), "", "unique"),
			tabacco: this.getElementFromList("id", fertility.tabaccoDeprecated?.id, ValidationEnum.getValidationList(), "", "unique"),
			alcohol: this.getElementFromList("id", fertility.alcoholDeprecated?.id, ValidationEnum.getValidationList(), "", "unique"),
			earlyMenopause: this.getElementFromList("id", fertility.earlyMenopause?.id, ValidationEnum.getValidationList(), "", "unique"),
			menstrualCycleRegularity: this.getElementFromList("id", fertility.menstrualCycleRegularity?.id, MenstrualCycleRegularityEnum.getMenstrualCycleRegularityList(), "", "unique"),
			menstrualCycleLengthMin: fertility?.menstrualCycleLengthMin + this.translationPipe.transform("lblDays"),
			menstrualCycleLengthMax: fertility?.menstrualCycleLengthMax + this.translationPipe.transform("lblDays"),
			menstrualPeriodLengthMin: fertility?.menstrualPeriodLengthMin + this.translationPipe.transform("lblDays"),
			menstrualPeriodLengthMax: fertility?.menstrualPeriodLengthMax + this.translationPipe.transform("lblDays"),
			dysmenorhea: this.getElementFromList("id", fertility.dysmenorhea?.id, ValidationEnum.getValidationList(), "", "unique"),
			metrorrhagia: this.getElementFromList("id", fertility.metrorrhagia?.id, ValidationEnum.getValidationList(), "", "unique"),
			civilStatus: this.getElementFromList("id", fertility.civilStatus?.id, CivilStatusEnum.getCivilStatusList(), "", "unique")
		}
		fertilityPatientSection.dataList.push(dataPatient);
		this.dataExportList.push(fertilityPatientSection);
		let fertilityHusbandSection = {
			sectionName: this.translationPipe.transform("lblHusband"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "ageHusband", value: this.translationPipe.transform("lblAge") },
				{ key: "husbandFirstname", value: this.translationPipe.transform("lblFirstname") },
				{ key: "husbandLastname", value: this.translationPipe.transform("lblLastname") },
				{ key: "professionHusband", value: this.translationPipe.transform("lblProfession") },
				{ key: "wifeRankHusband", value: this.translationPipe.transform("lblWifeRankHusband") },
				{ key: "wifeRankPregnancyHusband", value: this.translationPipe.transform("lblWifeRankPregnancyHusband") },
				{ key: "tobaccoHusband", value: this.translationPipe.transform("lblTobaccoHusband") },
				{ key: "alcoholHusband", value: this.translationPipe.transform("lblAlcoholHusband") },
				{ key: "heatHusband", value: this.translationPipe.transform("lblHeatHusband")},
				{ key: "heatHusbandComment", value: this.translationPipe.transform("lblHeatHusbandComment") },
				{ key: "toxicHusband", value: this.translationPipe.transform("lblToxicHusband") },
				{ key: "toxicHusbandComment", value:this.translationPipe.transform("lblToxicHusbandComment") },
				{ key: "diabetesHusband", value: this.translationPipe.transform("lblDiabetesHusband") },
				{ key: "hbpHusband", value: this.translationPipe.transform("lblHbpHusband") },
				{ key: "inguinalTesticularSurgeryHusband", value: this.translationPipe.transform("lblInguinalTesticularSurgeryHusband") },
				{ key: "neurologicalPathologyHusband", value: this.translationPipe.transform("lblNeurologicalPathologyHusband") },
				{ key: "chimiotherapyRadiotherapyHusband", value: this.translationPipe.transform("lblChimiotherapyRadiotherapyHusband") },
				{ key: "othersPatholgyHusband", value: this.translationPipe.transform("lblOthersPatholgyHusband") }
			],
			viewStructure: [4, 2, 6,6],
			dataList: [],
		}
		let dataHusband = {
			ageHusband: fertility?.ageHusband + ' ' + this.translationPipe.transform("lblYears"),
			husbandFirstname: fertility?.husbandFirstname,
			husbandLastname: fertility?.husbandLastname,
			professionHusband: fertility?.professionHusband,
			wifeRankHusband: fertility?.wifeRankHusband,
			wifeRankPregnancyHusband: this.getElementFromList("id", fertility.wifeRankPregnancyHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			tobaccoHusband: this.getElementFromList("id", fertility.tobaccoHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			alcoholHusband: this.getElementFromList("id", fertility.alcoholHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			heatHusband: this.getElementFromList("id", fertility.heatHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			heatHusbandComment: fertility?.heatHusbandComment?.replace(/\n/g, '<br>'),
			toxicHusband: this.getElementFromList("id", fertility.toxicHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			toxicHusbandComment: fertility?.toxicHusbandComment?.replace(/\n/g, '<br>'), 
			diabetesHusband: this.getElementFromList("id", fertility.diabetesHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			hbpHusband: this.getElementFromList("id", fertility.hbpHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			inguinalTesticularSurgeryHusband: this.getElementFromList("id", fertility.inguinalTesticularSurgeryHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			neurologicalPathologyHusband: this.getElementFromList("id", fertility.neurologicalPathologyHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
			chimiotherapyRadiotherapyHusband: this.getElementFromList("id", fertility.chimiotherapyRadiotherapyHusband?.id, ValidationEnum.getValidationList(), "", "unique"),
		    othersPatholgyHusband: fertility?.othersPatholgyHusband
		}
		fertilityHusbandSection.dataList.push(dataHusband);
		this.dataExportList.push(fertilityHusbandSection);
		if (fertility?.fertilityPreviousStimulationList.length > 0) {
			this.onFormatFertilityPreviousStimulationList(fertility.fertilityPreviousStimulationList)
		}
		if (fertility?.comment !== undefined && fertility?.comment !== null) {
			let commentSection = {
				sectionName: this.translationPipe.transform("lblComment"),
				style: { fontStyle: 'bold' },
				dataList: [],
			}
			let dataa = {
				"comment": fertility.comment?.replace(/\n/g, '<br>')
			}
			commentSection.dataList.push(dataa);
			this.dataExportList.push(commentSection);
		}
	}
	
	onFormatFertilityPreviousStimulationList(fertilityPreviousStimulationList: any) {
		let fertilityPreviousStimulation = {
			sectionName: this.translationPipe.transform("lblFertilityPreviousStimulationList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "otherDoctor", value: this.translationPipe.transform("lblOtherDoctor") },
				{ key: "fertilityPreviousStimulationType", value: this.translationPipe.transform("lblFertilityPreviousStimulationType") },
				{ key: "result", value: this.translationPipe.transform("lblResult") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") }
			],
			dataList: [],
			displayHeader: true
		}
		for (let i = 0; i < fertilityPreviousStimulationList.length; i++) {
			let element = fertilityPreviousStimulationList[i];
			let data = {
				date: this.dateService.getStringUtcDate(element.date, "MM/YYYY"),
				otherDoctor: this.getElementFromList("idOtherDoctor", element.otherDoctor?.idOtherDoctor, this.dataSharedService.consultationSettings.otherDoctorList, "", "unique"),
				fertilityPreviousStimulationType: this.getElementFromList("idFertilityPreviousStimulationType", element.fertilityPreviousStimulationType?.idFertilityPreviousStimulationType, this.dataSharedService.fertilitySettings.fertilityPreviousStimulationTypeList, "", "unique"),
				result: this.getElementFromList("id", element.result?.id, ResultEnum.getResultList(), "", "unique"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			fertilityPreviousStimulation.dataList.push(data);
		}
			this.dataExportList.push(fertilityPreviousStimulation);
	}
	
    initPregnancyPatientListFormat(pregnancyList: Pregnancy[]){
	 for(let i =0; i < pregnancyList.length ; i++ ){
		let pregnancy = pregnancyList[i];
		this.initPregnancyFormat(pregnancy);
	 }	
	}
	
	initPatientFormat(patient: Patient) {
		let patientSection = this.translationPipe.transform(patient.firstname + ' ' + patient.lastname) + " " + this.dateUtcTimezonePipe.transform(patient.birthday);
		this.initSimpleText(patientSection);
	}
    
	initPregnancyFormat(pregnancy: Pregnancy) {
		let pregnancySection = {
			sectionName: this.translationPipe.transform("lblPatient"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "age", value: this.translationPipe.transform("lblAge") },
				{ key: "profession", value: this.translationPipe.transform("lblProfession") },
				{ key: "mariageDate", value: this.translationPipe.transform("lblMariageDate") },
				{ key: "conceptionType", value: this.translationPipe.transform("lblConceptionType") },
				{ key: "lpd", value: this.translationPipe.transform("lblLpd") },
				{ key: "datePregnancy", value: this.translationPipe.transform("lblDatePregnancy") },
				{ key: "lpdVerified", value: this.translationPipe.transform("lblLpdVerified") },
				{ key: "foetusNumbre", value: this.translationPipe.transform("lblFoetusNumbre") },
				{ key: "gestity", value: this.translationPipe.transform("lblGestity") },
				{ key: "parity", value: this.translationPipe.transform("lblParity") },
				{ key: "livingChild", value: this.translationPipe.transform("lblLivingChild") },
				{ key: "civilStatus", value: this.translationPipe.transform("lblCivilStatus") },
				{ key: "smoker", value: this.translationPipe.transform("lblSmoker") },
				{ key: "alcoholic", value: this.translationPipe.transform("lblAlcoholic") },
				{ key: "allergic", value: this.translationPipe.transform("lblAllergic") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [4, 4, 4, 3, 1],
			dataList: [],
		}
		let data = {
			age: pregnancy?.age,
			profession: pregnancy?.profession,
			mariageDate: this.dateUtcTimezonePipe.transform(pregnancy?.mariageDate),
			conceptionType: this.getElementFromList("id", pregnancy.conceptionType?.id, ConceptionTypeEnum.getConceptionTypeList(), "", "unique"),
			lpd: this.dateUtcTimezonePipe.transform(pregnancy?.lpd),
			datePregnancy: this.dateUtcTimezonePipe.transform(pregnancy?.datePregnancy),
			lpdVerified: this.getElementFromList("id", pregnancy.lpdVerified?.id, ValidationEnum.getValidationList(), "", "unique"),
			foetusNumbre: pregnancy?.foetusNumbre,
			gestity: pregnancy?.gestity,
			parity: pregnancy?.parity,
			livingChild: pregnancy?.livingChild,
			civilStatus: this.getElementFromList("id", pregnancy.civilStatus?.id, CivilStatusEnum.getCivilStatusList(), "", "unique"),
			smoker: this.getElementFromList("id", pregnancy.tabaccoDeprecated?.id, ValidationEnum.getValidationList(), "", "unique"),
			alcoholic: this.getElementFromList("id", pregnancy.alcoholDeprecated?.id, ValidationEnum.getValidationList(), "", "unique"),
			allergic: this.getElementFromList("id", pregnancy.allergicDeprecated?.id, ValidationEnum.getValidationList(), "", "unique"),
			comment: pregnancy?.comment?.replace(/\n/g, '<br>')
		}
		pregnancySection.dataList.push(data);
		this.dataExportList.push(pregnancySection);
	}

	onFormatPregnancySerumMarkersList(consultationPregnancySerumMarkersList: any) {
		for (let i = 0; i < consultationPregnancySerumMarkersList.length; i++) {
			let element = consultationPregnancySerumMarkersList[i];
			this.onFormatPregnancySerumMarkers(element);
		}
	}

	onFormatPregnancySerumMarkers(element: any) {
		let pregnancySerumMarkersSection = {
			sectionName: this.translationPipe.transform("lblPregnancySerumMarkersList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "weeks", value: this.translationPipe.transform("lblWeeks") },
				{ key: "days", value: this.translationPipe.transform("lblDays") },
				{ key: "firstTOrSecondT", value: this.translationPipe.transform("lblFirstTOrSecondT") },
				{ key: "momPappa", value: this.translationPipe.transform("lblMomPappa") },
				{ key: "momFreeBhcg", value: this.translationPipe.transform("lblMomFreeBhcg") },
				{ key: "momPlGf", value: this.translationPipe.transform("lblMomPlGf") },
				{ key: "momAfp", value: this.translationPipe.transform("lblMomAfp") },
				{ key: "momBhcg", value: this.translationPipe.transform("lblMomBhcg") },
				{ key: "momEstriol", value: this.translationPipe.transform("lblMomEstriol") },
				{ key: "trisomyTwentyOne", value: this.translationPipe.transform("lblTrisomyTwentyOne") },
				{ key: "trisomyThirteen", value: this.translationPipe.transform("lblTrisomyThirteen") },
				{ key: "trisomyEighteen", value: this.translationPipe.transform("lblTrisomyEighteen") },
				{ key: "neuralTubeDefect", value: this.translationPipe.transform("lblNeuralTubeDefect") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [4, 3, 3, 4, 1],
			dataList: [],
		}
		pregnancySerumMarkersSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(element?.date),
			weeks: element?.weeks,
			days: element?.days,
			firstTOrSecondT: this.getElementFromList("id", element.firstTOrSecondT?.id, TrimesterEnum.getTrimesterList(), "", "unique"),
			momPappa: element?.momPappa,
			momFreeBhcg: element?.momFreeBhcg,
			momPlGf: element?.momPlGf,
			momAfp: element?.momAfp,
			momBhcg: element?.momBhcg,
			momEstriol: element?.momEstriol,
			trisomyTwentyOne: element?.trisomyTwentyOne,
			trisomyThirteen: element?.trisomyThirteen,
			trisomyEighteen: element?.trisomyEighteen,
			neuralTubeDefect: element?.neuralTubeDefect,
			comment: element?.comment?.replace(/\n/g, '<br>')
		}
		pregnancySerumMarkersSection.dataList?.push(data)
		this.dataExportList.push(pregnancySerumMarkersSection)
	}

	onFormatPregnancyUltrasoundEmptyList(consultationPregnancyUltrasoundEmptyList: any) {
		for (let i = 0; i < consultationPregnancyUltrasoundEmptyList.length; i++) {
			let element = consultationPregnancyUltrasoundEmptyList[i];
			this.onFormatPregnancyUltrasoundEmpty(element);
		}
	}

	onFormatPregnancyUltrasoundEmpty(element: any) {
		let date: any;
		if (element.consultationPregnancy && element.consultationPregnancy !== null) {
			date = element.consultationPregnancy.date !== undefined && element.consultationPregnancy.date !== null ? element.consultationPregnancy.date : null;
		}
		let pregnancyUltrasoundEmptySection = {
			sectionName: date !== null && this.generateDefaultData === true ? this.translationPipe.transform("lblPregnancyUltrasoundEmpty") + " (" + this.dateUtcTimezonePipe.transform(date) + ")" : this.translationPipe.transform("lblPregnancyUltrasoundEmpty"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "ultrasound", value: this.translationPipe.transform("lblUltrasound") },
				{ key: "ultrasoundRoute", value: this.translationPipe.transform("lblUltrasoundRoute") },
				{ key: "comment", value: undefined }
				],
			viewStructure: [2,1],
			dataList: [],
		}
		pregnancyUltrasoundEmptySection.dataList = [];
		if (this.generateDefaultData === false) {
			pregnancyUltrasoundEmptySection['generateDefaultData'] = this.generateDefaultData;
		}
		let data = {
			ultrasound: this.getUltrasound(element?.ultrasound),
			ultrasoundRoute: this.getElementFromList("id", element?.ultrasoundRoute?.id, UtrasoundRouteEnum.getUtrasoundRouteList(), "", "unique"),
			comment: element?.comment?.replace(/\n/g, '<br>')
		}
		pregnancyUltrasoundEmptySection.dataList?.push(data)
		if (data.comment !== undefined || data.ultrasound !== undefined || data.ultrasoundRoute !== undefined) {
			this.dataExportList.push(pregnancyUltrasoundEmptySection);
		}
	}

	onFormatPregnancyUltrasoundThirdTData(consultationPregnancyUltrasoundThirdTList: any) {
		for (let i = 0; i < consultationPregnancyUltrasoundThirdTList.length; i++) {
			let element = consultationPregnancyUltrasoundThirdTList[i];
			this.onFormatThirdFirstPart(element);
			this.onFormatDopplers(element);
            this.onFormatThirdSecondPart(element);
			if (element?.pregnancyUltrasoundThirdTBabyList.length > 0) {
				this.onFormatThirdBabyList(element?.pregnancyUltrasoundThirdTBabyList)
			}
			this.onFormatPregnancyConclusionModel(element);
		}
	}

	onFormatThirdFirstPart(element: any) {
		let datePregnancy = this.getPregnancyUltrasoundDate(element);
		let pregnancyUltrasoundThirdSection = {
			sectionName: datePregnancy !== null && this.generateDefaultData === true ? this.translationPipe.transform("lblPregnancyUltrasoundThirdTList") + " (" + this.dateUtcTimezonePipe.transform(datePregnancy) + ")" :this.translationPipe.transform("lblPregnancyUltrasoundThirdTList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "otherDoctor", value: this.translationPipe.transform("lblOtherDoctor") },
				{ key: "dateOtherDoctor", value: this.translationPipe.transform("lblDateOtherDoctor") },
				{ key: "recordSummury", value: this.translationPipe.transform("lblRecordSummury") },
				{ key: "commentSummary", value: undefined },
				{ key: "ultrasound", value: this.translationPipe.transform("lblUltrasound") },
				{ key: "ultrasoundRoute", value: this.translationPipe.transform("lblUltrasoundRoute") },
				{ key: "examCondition", value: this.translationPipe.transform("lblExamCondition") },
				{ key: "examConditionComment", value: this.translationPipe.transform("lblComment") },
				{ key: "fetusNumber", value: this.translationPipe.transform("lblFetusNumber") }
			],
			viewStructure: [2,1,1,5],
			dataList: [],
		}
		if( this.generateDefaultData === false ){
		pregnancyUltrasoundThirdSection['generateDefaultData'] = this.generateDefaultData;	
		}
		pregnancyUltrasoundThirdSection.dataList = [];
		let data = {
			dateOtherDoctor: this.dateUtcTimezonePipe.transform(element?.dateOtherDoctor),
			otherDoctor: this.getElementFromList("idOtherDoctor", element.otherDoctor?.idOtherDoctor, this.dataSharedService.consultationSettings.otherDoctorList, "", "unique"),
            recordSummury: element?.commentSummary != undefined ? this.translationPipe.transform("lblRecordSummury") : undefined,
            commentSummary: element?.commentSummary,
			ultrasound: this.getUltrasound(element?.ultrasound),
			ultrasoundRoute: this.getElementFromList("id", element?.ultrasoundRoute?.id, UtrasoundRouteEnum.getUtrasoundRouteList(), "", "unique"),
			examCondition: this.getElementFromList("id", element.examCondition?.id, ExamConditionEnum.getExamConditionList(), "", "unique"),
            examConditionComment: element?.examConditionComment,
            fetusNumber: element?.fetusNumber,
		}
		pregnancyUltrasoundThirdSection.dataList?.push(data)
		this.dataExportList.push(pregnancyUltrasoundThirdSection);
	}
	
	onFormatThirdSecondPart(element: any) {
		let pregnancyUltrasoundThirdSection = {
			sectionName: "",
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "uterusAdnexa", value: this.translationPipe.transform("lblUterusAdnexa") },
				{ key: "cervixLenght", value: this.translationPipe.transform("lblCervixLenght") },
				{ key: "cervixApparence", value: this.translationPipe.transform("lblCervixApparence") },
			    { key: "adnexaLeft", value: this.translationPipe.transform("lblAdnexaLeft") },
				{ key: "adnexaRight", value: this.translationPipe.transform("lblAdnexaRight") },
				{ key: "uterus", value: this.translationPipe.transform("lblUterus") },
			],
			viewStructure: [1, 5],
			dataList: [],
		}
		pregnancyUltrasoundThirdSection.dataList = [];
		let data = {
			uterusAdnexa:
				element?.cervixLenght || element?.cervixApparence || element?.adnexaLeft || element?.adnexaRight || element?.uterus
					? this.translationPipe.transform("lblUterusAdnexa")
					: undefined,
			cervixLenght: element?.cervixLenght + ' ' + this.translationPipe.transform("lblUnitMm"),
			cervixApparence: element?.cervixApparence,
			adnexaLeft: element?.adnexaLeft, 
			adnexaRight: element?.adnexaRight,
			uterus: element?.uterus,
		}
		pregnancyUltrasoundThirdSection.dataList?.push(data)
		this.dataExportList.push(pregnancyUltrasoundThirdSection);
	}

	onFormatThirdBabyList(dataList: any) {
		for (let i = 0; i < dataList.length; i++) {
			this.onFormatPregnancyUltrasoundThirdTBabyFirstPart(dataList[i]);
			this.onFormatFetalBiometries(dataList[i]);
            this.onFormatDopplersFoetaux(dataList[i]);
            this.onFormatPregnancyUltrasoundThirdTBabySecondPart(dataList[i]);
		}
	}

	onFormatPregnancyUltrasoundThirdTBabyFirstPart(value: any) {
		let pregnancyUltrasoundThirdBabySection = {
			sectionName: this.translationPipe.transform("lblPregnancyUltrasoundSecondTBabyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "presentation", value: this.translationPipe.transform("lblPresentation") },
				{ key: "mobility", value: this.translationPipe.transform("lblMobility") },
				{ key: "heart", value: this.translationPipe.transform("lblHeart") },
				{ key: "heartRate", value: this.translationPipe.transform("lblHeartRate") },
				{ key: "amnioticFluid", value: this.translationPipe.transform("lblAmnioticFluid") },
				{ key: "amnioticIndex", value: this.translationPipe.transform("lblAmnioticIndex") },
				{ key: "bigCitern", value: this.translationPipe.transform("lblBigCitern") },
				{ key: "placenta", value: this.translationPipe.transform("lblPlacenta") },
				{ key: "abruption", value: this.translationPipe.transform("lblAbruption") },
				{ key: "placentaPrevia", value: this.translationPipe.transform("lblPlacentaPrevia") },
				{ key: "placentaPreviaType", value: this.translationPipe.transform("lblPlacentaPreviaType") },
				{ key: "distancePlacentaCervix", value: this.translationPipe.transform("lblDistancePlacentaCervix") },
				{ key: "accretaSign", value: this.translationPipe.transform("lblAccretaSign") },
			],
			viewStructure: [4, 3, 6],
			dataList: [],
			subtitle: true
		}
		pregnancyUltrasoundThirdBabySection.dataList = [];
		let data = {
			presentation: this.getElementFromList("id", value.presentation?.id, PresentationEnum.getPresentationList(), "", "unique"),
			mobility: this.getElementFromList("id", value.mobility?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			heart: this.getElementFromList("id", value.heart?.id, SignEnum.getSignList(), "", "unique"),
			heartRate: value?.heartRate + ' ' + this.translationPipe.transform("lblBpm"),
			amnioticFluid: this.getElementFromList("id", value.amnioticFluid?.id, AmnioticFluidEnum.getAmnioticFluidList(), "", "unique"),
			amnioticIndex: value?.amnioticIndex + ' ' + this.translationPipe.transform("lblUnitMm"),
			bigCitern: value?.bigCitern + ' ' + this.translationPipe.transform("lblUnitMm"),
			placenta: this.getElementFromList("id", value.placenta?.id, PlacentaEnum.getPlacentaList(), "", "unique"),
			abruption: this.getElementFromList("id", value.abruption?.id, ValidationEnum.getValidationList(), "", "unique"),
			placentaPrevia: this.getElementFromList("id", value.placentaPrevia?.id, ValidationEnum.getValidationList(), "", "unique"),
		    placentaPreviaType: value?.placentaPreviaType,
			distancePlacentaCervix: value?.distancePlacentaCervix + ' ' + this.translationPipe.transform("lblUnitMm"),
			accretaSign: this.getElementFromList("id", value.accretaSign?.id, ValidationEnum.getValidationList(), "", "unique"),
		}
		pregnancyUltrasoundThirdBabySection.dataList?.push(data);
		this.dataExportList.push(pregnancyUltrasoundThirdBabySection);
	}
	
	onFormatPregnancyUltrasoundThirdTBabySecondPart(value: any) {
		let pregnancyUltrasoundThirdBabySection = {
			sectionName: "",
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "sex", value: this.translationPipe.transform("lblSex") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [2],
			dataList: [],
			subtitle: true
		}
		pregnancyUltrasoundThirdBabySection.dataList = [];
		let data = {
			sex: this.getElementFromList("id", value.sex?.id, SexEnum.getSexList(), "", "unique"),
			comment: value?.comment?.replace(/\n/g, '<br>')
		}
		pregnancyUltrasoundThirdBabySection.dataList?.push(data);
		this.dataExportList.push(pregnancyUltrasoundThirdBabySection);
	}

	onFormatPregnancyUltrasoundSecondTList(consultationPregnancyUltrasoundSecondTList: any) {
		for (let i = 0; i < consultationPregnancyUltrasoundSecondTList.length; i++) {
			let element = consultationPregnancyUltrasoundSecondTList[i];
			this.onFormatFirstPart(element);
			this.onFormatDopplers(element);
            this.onFormatSecondPart(element);
			if (element?.pregnancyUltrasoundSecondTBabyList.length > 0) {
				this.onFormatSecondBabyList(element?.pregnancyUltrasoundSecondTBabyList);
			}
			this.onFormatPregnancyConclusionModel(element);
		}
	}
	
	onFormatFirstPart(element: any) {
		let datePregnancy = this.getPregnancyUltrasoundDate(element);
		let pregnancyUltrasoundSecondSection = {
			sectionName: datePregnancy !== null && this.generateDefaultData === true ? this.translationPipe.transform("lblPregnancyUltrasoundSecondTList") + " (" + this.dateUtcTimezonePipe.transform(datePregnancy) + ")" :this.translationPipe.transform("lblPregnancyUltrasoundSecondTList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "otherDoctor", value: this.translationPipe.transform("lblOtherDoctor") },
				{ key: "dateOtherDoctor", value: this.translationPipe.transform("lblDateOtherDoctor") },
				{ key: "recordSummury", value: this.translationPipe.transform("lblRecordSummury") },
				{ key: "commentSummary", value: undefined },
				{ key: "ultrasound", value: this.translationPipe.transform("lblUltrasound") },
				{ key: "ultrasoundRoute", value: this.translationPipe.transform("lblUltrasoundRoute") },
			    { key: "examCondition", value: this.translationPipe.transform("lblExamCondition") },
				{ key: "examConditionComment", value: this.translationPipe.transform("lblComment") },
				{ key: "fetusNumber", value: this.translationPipe.transform("lblFetusNumber") }
			],
			viewStructure: [2,1,1,5],
			dataList: [],
		}
		pregnancyUltrasoundSecondSection.dataList = [];
		if (this.generateDefaultData === false) {
			pregnancyUltrasoundSecondSection['generateDefaultData'] = this.generateDefaultData;
		}
		let data = {
			dateOtherDoctor: this.dateUtcTimezonePipe.transform(element?.dateOtherDoctor),
			otherDoctor: this.getElementFromList("idOtherDoctor", element.otherDoctor?.idOtherDoctor, this.dataSharedService.consultationSettings.otherDoctorList, "", "unique"),
            recordSummury: element?.commentSummary != undefined ? this.translationPipe.transform("lblRecordSummury") : undefined,
            commentSummary: element?.commentSummary,
            ultrasound: this.getUltrasound(element?.ultrasound),
			ultrasoundRoute: this.getElementFromList("id", element?.ultrasoundRoute?.id, UtrasoundRouteEnum.getUtrasoundRouteList(), "", "unique"),
			examCondition: this.getElementFromList("id", element.examCondition?.id, ExamConditionEnum.getExamConditionList(), "", "unique"),
            examConditionComment: element?.examConditionComment,
            fetusNumber: element?.fetusNumber,
		}
		pregnancyUltrasoundSecondSection.dataList?.push(data)
		this.dataExportList.push(pregnancyUltrasoundSecondSection);
	}
	
	onFormatSecondPart(element: any) {
		let datePregnancy = this.getPregnancyUltrasoundDate(element);
		let pregnancyUltrasoundSecondSection = {
			sectionName:"",
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "uterusAdnexa", value: this.translationPipe.transform("lblUterusAdnexa") },
				{ key: "cervixLenght", value: this.translationPipe.transform("lblCervixLenght") },
				{ key: "cervixApparence", value: this.translationPipe.transform("lblCervixApparence") },
			    { key: "adnexaLeft", value: this.translationPipe.transform("lblAdnexaLeft") },
				{ key: "adnexaRight", value: this.translationPipe.transform("lblAdnexaRight") },
				{ key: "uterus", value: this.translationPipe.transform("lblUterus") },
			],
			viewStructure: [1, 5],
			dataList: [],
		}
		pregnancyUltrasoundSecondSection.dataList = [];
		let data = {
			uterusAdnexa: element?.cervixLenght || element?.cervixApparence || element?.adnexaLeft || element?.adnexaRight || element?.uterus 
            ? this.translationPipe.transform("lblUterusAdnexa") : undefined,
			cervixLenght: element?.cervixLenght,
			cervixApparence: element?.cervixApparence,
			adnexaLeft: element?.adnexaLeft, 
			adnexaRight: element?.adnexaRight,
			uterus: element?.uterus,
		}
		pregnancyUltrasoundSecondSection.dataList?.push(data)
		this.dataExportList.push(pregnancyUltrasoundSecondSection);
	}


	onFormatSecondBabyList(dataList: any) {
		for (let i = 0; i < dataList.length; i++) {
			this.onFormatPregnancyUltrasoundSecondTBabyFirstPart(dataList[i]);
            this.onFormatFetalBiometries(dataList[i]);
            this.onFormatMembersBiometries(dataList[i]);
            this.onFormatDopplersFoetaux(dataList[i]);
            this.onFormatPregnancyUltrasoundSecondTBabySecondPart(dataList[i]);
		}
	}
	
	onFormatPregnancyUltrasoundSecondTBabyFirstPart(value: any) {
		let pregnancyUltrasoundSecondBabySection = {
			sectionName: this.translationPipe.transform("lblPregnancyUltrasoundSecondTBabyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "presentation", value: this.translationPipe.transform("lblPresentation") },
				{ key: "mobility", value: this.translationPipe.transform("lblMobility") },
				{ key: "heart", value: this.translationPipe.transform("lblHeart") },
				{ key: "heartRate", value: this.translationPipe.transform("lblHeartRate") },
				{ key: "amnioticFluid", value: this.translationPipe.transform("lblAmnioticFluid") },
				{ key: "amnioticIndex", value: this.translationPipe.transform("lblAmnioticIndex") },
				{ key: "bigCitern", value: this.translationPipe.transform("lblBigCitern") },
				{ key: "umbilicalCord", value: this.translationPipe.transform("lblUmbilicalCord") },
				{ key: "placenta", value: this.translationPipe.transform("lblPlacenta") },
				{ key: "abruption", value: this.translationPipe.transform("lblAbruption") },
				{ key: "placentaPrevia", value: this.translationPipe.transform("lblPlacentaPrevia") },
				{ key: "placentaPreviaType", value: this.translationPipe.transform("lblPlacentaPreviaType") },
				{ key: "distancePlacentaCervix", value: this.translationPipe.transform("lblDistancePlacentaCervix") },
				{ key: "accretaSign", value: this.translationPipe.transform("lblAccretaSign") },
			],
			viewStructure: [4, 4, 6],
			dataList: [],
			subtitle: true
		}
		pregnancyUltrasoundSecondBabySection.dataList = [];
		let data = {
			presentation: this.getElementFromList("id", value.presentation?.id, PresentationEnum.getPresentationList(), "", "unique"),
			mobility: this.getElementFromList("id", value.mobility?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			heart: this.getElementFromList("id", value.heart?.id, SignEnum.getSignList(), "", "unique"),
			heartRate: value?.heartRate + ' ' + this.translationPipe.transform("lblBpm"),
			amnioticFluid: this.getElementFromList("id", value.amnioticFluid?.id, AmnioticFluidEnum.getAmnioticFluidList(), "", "unique"),
			amnioticIndex: value?.amnioticIndex + ' ' + this.translationPipe.transform("lblUnitMm"),
			bigCitern: value?.bigCitern + ' ' + this.translationPipe.transform("lblUnitMm"),
			umbilicalCord: this.getElementFromList("id", value.umbilicalCord?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			placenta: this.getElementFromList("id", value.placenta?.id, PlacentaEnum.getPlacentaList(), "", "unique"),
			abruption: this.getElementFromList("id", value.abruption?.id, ValidationEnum.getValidationList(), "", "unique"),
			placentaPrevia: this.getElementFromList("id", value.placentaPrevia?.id, ValidationEnum.getValidationList(), "", "unique"),
			placentaPreviaType: value?.placentaPreviaType,
			distancePlacentaCervix: value?.distancePlacentaCervix + ' ' + this.translationPipe.transform("lblUnitMm"),
			accretaSign: this.getElementFromList("id", value.accretaSign?.id, ValidationEnum.getValidationList(), "", "unique")+ "<br><br>"
		}
		pregnancyUltrasoundSecondBabySection.dataList?.push(data)
		this.dataExportList.push(pregnancyUltrasoundSecondBabySection);
	}
	
	onFormatPregnancyUltrasoundSecondTBabySecondPart(value: any){
		let pregnancyUltrasoundSecondBabySecondSection = {
			sectionName: "",
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "skull", value: this.translationPipe.transform("lblSkull") },
				{ key: "brainParenchyma", value: this.translationPipe.transform("lblBrainParenchyma") },
				{ key: "lateralVentricul", value: this.translationPipe.transform("lblLateralVentricul") },
				{ key: "lateralVentriculMesure", value: this.translationPipe.transform("lblLateralVentriculMesure") },
				{ key: "thalami", value: this.translationPipe.transform("lblThalami") },
				{ key: "middleLine", value: this.translationPipe.transform("lblMiddleLine") },
				{ key: "septumPellucidum", value: this.translationPipe.transform("lblSeptumPellucidum") },
				{ key: "corpusCallosum", value: this.translationPipe.transform("lblCorpusCallosum") },
				{ key: "cerebellum", value: this.translationPipe.transform("lblCerebellum") },
				{ key: "cerebellumMesure", value: this.translationPipe.transform("lblCerebellumMesure") },
				{ key: "posteriorPit", value: this.translationPipe.transform("lblPosteriorPit") },
				{ key: "upperLip", value: this.translationPipe.transform("lblUpperLip") },
				{ key: "profile", value: this.translationPipe.transform("lblProfile") },
				{ key: "nasalBone", value: this.translationPipe.transform("lblNasalBone") },
				{ key: "nasalBoneMesure", value: this.translationPipe.transform("lblNasalBoneMesure") },
				{ key: "lung", value: this.translationPipe.transform("lblLung") },
				{ key: "heartAxis", value: this.translationPipe.transform("lblHeartAxis") },
				{ key: "fourCavity", value: this.translationPipe.transform("lblFourCavity") },
				{ key: "largeVessels", value: this.translationPipe.transform("lblLargeVessels") },
				{ key: "stomach", value: this.translationPipe.transform("lblStomach") },
				{ key: "bladder", value: this.translationPipe.transform("lblBladder") },
				{ key: "abdomen", value: this.translationPipe.transform("lblAbdomen") },
				{ key: "kidneys", value: this.translationPipe.transform("lblKidneys") },
				{ key: "rigntKidneyMesure", value: this.translationPipe.transform("lblRigntKidneyMesure") },
				{ key: "leftKidneyMesure", value: this.translationPipe.transform("lblLeftKidneyMesure") },
				{ key: "spine", value: this.translationPipe.transform("lblSpine") },
				{ key: "fourLimbs", value: this.translationPipe.transform("lblFourLimbs") },
				{ key: "threeSegments", value: this.translationPipe.transform("lblThreeSegments") },
				{ key: "sex", value: this.translationPipe.transform("lblSex") },
				{ key: "morphology", value: this.translationPipe.transform("lblMorphology") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [5,6,4,4,6,3,3],
			dataList: [],
			subtitle: true
		}
		pregnancyUltrasoundSecondBabySecondSection.dataList = [];
		let data = {
			skull: this.getElementFromList("id", value.skull?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			brainParenchyma: this.getElementFromList("id", value.brainParenchyma?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			lateralVentricul: this.getElementFromList("id", value.lateralVentricul?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			lateralVentriculMesure: value?.lateralVentriculMesure + ' ' + this.translationPipe.transform("lblUnitMm"),
			thalami: this.getElementFromList("id", value.thalami?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			middleLine: this.getElementFromList("id", value.middleLine?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			septumPellucidum: this.getElementFromList("id", value.septumPellucidum?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			corpusCallosum: this.getElementFromList("id", value.corpusCallosum?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			cerebellum: this.getElementFromList("id", value.cerebellum?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			cerebellumMesure: value?.cerebellumMesure + ' ' + this.translationPipe.transform("lblUnitMm"),
			posteriorPit: this.getElementFromList("id", value.posteriorPit?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			upperLip: this.getElementFromList("id", value.upperLip?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			profile: this.getElementFromList("id", value.profile?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			nasalBone: this.getElementFromList("id", value.nasalBone?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			nasalBoneMesure: value?.nasalBoneMesure + ' ' + this.translationPipe.transform("lblUnitMm"),
			lung: this.getElementFromList("id", value.lung?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			heartAxis: this.getElementFromList("id", value.heartAxis?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			fourCavity: this.getElementFromList("id", value.fourCavity?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			largeVessels: this.getElementFromList("id", value.largeVessels?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			stomach: this.getElementFromList("id", value.stomach?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			bladder: this.getElementFromList("id", value.bladder?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			abdomen: this.getElementFromList("id", value.abdomen?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			kidneys: this.getElementFromList("id", value.kidneys?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			rigntKidneyMesure: value?.rigntKidneyMesure + ' ' + this.translationPipe.transform("lblUnitMm"),
			leftKidneyMesure: value?.leftKidneyMesure + ' ' + this.translationPipe.transform("lblUnitMm"),
			spine: this.getElementFromList("id", value.spine?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			fourLimbs: this.getElementFromList("id", value.fourLimbs?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			threeSegments: this.getElementFromList("id", value.threeSegments?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			sex: this.getElementFromList("id", value.sex?.id, SexEnum.getSexList(), "", "unique"),
			morphology: this.getElementFromList("id", value.morphology?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			comment: value?.comment?.replace(/\n/g, '<br>')
		}
		pregnancyUltrasoundSecondBabySecondSection.dataList?.push(data)
		this.dataExportList.push(pregnancyUltrasoundSecondBabySecondSection);
	}
	

	onFormatPregnancyUltrasoundFirstTList(consultationPregnancyUltrasoundFirstTList: any) {
		for (let i = 0; i < consultationPregnancyUltrasoundFirstTList.length; i++) {
			let element = consultationPregnancyUltrasoundFirstTList[i];
			this.onFormatPregnancyUltrasoundFirstTFirstPart(element);
			this.onFormatDopplers(element);
            this.onFormatPregnancyUltrasoundFirstTSecondPart(element);
			if (element?.pregnancyUltrasoundFirstTBabyList.length !== 0) {
				this.onFormatFirstBabyData(element?.pregnancyUltrasoundFirstTBabyList)
			}
			this.onFormatPregnancyConclusionModel(element);
		}
	}
	
	onFormatPregnancyConclusionModel(pregnancyUltrasound: any) {
		let pregnancyUltrasoundConclusionModelSection = {
			sectionName: pregnancyUltrasound?.commentConclusion 
               ? this.translationPipe.transform("lblConclusionAndComment")+ ":"
               : "",
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "commentConclusion", value: undefined },
			],
			viewStructure: [1],
			dataList: [],
		}
		pregnancyUltrasoundConclusionModelSection.dataList = [];
		let data = {
               commentConclusion: pregnancyUltrasound?.commentConclusion
		}
		pregnancyUltrasoundConclusionModelSection.dataList?.push(data);
		if (pregnancyUltrasoundConclusionModelSection.dataList.length !== 0) {
			this.dataExportList.push(pregnancyUltrasoundConclusionModelSection);
		}
	}

	onFormatPregnancyUltrasoundFirstTFirstPart(element: any) {
		let datePregnancy = this.getPregnancyUltrasoundDate(element);
		let pregnancyUltrasoundFirstSection = {
			sectionName: datePregnancy!= null && this.generateDefaultData === true ? this.translationPipe.transform("lblPregnancyUltrasoundFirstTList") + " (" + this.dateUtcTimezonePipe.transform(datePregnancy) + ")" : this.translationPipe.transform("lblPregnancyUltrasoundFirstTList") ,
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "otherDoctor", value: this.translationPipe.transform("lblOtherDoctor") },
				{ key: "dateOtherDoctor", value: this.translationPipe.transform("lblDateOtherDoctor") },
				{ key: "recordSummury", value: this.translationPipe.transform("lblRecordSummury") },
				{ key: "commentSummary", value: undefined },
				{ key: "ultrasound", value: this.translationPipe.transform("lblUltrasound") },
				{ key: "ultrasoundRoute", value: this.translationPipe.transform("lblUltrasoundRoute") },
				{ key: "examCondition", value: this.translationPipe.transform("lblExamCondition") },
				{ key: "examConditionComment", value: this.translationPipe.transform("lblComment") },
				{ key: "fetusNumber", value: this.translationPipe.transform("lblFetusNumber") },
			],
			viewStructure: [2,1,1,5],
			dataList: [],
		}
		pregnancyUltrasoundFirstSection.dataList = [];
		if (this.generateDefaultData === false) {
			pregnancyUltrasoundFirstSection['generateDefaultData'] = this.generateDefaultData;
		}
		let data = {
			dateOtherDoctor: this.dateUtcTimezonePipe.transform(element?.dateOtherDoctor),
			otherDoctor: this.getElementFromList("idOtherDoctor", element.otherDoctor?.idOtherDoctor, this.dataSharedService.consultationSettings.otherDoctorList, "", "unique"),
            recordSummury: element?.commentSummary != undefined ? this.translationPipe.transform("lblRecordSummury"): undefined,
            commentSummary: element?.commentSummary,
            ultrasound: this.getUltrasound(element?.ultrasound),
			ultrasoundRoute: this.getElementFromList("id", element?.ultrasoundRoute?.id, UtrasoundRouteEnum.getUtrasoundRouteList(), "", "unique"),
			examCondition: this.getElementFromList("id", element.examCondition?.id, ExamConditionEnum.getExamConditionList(), "", "unique"),
            examConditionComment: element?.examConditionComment,
            fetusNumber: element?.fetusNumber
		}
		pregnancyUltrasoundFirstSection.dataList?.push(data);
		if (pregnancyUltrasoundFirstSection.dataList.length !== 0) {
			this.dataExportList.push(pregnancyUltrasoundFirstSection);
		}
	}
	
	onFormatPregnancyUltrasoundFirstTSecondPart(element: any) {
		let pregnancyUltrasoundFirstSection = {
			sectionName: "" ,
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "uterusAdnexa", value: this.translationPipe.transform("lblUterusAdnexa") },
				{ key: "cervixLenght", value: this.translationPipe.transform("lblCervixLenght") },
				{ key: "cervixApparence", value: this.translationPipe.transform("lblCervixApparence") },
			    { key: "adnexaLeft", value: this.translationPipe.transform("lblAdnexaLeft") },
				{ key: "adnexaRight", value: this.translationPipe.transform("lblAdnexaRight") },
				{ key: "uterus", value: this.translationPipe.transform("lblUterus") },
			],
			viewStructure: [1, 5],
			dataList: [],
		}
		pregnancyUltrasoundFirstSection.dataList = [];
		let data = {
			uterusAdnexa :  element?.cervixLenght || element?.cervixApparence || element?.adnexaLeft || element?.adnexaRight || element?.uterus 
            ? this.translationPipe.transform("lblUterusAdnexa")  : undefined,
			cervixLenght: element?.cervixLenght + ' ' + this.translationPipe.transform("lblUnitMm"),
			cervixApparence: element?.cervixApparence,
			adnexaLeft: element?.adnexaLeft, 
			adnexaRight: element?.adnexaRight,
			uterus: element?.uterus,
		}
		pregnancyUltrasoundFirstSection.dataList?.push(data);
		if (pregnancyUltrasoundFirstSection.dataList.length !== 0) {
			this.dataExportList.push(pregnancyUltrasoundFirstSection);
		}
	}

	onFormatFirstBabyData(dataList: any) {
		for (let i = 0; i < dataList.length; i++) {
			this.onFormatFirstBabyFoetusFirstPart(dataList[i]);
			this.onFormatPregnancyFirstDopplerFoetaux(dataList[i]);
			this.onFormatFirstBabyFoetusSecondPart(dataList[i]);
		}
	}

	onFormatFirstBabyFoetusFirstPart(value: any) {
		let pregnancyUltrasoundFirstBabySection = {
			sectionName: this.translationPipe.transform("lblPregnancyUltrasoundFirstTBabyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "heart", value: this.translationPipe.transform("lblHeart") },
				{ key: "heartRate", value: this.translationPipe.transform("lblHeartRate") },
				{ key: "mobility", value: this.translationPipe.transform("lblMobility") },
				{ key: "trophoblastePlacenta", value: this.translationPipe.transform("lblTrophoblastePlacenta") },
			],
			viewStructure: [4],
			dataList: [],
			subtitle: true
		}
		pregnancyUltrasoundFirstBabySection.dataList = [];
		let data = {
			heart: this.getElementFromList("id", value.heart?.id, PregnancyUltrasoundEarlyHeartEnum.getPregnancyUltrasoundEarlyHeartList(), "", "unique"),
			heartRate: value?.heartRate + ' ' + this.translationPipe.transform("lblBpm"),
			mobility: this.getElementFromList("id", value.mobility?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			trophoblastePlacenta: this.getElementFromList("id", value.trophoblastePlacenta?.id, StatusEnum.getStatusList(), "", "unique"),
		}
		pregnancyUltrasoundFirstBabySection.dataList.push(data);
		this.dataExportList.push(pregnancyUltrasoundFirstBabySection);
	}
	
	onFormatFirstBabyFoetusSecondPart(value: any) {
		let pregnancyUltrasoundFirstBabySection = {
			sectionName: this.translationPipe.transform(""),
			style: { fontStyle: 'bold' },
			columnHeader: [
                { key: "nasalBone", value: this.translationPipe.transform("lblNasalBone") },
                { key: "limb", value: this.translationPipe.transform("lblLimb") },
				{ key: "skull", value: this.translationPipe.transform("lblSkull") },
				{ key: "choroidPlexus", value: this.translationPipe.transform("lblChoroidPlexus") },
				{ key: "middleLine", value: this.translationPipe.transform("lblMiddleLine") },
				{ key: "abdomen", value: this.translationPipe.transform("lblAbdomen") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [6,1],
			dataList: [],
			subtitle: true
		}
		pregnancyUltrasoundFirstBabySection.dataList = [];
		let data = {
			nasalBone: this.getElementFromList("id", value.nasalBone?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			limb: this.getElementFromList("id", value.limb?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			skull: this.getElementFromList("id", value.skull?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			choroidPlexus: this.getElementFromList("id", value.choroidPlexus?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			middleLine: this.getElementFromList("id", value.middleLine?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			abdomen: this.getElementFromList("id", value.abdomen?.id, AppearanceEnum.getAppearanceList(), "", "unique"),
			comment: value?.comment?.replace(/\n/g, '<br>')
		}
		pregnancyUltrasoundFirstBabySection.dataList.push(data);
		this.dataExportList.push(pregnancyUltrasoundFirstBabySection);
	}

	onFormatPregnancyUltrasoundEarlyData(consultationPregnancyUltrasoundEarlyList: any) {
		for (let i = 0; i < consultationPregnancyUltrasoundEarlyList.length; i++) {
			this.onFormatEarlyData(consultationPregnancyUltrasoundEarlyList[i]);
			if (consultationPregnancyUltrasoundEarlyList[i].pregnancyUltrasoundEarlyBabyList && consultationPregnancyUltrasoundEarlyList[i].pregnancyUltrasoundEarlyBabyList.length !== 0) {
				let dataList = consultationPregnancyUltrasoundEarlyList[i].pregnancyUltrasoundEarlyBabyList;
				this.onFormatEarlyBabyList(dataList);
			}
			this.onFormatPregnancyConclusionModel(consultationPregnancyUltrasoundEarlyList[i]);
		}
	}

	onFormatEarlyData(element: any) {
		let datePregnancy = this.getPregnancyUltrasoundDate(element);
		let pregnancyUltrasoundEarlySection = {
			sectionName: datePregnancy !== null && this.generateDefaultData === true ?this.translationPipe.transform("lblPregnancyUltrasoundEarlyList") + " (" + this.dateUtcTimezonePipe.transform(datePregnancy) + ")" : this.translationPipe.transform("lblPregnancyUltrasoundEarlyList") ,
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "otherDoctor", value: this.translationPipe.transform("lblOtherDoctor") },
				{ key: "dateOtherDoctor", value: this.translationPipe.transform("lblDateOtherDoctor") },
			    { key: "recordSummury", value: this.translationPipe.transform("lblRecordSummury") },
				{ key: "commentSummary", value: undefined},	
				{ key: "ultrasound", value: this.translationPipe.transform("lblUltrasound") },
				{ key: "ultrasoundRoute", value: this.translationPipe.transform("lblUltrasoundRoute") },
			    { key: "examCondition", value: this.translationPipe.transform("lblExamCondition") },
				{ key: "examConditionComment", value: this.translationPipe.transform("lblComment") },
				{ key: "fetusNumber", value: this.translationPipe.transform("lblFetusNumber") },
			    { key: "uterusAdnexa", value: this.translationPipe.transform("lblUterusAdnexa") },
			    { key: "adnexaLeft", value: this.translationPipe.transform("lblAdnexaLeft") },
				{ key: "adnexaRight", value: this.translationPipe.transform("lblAdnexaRight") },
				{ key: "uterus", value: this.translationPipe.transform("lblUterus") },
			],
			viewStructure: [2,1,1,5,1,3],
			dataList: [],
		}
		pregnancyUltrasoundEarlySection.dataList = [];
		if (this.generateDefaultData === false) {
			pregnancyUltrasoundEarlySection['generateDefaultData'] = this.generateDefaultData;
		}
		let data = {
			dateOtherDoctor: this.dateUtcTimezonePipe.transform(element?.dateOtherDoctor),
			otherDoctor: this.getElementFromList("idOtherDoctor", element?.otherDoctor?.idOtherDoctor, this.dataSharedService.consultationSettings.otherDoctorList, "", "unique"),
            recordSummury: element?.commentSummary != null ? this.translationPipe.transform("lblRecordSummury") : undefined,
            commentSummary: element?.commentSummary,
		    ultrasoundRoute: this.getElementFromList("id", element?.ultrasoundRoute?.id, UtrasoundRouteEnum.getUtrasoundRouteList(), "", "unique"),
            ultrasound: this.getUltrasound(element?.ultrasound),
		    examCondition: this.getElementFromList("id", element.examCondition?.id, ExamConditionEnum.getExamConditionList(), "", "unique"),
            examConditionComment: element?.examConditionComment,
            fetusNumber: element?.fetusNumber,
			uterusAdnexa:
				element?.adnexaLeft || element?.adnexaRight || element?.uterus
					? this.translationPipe.transform("lblUterusAdnexa")
					: undefined, 
			adnexaLeft: element?.adnexaLeft, 
			adnexaRight: element?.adnexaRight,
			uterus: element?.uterus,
		}
		if (data.dateOtherDoctor === undefined && data.otherDoctor === undefined
			&& data.ultrasound === undefined && data.ultrasoundRoute === undefined) {
			this.initSimpleText(pregnancyUltrasoundEarlySection.sectionName);
		} else {
			pregnancyUltrasoundEarlySection.dataList.push(data);
			this.dataExportList.push(pregnancyUltrasoundEarlySection);
		}
	}
	
	getUltrasound(data: any) {
		let ultrasoundList = this.dataSharedService.consultationSettings.ultrasoundList;
		if (data === undefined || data === null) {
			return undefined;
		}
		const foundUltrasound = ultrasoundList.find(ultrasound => {
			return ultrasound.idUltrasound === data.idUltrasound;
		});
		let fullName = this.translationPipe.transform(foundUltrasound?.name)
			+ " (" + this.dateService.getStringUtcDate(Number(foundUltrasound?.date), "MM/YYYY") + ")";
		return fullName;
	}

	onFormatEarlyBabyList(dataList: any) {
		for (let i = 0; i < dataList.length; i++) {
			this.FormatEarlyBabyFirstPart(dataList[i]);
			this.FormatEarlyBabyDopplersFoetaux(dataList[i]);
			this.FormatEarlyBabySecondPart(dataList[i]);
		}
	}

	FormatEarlyBabyFirstPart(element: any) {
		let pregnancyUltrasoundEarlyBabySection = {
			sectionName: this.translationPipe.transform("lblPregnancyUltrasoundEarlyBabyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "heart", value: this.translationPipe.transform("lblHeart") },
				{ key: "heartRate", value: this.translationPipe.transform("lblHeartRate") },
				{ key: "trophoblast", value: this.translationPipe.transform("lblTrophoblast") },
			],
			viewStructure: [3],
			dataList: [],
			subtitle: true
		}
		pregnancyUltrasoundEarlyBabySection.dataList = [];
		let dataBaby = {
			heart: this.getElementFromList("id", element.heart?.id, PregnancyUltrasoundEarlyHeartEnum.getPregnancyUltrasoundEarlyHeartList(), "", "unique"),
			heartRate: element?.heartRate + ' ' + this.translationPipe.transform("lblBpm"),
			trophoblast: this.getElementFromList("id", element.trophoblast?.id, StatusEnum.getStatusList(), "", "unique"),
		};
		pregnancyUltrasoundEarlyBabySection.dataList?.push(dataBaby);
		this.dataExportList.push(pregnancyUltrasoundEarlyBabySection)
	}
	
	FormatEarlyBabySecondPart(element: any) {
		let pregnancyUltrasoundEarlyBabySection = {
			sectionName: "",
			style: { fontStyle: 'bold' },
			columnHeader: [
				
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [1],
			dataList: [],
			subtitle: true
		}
		pregnancyUltrasoundEarlyBabySection.dataList = [];
		let dataBaby = {
			comment: element?.comment?.replace(/\n/g, '<br>')
		};
		pregnancyUltrasoundEarlyBabySection.dataList?.push(dataBaby);
		this.dataExportList.push(pregnancyUltrasoundEarlyBabySection)
	}


	onFormatPregnancyOutcomeData(consultationPregnancyOutcomeList: any) {
		for (let i = 0; i < consultationPregnancyOutcomeList.length; i++) {
			let element = consultationPregnancyOutcomeList[i];
			this.onFormatPregnancyOutcome(element);
		}
	}

	onFormatPregnancyOutcome(element: any) {
		let pregnancyOutcomeDeliveryPrevisionSection = {
			sectionName: this.translationPipe.transform("lblDeliveryPrevision"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "operationExpectedPlace", value: this.translationPipe.transform("lblExpectedPlace") },
				{ key: "expectedDeliveryDate", value: this.translationPipe.transform("lblExpectedDeliveryDate") },
				{ key: "expectedDeliveryMode", value: this.translationPipe.transform("lblExpectedDeliveryMode") },
				{ key: "deliveryComment", value: this.translationPipe.transform("lblComments") }
			],
			viewStructure: [4],
			dataList: [],
		}
		
		pregnancyOutcomeDeliveryPrevisionSection.dataList = [];
		let dataa = {
			operationExpectedPlace: this.getElementFromList("idOperationPlace", element.operationExpectedPlace?.idOperationPlace, this.dataSharedService.operationPlaceList, "", "unique"),
			expectedDeliveryDate: this.dateUtcTimezonePipe.transform(element?.expectedDeliveryDate),
			deliveryComment: element?.deliveryComment?.replace(/\n/g, '<br>'),
			expectedDeliveryMode: this.getElementFromList("id", element.expectedDeliveryMode?.id, ExpectedDeliveryModeEnum.getExpectedDeliveryModeList(), "", "unique"),
		}
		pregnancyOutcomeDeliveryPrevisionSection.dataList?.push(dataa);
		if ((dataa.operationExpectedPlace !== undefined && dataa.operationExpectedPlace !== null) || (dataa.expectedDeliveryDate !== undefined && dataa.expectedDeliveryDate !== null)
			|| (dataa.deliveryComment !== undefined && dataa.deliveryComment !== null) || (dataa.expectedDeliveryMode !== undefined && dataa.expectedDeliveryMode !== null)) {
			this.dataExportList.push(pregnancyOutcomeDeliveryPrevisionSection);
		}
		let pregnancyOutcomeSection = {
			sectionName: this.translationPipe.transform("lblPregnancyOutcome"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "outcome", value: this.translationPipe.transform("lblOutcome") },
				{ key: "intervention", value: this.translationPipe.transform("lblIntervention") },
				{ key: "deliveryDate", value: this.translationPipe.transform("lblDeliveryDate") },
				{ key: "gestationalAgeWeeks", value: this.translationPipe.transform("lblGestationalAgeWeeks") },
				{ key: "gestationalAgeDays", value: this.translationPipe.transform("lblGestationalAgeDays") },
				{ key: "deliveryModeDeprecated", value: this.translationPipe.transform("lblDeliveryMode") },
				{ key: "cesarieanIndication", value: this.translationPipe.transform("lblCesarieanIndication") },
				{ key: "weigntBirth", value: this.translationPipe.transform("lblWeigntBirth") },
				{ key: "operationPlace", value: this.translationPipe.transform("lblPlace") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [4,5,1],
			dataList: [],
		}
		let interventionList = [];
		if (element.outcome !== undefined && element.outcome !== null) {
			let outcome: Data = OutcomeEnum.getOutcomeList().find(o => o.id == element.outcome.id);
			interventionList = outcome.dataList;
		}
		pregnancyOutcomeSection.dataList = [];
		let data = {
			outcome: this.getElementFromList("id", element.outcome?.id, OutcomeEnum.getOutcomeList(), "", "unique"),
            intervention: this.getElementFromList("id", element.intervention?.id, interventionList, "", "unique"),
			deliveryDate: this.dateUtcTimezonePipe.transform(element?.deliveryDate),
			gestationalAgeWeeks: element?.gestationalAgeWeeks,
			gestationalAgeDays: element?.gestationalAgeDays,
			deliveryModeDeprecated: this.getElementFromList("id", element.deliveryModeDeprecated?.id, DeliveryModeEnum.getDeliveryModeList(), "", "unique"),
			cesarieanIndication: element?.cesarieanIndication,
			weigntBirth: element?.weigntBirth + ' ' + this.translationPipe.transform("lblGramme"),
			operationPlace: this.getElementFromList("idOperationPlace", element.operationPlace?.idOperationPlace, this.dataSharedService.operationPlaceList, "", "unique"),
			comment: element?.comment?.replace(/\n/g, '<br>')
		}
		pregnancyOutcomeSection.dataList?.push(data)
		this.dataExportList.push(pregnancyOutcomeSection);
	}

	onFormatOvarianHyperstimulationIvfList(HyperstimulationIvfList: any) {
		for (let i = 0; i < HyperstimulationIvfList.length; i++) {
			let element = HyperstimulationIvfList[i];
			this.onFormatOvarianHyperstimulationIvf(element);
		}
	}
	
	onFormatOvarianHyperstimulationIvf(element: any) {
		let hyperstimulationIvfSection = {
			sectionName: this.translationPipe.transform("lblFertilityControlledOvarianHyperstimulationIvf"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "retreivalDate", value: this.translationPipe.transform("lblRetreivalDate") },
				{ key: "retrievalHour", value: this.translationPipe.transform("lblRetrievalHour") },
				{ key: "fertilityMapCenter", value: this.translationPipe.transform("lblMapCenter") },
				{ key: "fertilityAnesthesia", value: this.translationPipe.transform("lblAnesthesia") },
				{ key: "retreivalComment", value: this.translationPipe.transform("lblRetreivalComment") },
				{ key: "oocytesTotal", value: this.translationPipe.transform("lblOocytesTotal") },
				{ key: "oocytesMOne", value: this.translationPipe.transform("lblOocytesMOne") },
				{ key: "oocytesMTwo", value: this.translationPipe.transform("lblOocytesMTwo") },
				{ key: "oocytesDegenerative", value: this.translationPipe.transform("lblOocytesDegenerative") },
				{ key: "germinaVesical", value: this.translationPipe.transform("lblGerminaVesical") },
				{ key: "fertilityMapMethod", value: this.translationPipe.transform("lblMapMethod") },
				{ key: "fertilitySpermOrigin", value: this.translationPipe.transform("lblSpermOrigin") },
				{ key: "spermComment", value: this.translationPipe.transform("lblSpermComment") },
				{ key: "oocytesInjected", value: this.translationPipe.transform("lblOocytesInjected") },
				{ key: "oocytesFertilized", value: this.translationPipe.transform("lblOocytesFertilized") },
				{ key: "embryoDayTwo", value: this.translationPipe.transform("lblEmbryoDayTwo") },
				{ key: "embryoDayTwoComment", value: this.translationPipe.transform("lblEmbryoDayTwoComment") },
				{ key: "freezEmbryoDayTwo", value: this.translationPipe.transform("lblFreezEmbryoDayTwo") },
				{ key: "freezEmbryoDayTwoComment", value: this.translationPipe.transform("lblFreezEmbryoDayTwoComment") },
				{ key: "embryoDayThree", value: this.translationPipe.transform("lblEmbryoDayThree") },
				{ key: "embryoDayThreeComment", value: this.translationPipe.transform("lblEmbryoDayThreeComment") },
				{ key: "freezEmbryoDayThree", value: this.translationPipe.transform("lblFreezEmbryoDayThree") },
				{ key: "freezEmbryoDayThreeComment", value: this.translationPipe.transform("lblFreezEmbryoDayThreeComment") },
				{ key: "embryoDayFour", value: this.translationPipe.transform("lblEmbryoDayFour") },
				{ key: "embryoDayFourComment", value: this.translationPipe.transform("lblEmbryoDayFourComment") },
				{ key: "freezEmbryoDayFour", value: this.translationPipe.transform("lblFreezEmbryoDayFour") },
				{ key: "freezEmbryoDayFourComment", value: this.translationPipe.transform("lblFreezEmbryoDayFourComment") },
				{ key: "embryoDayFive", value: this.translationPipe.transform("lblEmbryoDayFive") },
				{ key: "embryoDayFiveComment", value: this.translationPipe.transform("lblEmbryoDayFiveComment") },
				{ key: "freezEmbryoDayFive", value: this.translationPipe.transform("lblFreezEmbryoDayFive") },
				{ key: "freezEmbryoDayFiveComment", value: this.translationPipe.transform("lblFreezEmbryoDayFiveComment") },
				{ key: "transferDate", value: this.translationPipe.transform("lblTransferDate") },
				{ key: "transferEmbryoDay", value: this.translationPipe.transform("lblTransferEmbryoDay") },
				{ key: "transferEmbryoNumber", value: this.translationPipe.transform("lblTransferEmbryoNumber") },
				{ key: "transferEmbryoType", value: this.translationPipe.transform("lblTransferEmbryoType") },
				{ key: "transferEmbryoQuality", value: this.translationPipe.transform("lblTransferEmbryoQuality") },
				{ key: "transferDifficulty", value: this.translationPipe.transform("lblTransferDifficulty") },
				{ key: "transferComment", value: this.translationPipe.transform("lblTransferComment") },
				{ key: "bhcg", value: this.translationPipe.transform("lblBhc") },
				{ key: "outcomePregnancy", value: this.translationPipe.transform("lblPregnancyOutcome") }
			],
			viewStructure: [5, 5, 5, 4, 4, 4, 4, 6, 1, 2],
			dataList: [],
		}
		hyperstimulationIvfSection.dataList = [];
		let data = {
			retreivalDate: this.dateUtcTimezonePipe.transform(element.retreivalDate),
			retrievalHour: element?.retrievalHour,
			fertilityMapCenter: this.getElementFromList("idFertilityMapCenter", element.fertilityMapCenter?.idFertilityMapCenter, this.dataSharedService.fertilitySettings.fertilityMapCenterList, "", "unique"),
			fertilityAnesthesia: this.getElementFromList("idFertilityAnesthesia", element.fertilityAnesthesia?.idFertilityAnesthesia, this.dataSharedService.fertilitySettings.fertilityAnesthesiaList, "", "unique"),
			retreivalComment: element?.retreivalComment?.replace(/\n/g, '<br>'),
			oocytesTotal: element?.oocytesTotal,
			oocytesMOne: element?.oocytesMOne,
			oocytesMTwo: element?.oocytesMTwo,
			oocytesDegenerative: element?.oocytesDegenerative,
			germinaVesical: element?.germinaVesical,
			fertilityMapMethod: this.getElementFromList("idFertilityMapMethod", element.fertilityMapMethod?.idFertilityMapMethod, this.dataSharedService.fertilitySettings.fertilityMapMethodList, "", "unique"),
			fertilitySpermOrigin: this.getElementFromList("idFertilitySpermOrigin", element.fertilitySpermOrigin?.idFertilitySpermOrigin, this.dataSharedService.fertilitySettings.fertilitySpermOriginList, "", "unique"),
			spermComment: element?.spermComment?.replace(/\n/g, '<br>'),
			oocytesInjected: element?.oocytesInjected,
			oocytesFertilized: element?.oocytesFertilized,
			embryoDayTwo: element?.embryoDayTwo,
			embryoDayTwoComment: element?.embryoDayTwoComment?.replace(/\n/g, '<br>'),
			freezEmbryoDayTwo: element?.freezEmbryoDayTwo,
			freezEmbryoDayTwoComment: element?.freezEmbryoDayTwoComment?.replace(/\n/g, '<br>'),
			embryoDayThree: element?.embryoDayThree,
			embryoDayThreeComment: element?.embryoDayThreeComment?.replace(/\n/g, '<br>'),
			freezEmbryoDayThree: element?.freezEmbryoDayThree,
			freezEmbryoDayThreeComment: element?.freezEmbryoDayThreeComment?.replace(/\n/g, '<br>'),
			embryoDayFour: element?.embryoDayFour,
			embryoDayFourComment: element?.embryoDayFourComment?.replace(/\n/g, '<br>'),
			freezEmbryoDayFour: element?.freezEmbryoDayFour,
			freezEmbryoDayFourComment: element?.freezEmbryoDayFourComment?.replace(/\n/g, '<br>'),
			embryoDayFive: element?.embryoDayFive,
			embryoDayFiveComment: element?.embryoDayFiveComment?.replace(/\n/g, '<br>'),
			freezEmbryoDayFive: element?.freezEmbryoDayFive,
			freezEmbryoDayFiveComment: element?.freezEmbryoDayFiveComment?.replace(/\n/g, '<br>'),
			transferDate: element?.transferDate,
			transferEmbryoDay: element?.transferEmbryoDay,
			transferEmbryoNumber: element?.transferEmbryoNumber,
			transferEmbryoType: this.getElementFromList("id", element.transferEmbryoType?.id, TransferEmbryoDayEnum.getTransferEmbryoDayList(), "", "unique"),
			transferEmbryoQuality: element?.transferEmbryoQuality,
			transferDifficulty: this.getElementFromList("id", element.transferDifficulty?.id, ValidationEnum.getValidationList(), "", "unique"),
			transferComment: element?.transferComment?.replace(/\n/g, '<br>'),
			bhcg: this.getElementFromList("id", element.bhcg?.id, BhcgEnum.getBhcgList(), "", "unique"),
			outcomePregnancy: this.getElementFromList("id", element.outcomePregnancy?.id, OutcomeEnum.getOutcomeList(), "", "unique")
		}
		hyperstimulationIvfSection.dataList?.push(data)
		this.dataExportList.push(hyperstimulationIvfSection);
	}

	onFormatOvarianHyperstimulationIuiList(HyperstimulationIuiList: any) {
		for (let i = 0; i < HyperstimulationIuiList.length; i++) {
			let element = HyperstimulationIuiList[i];
			this.onFormatOvarianHyperstimulationIui(element);
		}
	}
	
	onFormatOvarianHyperstimulationIui(element: any) {
		let hyperstimulationIuiSection = {
			sectionName: this.translationPipe.transform("lblFertilityControlledOvarianHyperstimulationIui"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "fertilityMapCenter", value: this.translationPipe.transform("lblMapCenter") },
				{ key: "tms", value: this.translationPipe.transform("lblTms") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
				{ key: "bhcg", value: this.translationPipe.transform("lblBhc") },
				{ key: "outcomePregnancy", value: this.translationPipe.transform("lblPregnancyOutcome") },
			],
			viewStructure: [4, 2],
			dataList: [],
		}
		hyperstimulationIuiSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(element.date),
			fertilityMapCenter: this.getElementFromList("idFertilityMapCenter", element.fertilityMapCenter?.idFertilityMapCenter, this.dataSharedService.fertilitySettings.fertilityMapCenterList, "", "unique"),
			tms: element?.tms,
			comment: element?.comment?.replace(/\n/g, '<br>'),
			bhcg: this.getElementFromList("id", element.bhcg?.id, BhcgEnum.getBhcgList(), "", "unique"),
			outcomePregnancy: this.getElementFromList("id", element.outcomePregnancy?.id, OutcomeEnum.getOutcomeList(), "", "unique")
		}
		hyperstimulationIuiSection.dataList?.push(data)
		this.dataExportList.push(hyperstimulationIuiSection);
	}
	
	onFormatOvarianHyperstimulationScheduledReportList(hyperstimulationScheduledReportList: any) {
		for (let i = 0; i < hyperstimulationScheduledReportList.length; i++) {
			let element = hyperstimulationScheduledReportList[i];
			this.onFormatOvarianHyperstimulationScheduledReport(element);
		}
	}
	
	onFormatOvarianHyperstimulationScheduledReport(element: any) {
		let hyperstimulationScheduledReportSection = {
			sectionName: this.translationPipe.transform("lblFertilityControlledOvarianHyperstimulationScheduledReport"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "bhcg", value: this.translationPipe.transform("lblBhc") },
				{ key: "outcomePregnancy", value: this.translationPipe.transform("lblPregnancyOutcome") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") }
			],
			viewStructure: [4, 1],
			dataList: [],
		}
		hyperstimulationScheduledReportSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(element.date),
			bhcg: this.getElementFromList("id", element.bhcg?.id, BhcgEnum.getBhcgList(), "", "unique"),
			outcomePregnancy: this.getElementFromList("id", element.outcomePregnancy?.id, OutcomeEnum.getOutcomeList(), "", "unique"),
			comment: element?.comment?.replace(/\n/g, '<br>')
		}
		hyperstimulationScheduledReportSection.dataList?.push(data)
		this.dataExportList.push(hyperstimulationScheduledReportSection);
	}

	onFormatUltrasoundList(gynecoUltrasoundList: any) {
		for (let i = 0; i < gynecoUltrasoundList.length; i++) {
			let element = gynecoUltrasoundList[i];
			this.onFormatUltrasound(element);
			if(element?.gynecoUltrasoundMyomaList.length !== 0){
				this.onFormatMyomaList(element.gynecoUltrasoundMyomaList);
			}
			this.onFormatPregnancyConclusionModel(element);
		}
	}
	
	onFormatMyomaList(gynecoUltrasoundMyomaList: any) {
		let myomaSection = {
			sectionName: this.translationPipe.transform("lblGynecoUltrasoundMyomaList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "size", value: this.translationPipe.transform("lblSize") },
				{ key: "location", value: this.translationPipe.transform("lblLocation") },
				{ key: "figo", value: this.translationPipe.transform("lblFigo") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: [],
		}
		for (let i = 0; i < gynecoUltrasoundMyomaList.length; i++) {
			let element = gynecoUltrasoundMyomaList[i];
			let data = {
				size: element?.size + ' ' + this.translationPipe.transform("lblUnitMm"),
				location: this.getElementFromList("id", element?.location?.id, LocationEnum.getLocationList(), "", "unique"),
				figo: element?.figo,
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			myomaSection.dataList.push(data);
		}
		this.dataExportList.push(myomaSection);
	}

	onFormatUltrasound(element: any) {
		let date = this.getGynecoUltrasoundDate(element);
		let UltrasoundSection = {
			sectionName: date !== null && this.generateDefaultData === true ? this.translationPipe.transform("lblGynecoUltrasoundList") + " (" + this.dateUtcTimezonePipe.transform(date) + ")" : this.translationPipe.transform("lblGynecoUltrasoundList") ,
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDateOtherDoctor") },
				{ key: "otherDoctor", value: this.translationPipe.transform("lblOtherDoctor") },
				{ key: "recordSummury", value: this.translationPipe.transform("lblRecordSummury") },
				{ key: "commentSummary", value: undefined },
				{ key: "ultrasound", value: this.translationPipe.transform("lblUltrasound") },
				{ key: "ultrasoundRoute", value: this.translationPipe.transform("lblUltrasoundRoute") },
				{ key: "cycleDay", value: this.translationPipe.transform("lblCycleDay") },
				{ key: "examCondition", value: this.translationPipe.transform("lblExamCondition") },
				{ key: "examConditionComment", value: this.translationPipe.transform("lblComment") },
				{ key: "uterus", value: this.translationPipe.transform("lblUterus") },
				{ key: "uterusSize", value: this.translationPipe.transform("lblUterusSize") },
				{ key: "uterusLength", value: this.translationPipe.transform("lblUterusLength") },
				{ key: "uterusWidth" , value: this.translationPipe.transform("lblUterusWidth") },
				{ key: "myometre", value: this.translationPipe.transform("lblMyometre") },
				{ key: "myomaNumber", value: this.translationPipe.transform("lblMyomaNumber") },
				{ key: "endometerThickness", value: this.translationPipe.transform("lblEndometerThickness") },
				{ key: "endometerDescription", value: this.translationPipe.transform("lblEndometerDescription") },
				{ key: "polype", value: this.translationPipe.transform("lblPolype") },
				{ key: "polypeDescription", value: this.translationPipe.transform("lblPolypeDescription") },
				{ key: "ovaryRightTitle", value: this.translationPipe.transform("lblOvaryRightTitle") },
				{ key: "ovaryRight", value: this.translationPipe.transform("lblOvaryRight") },
				{ key: "ovaryRightSize", value: this.translationPipe.transform("lblOvaryRightSize") },
				{ key: "cystOvaryRightSize", value: this.translationPipe.transform("lblCystOvaryRightSize") },
				{ key: "cystOvaryRightPresumptionList", value: this.translationPipe.transform("lblCystOvaryRightPresumptionList") },
				{ key: "cystOvaryRightDescription", value: this.translationPipe.transform("lblCystOvaryRightDescription") },
				{ key: "ovaryLeftTitle", value: this.translationPipe.transform("lblOvaryLeftTitle") },
				{ key: "ovaryLeft", value: this.translationPipe.transform("lblOvaryLeft") },
				{ key: "ovaryLeftSize", value: this.translationPipe.transform("lblOvaryLeftSize") },
				{ key: "cystOvaryLeftSize", value: this.translationPipe.transform("lblCystOvaryLeftSize") },
				{ key: "cystOvaryLeftPresumptionList", value: this.translationPipe.transform("lblCystOvaryLeftPresumptionList") },
				{ key: "cystOvaryLeftDescription", value: this.translationPipe.transform("lblCystOvaryLeftDescription") },
				{ key: "pelvicMassRightTitle", value: this.translationPipe.transform("lblPelvicMassRight") },
				{ key: "pelvicMassRight", value: undefined },
				{ key: "pelvicMassRightSize", value: this.translationPipe.transform("lblPelvicMassRightSize") },
				{ key: "pelvicMassRightPresumptionList", value: this.translationPipe.transform("lblPelvicMassRightPresumptionList") },
				{ key: "pelvicMassRightDescription", value: this.translationPipe.transform("lblPelvicMassRightDescription") },
				{ key: "pelvicMassLeftTitle", value: this.translationPipe.transform("lblPelvicMassLeft") },
				{ key: "pelvicMassLeft", value: undefined },
				{ key: "pelvicMassLeftSize", value: this.translationPipe.transform("lblPelvicMassLeftSize") },
				{ key: "pelvicMassLeftPresumptionList", value: this.translationPipe.transform("lblPelvicMassLeftPresumptionList") },
				{ key: "pelvicMassLeftDescription", value: this.translationPipe.transform("lblPelvicMassLeftDescription") },
				{ key: "douglasTitle", value: this.translationPipe.transform("lblDouglasTitle") },
				{ key: "douglas", value: this.translationPipe.transform("lblDouglas") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
				{ key: "afcRight", value: this.translationPipe.transform("lblAfcRight") },
				{ key: "afcLeft", value: this.translationPipe.transform("lblAfcLeft") },
			],
			viewStructure: [2,1,1,5,1,5,4,3,3,3,3,5,5,3],
			dataList: [],
		}
		UltrasoundSection.dataList = [];
		if (this.generateDefaultData === false) {
			UltrasoundSection['generateDefaultData'] = this.generateDefaultData;
		}
		let data = {
			date: this.dateUtcTimezonePipe.transform(element.date),
			otherDoctor: this.getElementFromList("idOtherDoctor", element.otherDoctor?.idOtherDoctor, this.dataSharedService.consultationSettings.otherDoctorList, "", "unique"),
            recordSummury: element?.commentSummary != null ? this.translationPipe.transform("lblRecordSummury") : undefined,
            commentSummary: element?.commentSummary,
			ultrasound: this.getUltrasound(element?.ultrasound),
			ultrasoundRoute: this.getElementFromList("id", element?.ultrasoundRoute?.id, UtrasoundRouteEnum.getUtrasoundRouteList(), "", "unique"),
			cycleDay: element?.cycleDay,
			examCondition: this.getElementFromList("id", element.examCondition?.id, ExamConditionEnum.getExamConditionList(), "", "unique"),
            examConditionComment: element?.examConditionComment,
			uterus: element?.uterusSize || element?.uterusLength || element?.uterusWidth ||
				element?.myometre || element?.myomaNumber || element?.endometerThickness ||
				element?.endometerDescription || element?.polype || element?.polypeDescription
				? this.translationPipe.transform("lblUterus")
				: undefined,
			uterusSize: this.getElementFromList("id", element.uterusSize?.id, UterusSizeEnum.getUterusSizeList(), "", "unique"),
			uterusLength: element?.uterusLength + ' ' + this.translationPipe.transform("lblUnitMm"),
			uterusWidth: element?.uterusWidth + ' ' + this.translationPipe.transform("lblUnitMm"),
			myometre: this.getElementFromList("id", element.myometre?.id, MyometreEnum.getMyometreList(), "", "unique"),
			myomaNumber: element?.myomaNumber,
			endometerThickness: element?.endometerThickness + ' ' + this.translationPipe.transform("lblUnitMm"),
			endometerDescription: element?.endometerDescription,
			polype: this.getElementFromList("id", element.polype?.id, ValidationEnum.getValidationList(), "", "unique"),
			polypeDescription: element?.polypeDescription,
			ovaryRightTitle: element?.ovaryRight || element?.ovaryRightSize || element?.cystOvaryRightSize ||
				element?.cystOvaryRightPresumptionList.length !== 0 || element?.cystOvaryRightDescription
				? this.translationPipe.transform("lblOvaryRightTitle")
				: undefined,
			ovaryRight: this.getElementFromList("id", element.ovaryRight?.id, OvaryEnum.getOvaryList(), "", "unique"),
			ovaryRightSize: element?.ovaryRightSize + ' ' + this.translationPipe.transform("lblUnitMm"),
			cystOvaryRightSize: element?.cystOvaryRightSize + ' ' + this.translationPipe.transform("lblUnitMm"),
			cystOvaryRightPresumptionList: this.getOvairePresumptionList(element?.cystOvaryRightPresumptionList),
			cystOvaryRightDescription: element?.cystOvaryRightDescription,
			ovaryLeftTitle: element?.ovaryLeft || element?.ovaryLeftSize || element?.cystOvaryLeftSize ||
				element?.cystOvaryLeftPresumptionList.length !== 0 || element?.cystOvaryLeftDescription
				? this.translationPipe.transform("lblOvaryLeftTitle")
				: undefined, 
			ovaryLeft: this.getElementFromList("id", element.ovaryLeft?.id, OvaryEnum.getOvaryList(), "", "unique"),
			ovaryLeftSize: element?.ovaryLeftSize + ' ' + this.translationPipe.transform("lblUnitMm"),
			cystOvaryLeftSize: element?.cystOvaryLeftSize + ' ' + this.translationPipe.transform("lblUnitMm"),
			cystOvaryLeftPresumptionList: this.getOvairePresumptionList(element?.cystOvaryLeftPresumptionList),
			cystOvaryLeftDescription: element?.cystOvaryLeftDescription,
			pelvicMassRightTitle: element?.pelvicMassRight || element?.pelvicMassRightSize ||
				element?.pelvicMassRightPresumptionList || element?.pelvicMassRightDescription
				? this.translationPipe.transform("lblPelvicMassRight")
				: undefined,
			pelvicMassRight: this.getElementFromList("id", element.pelvicMassRight?.id, ValidationEnum.getValidationList(), "", "unique"),
			pelvicMassRightSize: element?.pelvicMassLeftSize + this.translationPipe.transform("lblUnitMm"),
			pelvicMassRightPresumptionList: this.getPelvicMassPresumptionList(element?.pelvicMassRightPresumptionList),
			pelvicMassRightDescription: element?.pelvicMassRightDescription,
			pelvicMassLeftTitle: element?.pelvicMassLeft || element?.pelvicMassLeftSize ||
				element?.pelvicMassLeftPresumptionList || element?.pelvicMassLeftDescription
				? this.translationPipe.transform("lblPelvicMassLeft")
				: undefined,
			pelvicMassLeft: this.getElementFromList("id", element.pelvicMassLeft?.id, ValidationEnum.getValidationList(), "", "unique"),
			pelvicMassLeftSize: element?.pelvicMassLeftSize + this.translationPipe.transform("lblUnitMm"),
			pelvicMassLeftPresumptionList: this.getPelvicMassPresumptionList(element?.pelvicMassLeftPresumptionList),
			pelvicMassLeftDescription: element?.pelvicMassLeftDescription,
			douglasTitle: element?.douglas || element?.comment
				? this.translationPipe.transform("lblDouglasTitle")
				: undefined,
			douglas: this.getElementFromList("id", element.douglas?.id, DouglasEnum.getDouglasList(), "", "unique"),
			comment: element?.comment?.replace(/\n/g, '<br>'),
			afcRight: element?.afcRight + ' ' + this.translationPipe.transform("lblFollicules"),
			afcLeft: element?.afcLeft + ' ' + this.translationPipe.transform("lblFollicules"),
		}
		UltrasoundSection.dataList?.push(data)
		this.dataExportList.push(UltrasoundSection);
	}

	onFormatFreeSpaceList(FreeSpaceList: any) {
		for (let i = 0; i < FreeSpaceList.length; i++) {
			let element = FreeSpaceList[i];
			this.onFormatFreeSpace(element);
		}
	}

	onFormatFreeSpace(element: any) {
		let freeSpaceSection = {
			sectionName: element.date !== null ? this.translationPipe.transform("lblFreeSpace") + " (" + this.dateUtcTimezonePipe.transform(element.date) + ")" : this.translationPipe.transform("lblFreeSpace"),
			style: { fontStyle: 'bold' },
			dataList: [],
		}
		freeSpaceSection.dataList = [];
		let data = {
			"comment": element.comment?.replace(/\n/g, '<br>')
		}
		freeSpaceSection.dataList?.push(data)
		this.dataExportList.push(freeSpaceSection);
	}

	onFormatoperativeReportList(operativeReportList: any) {
		for (let i = 0; i < operativeReportList.length; i++) {
			this.onFormatoperativeReport(operativeReportList[i]);
		}
	}

	onFormatoperativeReport(operativeReport: any) {
		let date: any;
		if (operativeReport.consultationGeneralMedicine && operativeReport.consultationGeneralMedicine !== null) {
			date = operativeReport.consultationGeneralMedicine.date !== undefined && operativeReport.consultationGeneralMedicine.date !== null ? operativeReport.consultationGeneralMedicine.date : null;
		}
		if (operativeReport.consultationCardiology && operativeReport.consultationCardiology !== null) {
			date = operativeReport.consultationCardiology.date !== undefined && operativeReport.consultationCardiology.date !== null ? operativeReport.consultationCardiology.date : null;
		}
		if (operativeReport.consultationGyneco && operativeReport.consultationGyneco !== null) {
			date = operativeReport.consultationGyneco.date !== undefined && operativeReport.consultationGyneco.date !== null ? operativeReport.consultationGyneco.date : null;
		}
		if (operativeReport.consultationPregnancy && operativeReport.consultationPregnancy !== null) {
			date = operativeReport.consultationPregnancy.date !== undefined && operativeReport.consultationPregnancy.date !== null ? operativeReport.consultationPregnancy.date : null;
		}
		if (operativeReport.consultationFertility && operativeReport.consultationFertility !== null) {
			date = operativeReport.consultationFertility.date !== undefined && operativeReport.consultationFertility.date !== null ? operativeReport.consultationFertility.date : null;
		}
		let operativeReportSection = {
			sectionName: date !== null ? this.translationPipe.transform("lblOperativeReportList") + " (" +  this.dateUtcTimezonePipe.transform(date) +")" : this.translationPipe.transform("lblOperativeReportList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "operationDate", value: this.translationPipe.transform("lblDate") },
				{ key: "operativeReportTypeList", value: this.translationPipe.transform("lblType") },
				{ key: "otherDoctor", value: this.translationPipe.transform("lblOtherDoctor") },
				{ key: "operationPlace", value: this.translationPipe.transform("lblPlace") },
				{ key: "indication", value: this.translationPipe.transform("lblIndication") },
				{ key: "operativeReportModel", value: this.translationPipe.transform("lblModel") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [5, 2],
			dataList: [],
		}
		operativeReportSection.dataList = [];
		let data = {
			operationDate: this.dateUtcTimezonePipe.transform(operativeReport.operationDate),
			operativeReportTypeList: this.translationPipe.transform("Type") + this.getoperativeReportTypeList(operativeReport.operativeReportTypeList),
			otherDoctor: this.getElementFromList("idOtherDoctor", operativeReport.otherDoctor?.idOtherDoctor, this.dataSharedService.consultationSettings.otherDoctorList, "", "unique"),
			operationPlace: this.getElementFromList("idOperationPlace", operativeReport.operationPlace?.idOperationPlace, this.dataSharedService.operationPlaceList, "", "unique"),
			indication: operativeReport?.indication,
			operativeReportModel: this.getElementFromList("idOperativeReportModel", operativeReport.operativeReportModel?.idOperativeReportModel, this.dataSharedService.consultationSettings.operativeReportModelList, "", "unique"),
			comment: operativeReport?.comment?.replace(/\n/g, '<br>')
		}
		operativeReportSection.dataList?.push(data)
		this.dataExportList.push(operativeReportSection);
	}
	
	onFormatconsultationGeneralMedicineDetailsList(consultationGeneralMedicineDetailsList: any) {
		for (let i = 0; i < consultationGeneralMedicineDetailsList.length; i++) {
			let element = consultationGeneralMedicineDetailsList[i];
			this.onFormatconsultationGeneralMedicineDetails(element);
		}
	}

	onFormatconsultationGeneralMedicineDetails(element: any) {
		let doesDateExist: boolean = element.date !== undefined && element.date !== null ? true : false;
		let consultationGeneralMedicineDetailsSection = {
			sectionName: doesDateExist ? this.translationPipe.transform("lblGeneralMedicineConsultation") + " (" + this.dateUtcTimezonePipe.transform(element.date) + ")" : this.translationPipe.transform("lblGeneralMedicineConsultation"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "generalMedicineReasonList", value: this.translationPipe.transform("lblReason") },
				{ key: "evolutionDuration", value: this.translationPipe.transform("lblEvolutionDuration") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
				{ key: "interrogation", value: this.translationPipe.transform("lblInterrogation")}

			],
			viewStructure: [4,1],
			dataList: [],
		}
		consultationGeneralMedicineDetailsSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(element.date),
			generalMedicineReasonList: this.getGeneralMedicineReasonList(element?.generalMedicineReasonList),
			evolutionDuration: element?.evolutionDuration,
			comment: this.dateUtcTimezonePipe.transform(element?.comment),
			interrogation: element.interrogation !== "" && element.interrogation !== '<br>' && element.interrogation !== undefined ?  '<br>' + element?.interrogation.replace(/<\/?p>/g, ""): undefined
		}
		consultationGeneralMedicineDetailsSection.dataList?.push(data)
		this.dataExportList.push(consultationGeneralMedicineDetailsSection);
	}

	onFormatconsultationCardiologyDetailsList(consultationCardiologyDetailsList: any) {
		for (let i = 0; i < consultationCardiologyDetailsList.length; i++) {
			let element = consultationCardiologyDetailsList[i];
			this.onFormatconsultationCardiologyDetails(element);
		}
	}

	onFormatconsultationCardiologyDetails(element: any) {
		let doesDateExist: boolean = element.date !== undefined && element.date !== null ? true : false;
		let consultationCardiologyDetailsSection = {
			sectionName: doesDateExist ? this.translationPipe.transform("lblCardiologyConsultation") + " (" + this.dateUtcTimezonePipe.transform(element.date) + ")" : this.translationPipe.transform("lblCardiologyConsultation"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "cardiologyReasonList", value: this.translationPipe.transform("lblReason") },
				{ key: "evolutionDuration", value: this.translationPipe.transform("lblEvolutionDuration") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
				{ key: "interrogation", value: this.translationPipe.transform("lblInterrogation") }

			],
			viewStructure: [4, 1],
			dataList: [],
		}
		consultationCardiologyDetailsSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(element.date),
			cardiologyReasonList: this.getCardiologyReasonList(element?.cardiologyReasonList),
			evolutionDuration: element?.evolutionDuration,
			comment: this.dateUtcTimezonePipe.transform(element?.comment),
			interrogation: element.interrogation !== "" && element.interrogation !== '<br>' && element.interrogation !== undefined ? '<br>' + element?.interrogation.replace(/<\/?p>/g, "") : undefined
		}
		consultationCardiologyDetailsSection.dataList?.push(data)
		this.dataExportList.push(consultationCardiologyDetailsSection);
	}
		
	onFormatconsultationFertilityDetailsList(consultationFertilityDetailsList: any) {
		for (let i = 0; i < consultationFertilityDetailsList.length; i++) {
			let element = consultationFertilityDetailsList[i];
			this.onFormatconsultationFertilityDetails(element);
		}
	}

	onFormatconsultationFertilityDetails(element: any) {
		let doesDateExist: boolean = element.date !== undefined && element.date !== null ? true : false;
		let consultationFertilityDetailsSection = {
			sectionName: doesDateExist ? this.translationPipe.transform("lblFertilityConsultation") + " (" + this.dateUtcTimezonePipe.transform(element.date) + ")" : this.translationPipe.transform("lblFertilityConsultation"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "fertilityReasonList", value: this.translationPipe.transform("lblReason") },
				{ key: "negativeSignList", value: this.translationPipe.transform("lblNegativeSigns") },
				{ key: "lpd", value: this.translationPipe.transform("lblLpd") },
				{ key: "bloodGroupDeprecated", value: this.translationPipe.transform("lblBloodGroup") },
				{ key: "weightDeprecated", value: this.translationPipe.transform("lblWeight") },
				{ key: "heightDeprecated", value: this.translationPipe.transform("lblHeight") },
				{ key: "bmiDeprecated", value: this.translationPipe.transform("lblBmi") },
				{ key: "interrogation", value: this.translationPipe.transform("lblInterrogation")},

			],
			viewStructure: [5,3,1],
			dataList: [],
		}
		consultationFertilityDetailsSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(element.date),
			fertilityReasonList: this.getFertilityReasonList(element.fertilityReasonList),
			negativeSignList: this.getFertilityReasonList(element.negativeSignList),
			lpd: this.dateUtcTimezonePipe.transform(element.lpd), 
			bloodGroupDeprecated: this.getElementFromList("id", element.bloodGroupDeprecated?.id, BloodGroupEnum.getBloodGroupList(), "", "unique"),
			weightDeprecated: element?.weightDeprecated + ' ' + this.translationPipe.transform("lblUnitKg"),
			heightDeprecated: element?.heightDeprecated + ' ' + this.translationPipe.transform("lblUnitM"),
			bmiDeprecated: element?.bmiDeprecated + '(' + this.translationPipe.transform("lblUnitKgM2") + ')',
		    interrogation: element.interrogation !== "" && element.interrogation !== '<br>' && element.interrogation !== undefined ?  '<br>' + element?.interrogation.replace(/<\/?p>/g, ""): undefined
		}
		consultationFertilityDetailsSection.dataList?.push(data)
		this.dataExportList.push(consultationFertilityDetailsSection);
	}

	onFormatconsultationGynecoDetailsList(consultationGynecoDetailsList: any) {
		for (let i = 0; i < consultationGynecoDetailsList.length; i++) {
			let element = consultationGynecoDetailsList[i];
			this.onFormatconsultationGynecoDetails(element);
		}
	}

	onFormatconsultationGynecoDetails(element: any) {
		let doesDateExist: boolean = element.date !== undefined && element.date !== null ? true : false;
		let consultationGynecoDetailsSection = {
			sectionName: doesDateExist ? this.translationPipe.transform("lblGynecoConsultation") + " (" + this.dateUtcTimezonePipe.transform(element.date) + ")" : this.translationPipe.transform("lblGynecoConsultation"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "gynecoReasonList", value: this.translationPipe.transform("lblReason") },
				{ key: "negativeSignList", value: this.translationPipe.transform("lblNegativeSigns") },
				{ key: "evolutionDuration", value: this.translationPipe.transform("lblEvolutionDuration") },
				{ key: "lpd", value: this.translationPipe.transform("lblLpd") },
				{ key: "lastIntercourseDate", value: this.translationPipe.transform("lblLastIntercourseDate") },
				{ key: "bloodGroupDeprecated", value: this.translationPipe.transform("lblBloodGroup") },
				{ key: "bpSystolicDeprecated", value: this.translationPipe.transform("lblBpSystolic") },
				{ key: "bpDiastolicDeprecated", value: this.translationPipe.transform("lblBpDiastolic") },
				{ key: "weightDeprecated", value: this.translationPipe.transform("lblWeight") },
			    { key: "interrogation", value: this.translationPipe.transform("lblInterrogation")}
			],
			viewStructure: [5,5,1],
			dataList: [],
		}
		consultationGynecoDetailsSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(element.date),
			gynecoReasonList: this.getGynecoReasonList(element?.gynecoReasonList),
			negativeSignList: this.getGynecoReasonList(element?.negativeSignList),
			evolutionDuration: element?.evolutionDuration,
			lpd: this.dateUtcTimezonePipe.transform(element?.lpd) ,
			lastIntercourseDate: this.dateUtcTimezonePipe.transform(element?.lastIntercourseDate),
			bloodGroupDeprecated: this.getElementFromList("id", element.bloodGroupDeprecated?.id, BloodGroupEnum.getBloodGroupList(), "", "unique"),
			bpSystolicDeprecated: element?.bpSystolicDeprecated + ' ' + this.translationPipe.transform("lblUnitMmHg"),
			bpDiastolicDeprecated: element?.bpDiastolicDeprecated + ' ' + this.translationPipe.transform("lblUnitMmHg"),
			weightDeprecated: element?.weightDeprecated + this.translationPipe.transform("lblUnitKg"),
			interrogation:  element.interrogation !== "" && element.interrogation !== undefined ?  '<br>' + element?.interrogation.replace(/<\/?p>/g, ""): undefined
		}
		consultationGynecoDetailsSection.dataList?.push(data)
		this.dataExportList.push(consultationGynecoDetailsSection);
	}

	onFormatconsultationPregnancyDetailsList(consultationPregnancyDetailsList: any) {
		for (let i = 0; i < consultationPregnancyDetailsList.length; i++) {
			this.onFormatconsultationPregnancyDetails(consultationPregnancyDetailsList[i]);
		}
	}

	onFormatconsultationPregnancyDetails(consultationPregnancyDetails: any) {
		let doesDateExist: boolean = consultationPregnancyDetails.date !== undefined && consultationPregnancyDetails.date !== null ? true : false;
		let consultationPregnancyDetailsSection = {
			sectionName: doesDateExist ? this.translationPipe.transform("lblPregnancyConsultation") + " (" + this.dateUtcTimezonePipe.transform(consultationPregnancyDetails.date) + ")" : this.translationPipe.transform("lblPregnancyConsultation"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "pregnancyReasonList", value: this.translationPipe.transform("lblReason") },
				{ key: "negativeSignList", value: this.translationPipe.transform("lblNegativeSigns") },
				{ key: "pregnancyReasonDeprecated", value: this.translationPipe.transform("lblReason") },
				{ key: "weeks", value: this.translationPipe.transform("lblWeeks") },
				{ key: "days", value: this.translationPipe.transform("lblDays") },
				{ key: "bpSystolicDeprecated", value: this.translationPipe.transform("lblBpSystolic") },
				{ key: "bpDiastolicDeprecated", value: this.translationPipe.transform("lblBpDiastolic") },
				{ key: "weightDeprecated", value: this.translationPipe.transform("lblWeight") },
				{ key: "labstixDeprecated", value: this.translationPipe.transform("lblLabstix") },
				{ key: "bloodGroupDeprecated", value: this.translationPipe.transform("lblBloodGroup") },
				{ key: "bloodGroupHusbandDeprecated", value: this.translationPipe.transform("lblHusbandBloodGroup") },
				{ key: "interrogation", value: this.translationPipe.transform("lblInterrogation")}
			],
			viewStructure: [5, 5,2, 1],
			dataList: [],
		}
		consultationPregnancyDetailsSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(consultationPregnancyDetails.date),
			pregnancyReasonList: this.getPregnancyReasonList(consultationPregnancyDetails?.pregnancyReasonList),
			negativeSignList: this.getPregnancyReasonList(consultationPregnancyDetails?.negativeSignList),
			pregnancyReasonDeprecated: this.getElementFromList("idPregnancyReason", consultationPregnancyDetails.pregnancyReasonDeprecated?.idPregnancyReason, this.dataSharedService.consultationSettings.pregnancyReasonList, "", "unique"), 
			weeks: consultationPregnancyDetails?.weeks,
			days: consultationPregnancyDetails?.days,
			bpSystolicDeprecated: consultationPregnancyDetails?.bpSystolicDeprecated + ' ' + this.translationPipe.transform("lblUnitMmHg"),
			bpDiastolicDeprecated: consultationPregnancyDetails?.bpDiastolicDeprecated + ' ' + this.translationPipe.transform("lblUnitMmHg"),
			weightDeprecated: consultationPregnancyDetails?.weightDeprecated + ' ' + this.translationPipe.transform("lblUnitKg"),
			labstixDeprecated: consultationPregnancyDetails?.labstixDeprecated,
			bloodGroupDeprecated: this.getElementFromList("id", consultationPregnancyDetails.bloodGroupDeprecated?.id, BloodGroupEnum.getBloodGroupList(), "", "unique"),
			bloodGroupHusbandDeprecated: this.getElementFromList("id", consultationPregnancyDetails.bloodGroupHusbandDeprecated?.id, BloodGroupEnum.getBloodGroupList(), "", "unique"),
			interrogation: (consultationPregnancyDetails.interrogation !== undefined && consultationPregnancyDetails.interrogation !== "") ?  '<br>' + consultationPregnancyDetails?.interrogation.replace(/<\/?p>/g, ""): undefined
		}
		consultationPregnancyDetailsSection.dataList?.push(data)
		this.dataExportList.push(consultationPregnancyDetailsSection);
	}

	 onFormatprescriptionData(prescriptionList: any) {
		let date: any;
		 for (let i = 0; i < prescriptionList.length; i++) {
				if (prescriptionList[i].consultationGeneralMedicine && prescriptionList[i].consultationGeneralMedicine !== null) {
					date = prescriptionList[i].consultationGeneralMedicine.date !== undefined && prescriptionList[i].consultationGeneralMedicine.date !== null ? prescriptionList[i].consultationGeneralMedicine.date : null;
				}
			 	if (prescriptionList[i].consultationCardiology && prescriptionList[i].consultationCardiology !== null) {
				 	date = prescriptionList[i].consultationCardiology.date !== undefined && prescriptionList[i].consultationCardiology.date !== null ? prescriptionList[i].consultationCardiology.date : null;
			 	}
				if (prescriptionList[i].consultationGyneco && prescriptionList[i].consultationGyneco !== null) {
					date = prescriptionList[i].consultationGyneco.date !== undefined && prescriptionList[i].consultationGyneco.date !== null ? prescriptionList[i].consultationGyneco.date : null;
				}
				if (prescriptionList[i].consultationPregnancy && prescriptionList[i].consultationPregnancy !== null) {
					date = prescriptionList[i].consultationPregnancy.date !== undefined && prescriptionList[i].consultationPregnancy.date !== null ? prescriptionList[i].consultationPregnancy.date : null;
				}
				if (prescriptionList[i].consultationFertility && prescriptionList[i].consultationFertility !== null) {
					date = prescriptionList[i].consultationFertility.date !== undefined && prescriptionList[i].consultationFertility.date !== null ? prescriptionList[i].consultationFertility.date : null;
				}
			if (prescriptionList[i].prescriptionDrugList.length > 0) {
				let prescriptionDrugList = prescriptionList[i].prescriptionDrugList;
				 this.OnFormatPrescription(prescriptionDrugList , date);
				}
				
			if(prescriptionList[i].prescriptionInvestigationList.length > 0){
				let prescriptionInvestigationList = prescriptionList[i].prescriptionInvestigationList;
				  this.OnFormatPrescriptionInvestigation(prescriptionInvestigationList, date);
			}
		}
		
		this.OnformatPrescriptionlibreList(prescriptionList);
	}
    
	 OnFormatPrescriptionInvestigation(prescriptionInvestigation: any, date: any) {
		let prescriptionInvestigationSection = {
			sectionName: date !== null ? this.translationPipe.transform("lblPrescriptioInvestigationList") + " (" + this.dateUtcTimezonePipe.transform(date) + ")" : this.translationPipe.transform("lblPrescriptioInvestigationList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "investigation", value: this.translationPipe.transform("lblInvestigation") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") }
			],
			displayHeader: true,
			dataList: [],
		}
		prescriptionInvestigationSection.dataList = [];
		for (let i = 0; i < prescriptionInvestigation.length; i++) {
			let data = {
				investigation: this.getElementFromList("idInvestigation", prescriptionInvestigation[i]?.idInvestigation, this.dataSharedService.consultationSettings.investigationList, "", "unique"),
				comment: prescriptionInvestigation[i]?.comment?.replace(/\n/g, '<br>')
			}
			prescriptionInvestigationSection.dataList.push(data);
		}
		if (prescriptionInvestigationSection.dataList.length > 0) {
			this.dataExportList.push(prescriptionInvestigationSection);
		}
	}

	async OnFormatPrescription(prescription: any, date: any) {
		let drugList = this.getDrugList(this.dataSharedService.consultationSettings.drugList, DrugFormEnum.getDrugFormList());
		let prescriptionSection = {
			sectionName: date !== null ? this.translationPipe.transform("lblPrescriptionDrug") + " (" + this.dateUtcTimezonePipe.transform(date) + ")" : this.translationPipe.transform("lblPrescriptionDrug"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "drug", value: this.translationPipe.transform("lblDrug") },
				{ key: "dosage", value: this.translationPipe.transform("lblDosage") },
				{ key: "duration", value: this.translationPipe.transform("lblDuration") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") }
			],
			displayHeader: true,
			dataList: [],
		}
		prescriptionSection.dataList = [];
		for (let i = 0; i < prescription.length; i++) {
			let data = {
				drug: this.getElementFromList("idDrug", prescription[i]?.idDrug, drugList, "", "unique"),
				dosage: prescription[i]?.dosage,
				duration: prescription[i]?.duration,
				comment: prescription[i]?.comment?.replace(/\n/g, '<br>')
			}
			prescriptionSection.dataList?.push(data);
		}
		if (prescriptionSection.dataList?.length > 0) {
			this.dataExportList.push(prescriptionSection);
		}
	}

	OnformatPrescriptionlibreList(data: any) {
		let date: any
		for (let i = 0; i < data.length; i++) {
			if (data[i].consultationGeneralMedicine && data[i].consultationGeneralMedicine !== null) {
				date = data[i].consultationGeneralMedicine.date !== undefined && data[i].consultationGeneralMedicine.date !== null ? data[i].consultationGeneralMedicine.date : null;
			}
			if (data[i].consultationCardiology && data[i].consultationCardiology !== null) {
				date = data[i].consultationCardiology.date !== undefined && data[i].consultationCardiology.date !== null ? data[i].consultationCardiology.date : null;
			}
			if (data[i].consultationGyneco && data[i].consultationGyneco !== null) {
				date = data[i].consultationGyneco.date !== undefined && data[i].consultationGyneco.date !== null ? data[i].consultationGyneco.date : null;
			}
			if (data[i].consultationPregnancy && data[i].consultationPregnancy !== null) {
				date = data[i].consultationPregnancy.date !== undefined && data[i].consultationPregnancy.date !== null ? data[i].consultationPregnancy.date : null;
			}
			if (data[i].consultationFertility && data[i].consultationFertility !== null) {
				date = data[i].consultationFertility.date !== undefined && data[i].consultationFertility.date !== null ? data[i].consultationFertility.date : null;
			}
			if (data[i]?.comment !== undefined || data[i]?.prescriptionModel?.idPrescriptionModel !== undefined) {
				this.OnformatPrescriptionlibre(data[i], date);
			}
		}
	}

	OnformatPrescriptionlibre(prescriptionLibre: any, date: any) {
		let prescriptionlibreSection = {
			sectionName: date !== null ? this.translationPipe.transform("lblPrescriptionFreeList") + " (" + this.dateUtcTimezonePipe.transform(date) + ")" : this.translationPipe.transform("lblPrescriptionFreeList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: 'prescriptionModel', value: this.translationPipe.transform("lblPrescriptionModel") },
				{ key: 'comment', value: this.translationPipe.transform("lblComment") }
			],
			viewStructure: [2],
			dataList: [],
		}
		prescriptionlibreSection.dataList = [];
		let dataa = {
			prescriptionModel: this.getElementFromList("idPrescriptionModel", prescriptionLibre?.prescriptionModel.idPrescriptionModel, this.dataSharedService.consultationSettings.prescriptionModelList, "", "unique"),
			comment: prescriptionLibre?.comment?.replace(/\n/g, '<br>')
		}
		prescriptionlibreSection?.dataList.push(dataa);
		this.dataExportList.push(prescriptionlibreSection);
	}

	onFormatphysicalExaminationList(physicalExaminationList: any) {
		for (let i = 0; i < physicalExaminationList.length; i++) {
			this.onFormatphysicalExamination(physicalExaminationList[i]);
		}
	}

	onFormatphysicalExamination(element: any) {
		let physicalExaminationSection = { sectionName: "", dataList: [], style: { fontStyle: 'bold' }, columnHeader: [], viewStructure: [] };
		switch(this.dataSharedService.appId) {
			case AppEnum.GYNECO.id:
				let date: any;
				if (element.consultationGyneco && element.consultationGyneco !== null) {
					date = element.consultationGyneco.date !== undefined && element.consultationGyneco.date !== null ? element.consultationGyneco.date : null;
				}
				if (element.consultationPregnancy && element.consultationPregnancy !== null) {
					date = element.consultationPregnancy.date !== undefined && element.consultationPregnancy.date !== null ? element.consultationPregnancy.date : null;
				}
				if (element.consultationFertility && element.consultationFertility !== null) {
					date = element.consultationFertility.date !== undefined && element.consultationFertility.date !== null ? element.consultationFertility.date : null;
				}
				physicalExaminationSection.sectionName = date !== null 
					? this.translationPipe.transform("lblPhysicalExamination") + " (" + this.dateUtcTimezonePipe.transform(date) + ")"
					: this.translationPipe.transform("lblPhysicalExamination");
				physicalExaminationSection.columnHeader = [
						{ key: "weight", value: this.translationPipe.transform("lblWeight") },
						{ key: "height", value: this.translationPipe.transform("lblHeight") },
						{ key: "bmi", value: this.translationPipe.transform("lblBmi") },
						{ key: "labstix", value: this.translationPipe.transform("lblLabstix") },
		                { key: "bpSystolic", value: this.translationPipe.transform("lblBpSystolic") },
						{ key: "bpDiastolic", value: this.translationPipe.transform("lblBpDiastolic") },
						{ key: "cervix", value: this.translationPipe.transform("lblCervix") },
						{ key: "perineum", value: this.translationPipe.transform("lblPerineum") },
						{ key: "vagina", value: this.translationPipe.transform("lblVagina") },
						{ key: "breast", value: this.translationPipe.transform("lblBreast") },
						{ key: "abdomen", value: this.translationPipe.transform("lblAbdomen") },
						{ key: "comment", value: this.translationPipe.transform("lblComment") },
				];
				physicalExaminationSection.viewStructure = [6,5,1];
				break;
			case AppEnum.CARDIOLOGY.id:
				physicalExaminationSection.sectionName = element.consultationCardiology.date !== null
					? this.translationPipe.transform("lblPhysicalExamination") + " (" + this.dateUtcTimezonePipe.transform(element.consultationCardiology.date) + ")"
					: this.translationPipe.transform("lblPhysicalExamination");
				physicalExaminationSection.columnHeader = [
					{ key: "weight", value: this.translationPipe.transform("lblWeight") },
					{ key: "height", value: this.translationPipe.transform("lblHeight") },
					{ key: "bmi", value: this.translationPipe.transform("lblBmi") },
					{ key: "labstix", value: this.translationPipe.transform("lblLabstix") },
					{ key: "bpSystolic", value: this.translationPipe.transform("lblBpSystolic") },
					{ key: "bpDiastolic", value: this.translationPipe.transform("lblBpDiastolic") },
					{ key: "comment", value: this.translationPipe.transform("lblComment") },
				];
				physicalExaminationSection.viewStructure = [6, 1];
				break;
			default:
				physicalExaminationSection.sectionName = element.consultationGeneralMedicine.date !== null 
					? this.translationPipe.transform("lblPhysicalExamination") + " (" + this.dateUtcTimezonePipe.transform(element.consultationGeneralMedicine.date) + ")" 
					: this.translationPipe.transform("lblPhysicalExamination");
				physicalExaminationSection.columnHeader = [
						{ key: "weight", value: this.translationPipe.transform("lblWeight") },
						{ key: "height", value: this.translationPipe.transform("lblHeight") },
						{ key: "bmi", value: this.translationPipe.transform("lblBmi") },
						{ key: "labstix", value: this.translationPipe.transform("lblLabstix") },
		                { key: "bpSystolic", value: this.translationPipe.transform("lblBpSystolic") },
						{ key: "bpDiastolic", value: this.translationPipe.transform("lblBpDiastolic") },
						{ key: "comment", value: this.translationPipe.transform("lblComment") },
				];
				physicalExaminationSection.viewStructure = [6,1];
				break;
		}
		physicalExaminationSection.dataList = [];
		let data = {
			weight: element?.weight + ' ' + this.translationPipe.transform("lblUnitKg"),
			height: element?.height + ' ' + this.translationPipe.transform("lblUnitM"),
			bmi: element?.bmi + '(' + this.translationPipe.transform("lblUnitKgM2") + ')',
			labstix: element?.labstix,
			bpSystolic: element?.bpSystolic + ' ' + this.translationPipe.transform("lblUnitMmHg"),
			bpDiastolic: element?.bpDiastolic + ' ' + this.translationPipe.transform("lblUnitMmHg"),
			cervix: this.getElementFromList("id", element.cervix?.id, StatusEnum.getStatusList(), "", "unique"),
			perineum: this.getElementFromList("id", element.perineum?.id, StatusEnum.getStatusList(), "", "unique"),
			vagina: this.getElementFromList("id", element.vagina?.id, StatusEnum.getStatusList(), "", "unique"),
			breast: this.getElementFromList("id", element.breast?.id, StatusEnum.getStatusList(), "", "unique"),
			abdomen: this.getElementFromList("id", element.abdomen?.id, StatusEnum.getStatusList(), "", "unique"),
			comment: element?.comment?.replace(/\n/g, '<br>')
		}
		physicalExaminationSection.dataList?.push(data);
		this.dataExportList.push(physicalExaminationSection);
	}

	onFormatHysterosalpingographyList(hysterosalpingography: any) {
		for (let i = 0; i < hysterosalpingography.length; i++) {
			this.onFormatHysterosalpingography(hysterosalpingography[i]);
		}
	}

	onFormatHysterosalpingography(hysterosalpingography: any) {
		let hysterosalpingographySection = {
			sectionName: this.translationPipe.transform("lblFertilityHysterosalpingographyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "fertilityCervixList", value: this.translationPipe.transform("lblFertilityCervix") },
				{ key: "fertilityUterusList", value: this.translationPipe.transform("lblFertilityUterus") },
				{ key: "fertilityRightTubeList", value: this.translationPipe.transform("lblFertilityRightTube") },
				{ key: "fertilityLeftTubeList", value: this.translationPipe.transform("lblFertilityLeftTube") },
				{ key: "peritonealSpill", value: this.translationPipe.transform("lblPeritonealSpill") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [4, 3],
			dataList: [],
		}
		hysterosalpingographySection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(hysterosalpingography.date),
			fertilityCervixList: this.getHystérosalpingographieList(hysterosalpingography?.fertilityCervixList),
			fertilityUterusList: this.getHystérosalpingographieList(hysterosalpingography?.fertilityUterusList),
			fertilityRightTubeList: this.getHystérosalpingographieList(hysterosalpingography?.fertilityRightTubeList),
			fertilityLeftTubeList: this.getHystérosalpingographieList(hysterosalpingography?.fertilityLeftTubeList),
			peritonealSpill: this.getElementFromList("id", hysterosalpingography.peritonealSpill?.id, StatusEnum.getStatusList(), "", "unique"),
			comment: hysterosalpingography?.comment?.replace(/\n/g, '<br>')
		}
		hysterosalpingographySection.dataList?.push(data)
		this.dataExportList.push(hysterosalpingographySection);
	}

	onFormatSpermogramList(spermogramData: any) {
		for (let i = 0; i < spermogramData.length; i++) {
			this.onFormatSpermogram(spermogramData[i]);
		}
	}

	onFormatSpermogram(spermogram: any) {
		let spermogramSection = {
			sectionName: this.translationPipe.transform("lblFertilitySpermogramList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "sexualAbstinenceDays", value: this.translationPipe.transform("lblSexualAbstinence") },
				{ key: "ph", value: this.translationPipe.transform("lblPh") },
				{ key: "volume", value: this.translationPipe.transform("lblVolume") },
				{ key: "cencentrationMillion", value: this.translationPipe.transform("lblCencentration") },
				{ key: "countSpermogram", value: this.translationPipe.transform("lblCountSpermogram") },
				{ key: "progressiveMotility", value: this.translationPipe.transform("lblProgressiveMotility") },
				{ key: "totalMotility", value: this.translationPipe.transform("lblTotalMotility") },
				{ key: "roundCellConcentration", value: this.translationPipe.transform("lblRoundCellConcentration") },
				{ key: "leucocytesConcentration", value: this.translationPipe.transform("lblLeucocytesConcentration") },
				{ key: "morphology", value: this.translationPipe.transform("lblMorphology") },
				{ key: "morphologyStandard", value: this.translationPipe.transform("lblMorphologyStandard") },
				{ key: "vitality", value: this.translationPipe.transform("lblVitality") },
				{ key: "tms", value: this.translationPipe.transform("lblTms") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			viewStructure: [4, 4, 4, 3],
			dataList: [],
		}
		spermogramSection.dataList = [];
		let data = {
			date: this.dateUtcTimezonePipe.transform(spermogram.date),
			sexualAbstinenceDays: spermogram?.sexualAbstinenceDays,
			ph: spermogram?.ph,
			volume: spermogram?.volume + ' ' + this.translationPipe.transform("lblUnitMl"),
			cencentrationMillion: spermogram?.cencentrationMillion + '(' + this.translationPipe.transform("lblUnitMillionsMl") + ')',
			countSpermogram: spermogram?.countSpermogram + ' ' + this.translationPipe.transform("lblUnitMillions"),
			progressiveMotility: spermogram?.progressiveMotility + '%',
			totalMotility: spermogram?.totalMotility + '%',
			roundCellConcentration: spermogram?.roundCellConcentration + '(' + this.translationPipe.transform("lblUnitMillionsMl") + ')',
			leucocytesConcentration: spermogram?.leucocytesConcentration + '(' + this.translationPipe.transform("lblUnitMillionsMl") + ')',
			morphology: spermogram?.morphology + '%',
			morphologyStandard: this.getElementFromList("id", spermogram.morphologyStandard?.id, MorphologyStandardEnum.getMorphologyStandardList(), "", "unique"),
			vitality: spermogram?.vitality + '%',
			tms: spermogram?.tms + ' ' + this.translationPipe.transform("lblUnitMillions"),
			comment: spermogram?.comment?.replace(/\n/g, '<br>')
		}
		spermogramSection.dataList?.push(data);
		this.dataExportList.push(spermogramSection);
	}

	onFormatgeneticData(geneticData: any) {
		let geneticSection = {
			sectionName: this.translationPipe.transform("lblPregnancyGeneticList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "pregnancyGeneticType", value: this.translationPipe.transform("lblType") },
				{ key: "pregnancyGeneticSample", value: this.translationPipe.transform("lblSample") },
				{ key: "doctor", value: this.translationPipe.transform("lblDoctor") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") }],
			displayHeader: true,
			dataList: []
		}
		geneticData.sort((a, b) => a.date - b.date);
		for (let i = 0; i < geneticData.length; i++) {
			let element = geneticData[i];
			let data = {
				date: this.dateUtcTimezonePipe.transform(element.date),
				pregnancyGeneticType: this.getElementFromList("idPregnancyGeneticType", element.pregnancyGeneticType?.idPregnancyGeneticType, this.dataSharedService.consultationSettings.pregnancyGeneticTypeList, "", "unique"),
				pregnancyGeneticSample: this.getElementFromList("idPregnancyGeneticSample", element.pregnancyGeneticSample?.idPregnancyGeneticSample, this.dataSharedService.consultationSettings.pregnancyGeneticSampleList, "", "unique"),
				doctor: element?.doctor,
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			geneticSection.dataList?.push(data)
		}
		this.dataExportList.push(geneticSection);
	}

	onFormatcytologyPathologyData(cytologyPathologyData: any) {
		let cytologyPathologySection = {
			sectionName: this.translationPipe.transform("lblCytologyPathologyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "cytologyPathologyType", value: this.translationPipe.transform("lblType") },
				{ key: "conclusion", value: this.translationPipe.transform("lblConclusion") },
			],
			displayHeader: true,
			dataList: []
		}
		cytologyPathologyData.sort((a, b) => a.date - b.date);
		for (let i = 0; i < cytologyPathologyData.length; i++) {
			let element = cytologyPathologyData[i];
			let data = {
				date: this.dateUtcTimezonePipe.transform(element.date),
				cytologyPathologyType: this.getElementFromList("idCytologyPathologyType", element.cytologyPathologyType?.idCytologyPathologyType, this.dataSharedService.consultationSettings.cytologyPathologyTypeList, "", "unique"),
				conclusion: element?.conclusion
			}
			cytologyPathologySection.dataList?.push(data)
		}
		this.dataExportList.push(cytologyPathologySection);
	}

	onFormatbiologyData(biologyData: any) {
		let biologySection = {
			sectionName: this.translationPipe.transform("lblBiologyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "biologyType", value: this.translationPipe.transform("lblType") },
				{ key: "level", value: this.translationPipe.transform("lblLevel") },
				{ key: "interpretation", value: this.translationPipe.transform("lblInterpretation") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") }],
			displayHeader: true,
			dataList: []
		}
		biologyData.sort((a, b) => a.date - b.date);
		for (let i = 0; i < biologyData.length; i++) {
			let element = biologyData[i];
			let data = {
				date: this.dateUtcTimezonePipe.transform(element.date),
				biologyType: this.getElementFromList("idBiologyType", element.biologyType?.idBiologyType, this.dataSharedService.consultationSettings.biologyTypeList, "Biology", "unique"),
				level: element?.level,
				interpretation: this.getElementFromList("id", element.interpretation?.id, BiologyInterpretationEnum.getBiologyInterpretationList(), "", "getInterpretation"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			biologySection.dataList?.push(data)
		}
		this.dataExportList.push(biologySection);
	}

	onFormatbacteriologyData(bacteriologyData: any) {
		let bacteriologySection = {
			sectionName: this.translationPipe.transform("lblBacteriologyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [{ key: "date", value: this.translationPipe.transform("lblDate") },
			{ key: "bacteriologyType", value: this.translationPipe.transform("lblType") },
			{ key: "interpretation", value: this.translationPipe.transform("lblInterpretation") },
			{ key: "bacteriologyGermList", value: this.translationPipe.transform("lblGermList") },
			{ key: "comment", value: this.translationPipe.transform("lblComment") },
			],
			displayHeader: true,
			dataList: []
		}
		bacteriologyData.sort((a, b) => a.date - b.date);
		for (let i = 0; i < bacteriologyData.length; i++) {
			let element = bacteriologyData[i];
			let data = {
				date: this.dateUtcTimezonePipe.transform(element?.date),
				bacteriologyType: this.getElementFromList("idBacteriologyType", element.bacteriologyType?.idBacteriologyType, this.dataSharedService.consultationSettings.bacteriologyTypeList, "", "unique"),
				interpretation: this.getElementFromList("id", element.interpretation?.id, BacteriologyInterpretationEnum.getBacteriologyInterpretationList(), "", "getInterpretation"),
				bacteriologyGermList: this.getBacteriologieGerm(element?.bacteriologyGermList),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			bacteriologySection.dataList?.push(data)
		}
		this.dataExportList.push(bacteriologySection);
	}

	onFormatserologyData(serologyData: any) {
		let serologySection = {
			sectionName: this.translationPipe.transform("lblSerologyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "serologyType", value: this.translationPipe.transform("lblType") },
				{ key: "level", value: this.translationPipe.transform("lblLevel") },
				{ key: "interpretation", value: this.translationPipe.transform("lblInterpretation") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") }],
			displayHeader: true,
			dataList: []
		}
		serologyData.sort((a, b) => a.date - b.date);
		for (let i = 0; i < serologyData.length; i++) {
			let element = serologyData[i];
			let data = {
				date: this.dateUtcTimezonePipe.transform(element.date),
				serologyType: this.getElementFromList("idSerologyType", element.serologyType?.idSerologyType, this.dataSharedService.consultationSettings.serologyTypeList, "", "unique"),
				level: element?.level,
				interpretation: this.getElementFromList("id", element.interpretation?.id, SerologyInterpretationEnum.getSerologyInterpretationList(), "", "getInterpretation"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			serologySection.dataList?.push(data)
		}
		this.dataExportList.push(serologySection);
	}

	onFormatradiologyData(radiologyData: any) {
		let radiologySection = {
			sectionName: this.translationPipe.transform("lblRadiologyList"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "date", value: this.translationPipe.transform("lblDate") },
				{ key: "radiologyType", value: this.translationPipe.transform("lblType") },
				{ key: "doctor", value: this.translationPipe.transform("lblDoctor") },
				{ key: "conclusion", value: this.translationPipe.transform("lblConclusion") },
				{ key: "comment", value: this.translationPipe.transform("lblComment") }],
			displayHeader: true,
			dataList: []
		}
		radiologyData.sort((a, b) => a.date - b.date);
		for (let i = 0; i < radiologyData.length; i++) {
			let element = radiologyData[i];
			let data = {
				date: this.dateUtcTimezonePipe.transform(element.date),
				radiologyType: this.getElementFromList("idRadiologyType", element.radiologyType?.idRadiologyType, this.dataSharedService.consultationSettings.radiologyTypeList, "", "unique"),
				doctor: radiologyData[i]?.doctor,
				conclusion: this.getElementFromList("id", element.conclusion?.id, StatusEnum.getStatusList(), "", "getInterpretation"),
				comment: element?.comment?.replace(/\n/g, '<br>')
			}
			radiologySection.dataList?.push(data)
		}
		this.dataExportList.push(radiologySection);
	}

	getElementFromList(idProprety: string, id: number, dataList: any[], dataStructure: string, methodName: string) {
		if (methodName === "unique") {
			let index = dataList.findIndex((element: any) => {
				return element[idProprety] === id;
			})
			if (dataStructure === "Biology" && index !== -1) {
				let nameFormat = this.translationPipe.transform(dataList[index]?.name);
				let unitFormat = this.translationPipe.transform(dataList[index]?.unit);
				return nameFormat + "(" + unitFormat + ")";
			}
			if (index !== -1) {
				return this.translationPipe.transform(dataList[index].name);
			}
			return undefined;
		}
		if (methodName === "getInterpretation") {
			let index = dataList.findIndex(element => {
				return element[idProprety] === id
			})
			if (index !== -1) {
				return this.translationPipe.transform(dataList[index].name);
			}
			return undefined;
		}
	}

	getBacteriologieGerm(germList: BacteriologyGerm[]) {
		if (germList.length === 0 || germList === undefined) {
			return undefined;
		}
		let germs = ""
		let bacteriologyGermList = this.dataSharedService.consultationSettings.bacteriologyGermList;
		germList.forEach(element => {
			let index = bacteriologyGermList.findIndex(germ => {
				return germ.idBacteriologyGerm === element.idBacteriologyGerm
			})
			if (index !== -1) {
				germs += this.translationPipe.transform(bacteriologyGermList[index].name) + "," + '<br/>';
			}
		});
		if (germs !== "") {
			germs = germs.trim().slice(0, germs.lastIndexOf(','));
			return germs;
		}
		return undefined;
	}

	getoperativeReportTypeList(reportList: OperativeReportType[]) {
		if (reportList.length === 0 || reportList === undefined) {
			return undefined;
		}
		let types = "";
		let reportTypeList = this.dataSharedService.consultationSettings.operativeReportTypeList;
		reportList.forEach(element => {
			let index = reportTypeList.findIndex(type => {
				return type.idOperativeReportType === element.idOperativeReportType
			})
			if (index !== -1) {
				types += this.translationPipe.transform(reportTypeList[index].name) + "," + '<br/>';
			}
		});
		if (types !== "") {
			types = types.trim().slice(0, types.lastIndexOf(','));
			return types;
		}
		return undefined;
	}

	getHystérosalpingographieList(dataList: any) {
		if (dataList.length === 0 || dataList === undefined) {
			return undefined;
		}
		let value = "";
		dataList.forEach(element => {
			value += this.translationPipe.transform(element.name) + "," + '<br/>';
		}
		);
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}

	getPelvicMassPresumptionList(presumptionList: GynecoPelvicMassPresumption[]) {
		if (presumptionList.length === 0 || presumptionList === undefined) {
			return undefined;
		}
		let value = "";
		let pelvicMassPresumptionList = this.dataSharedService.consultationSettings.gynecoPelvicMassPresumptionList;
		presumptionList.forEach(element => {
			let index = pelvicMassPresumptionList.findIndex(value => {
				return value.idGynecoPelvicMassPresumption === element.idGynecoPelvicMassPresumption
			})
			if (index !== -1) {
				value += this.translationPipe.transform(pelvicMassPresumptionList[index].name) + ",";
			}
		});
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}

	getGeneralMedicineReasonList(generalMedicineReasonList: any) {
		if (generalMedicineReasonList.length === 0 || generalMedicineReasonList === undefined) {
			return undefined;
		}
		let value = "";
		let data = this.dataSharedService.generalMedicineSettings.generalMedicineReasonList;
		generalMedicineReasonList.forEach(element => {
			let index = data.findIndex(value => {
				return value.idGeneralMedicineReason === element.idGeneralMedicineReason
			})
			if (index !== -1) {
				value += this.translationPipe.transform(data[index].name) + ",";
			}
		});
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}
	
	getCardiologyReasonList(cardiologyReasonList: any) {
		if (cardiologyReasonList.length === 0 || cardiologyReasonList === undefined) {
			return undefined;
		}
		let value = "";
		let data = this.dataSharedService.cardiologySettings.cardiologyReasonList;
		cardiologyReasonList.forEach(element => {
			let index = data.findIndex(value => {
				return value.idCardiologyReason === element.idCardiologyReason
			})
			if (index !== -1) {
				value += this.translationPipe.transform(data[index].name) + ",";
			}
		});
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}

	getGynecoReasonList(gynecoReasonList: any) {
		if (gynecoReasonList.length === 0 || gynecoReasonList === undefined) {
			return undefined;
		}
		let value = "";
		let data = this.dataSharedService.gynecoSettings.gynecoReasonList;
		gynecoReasonList.forEach(element => {
			let index = data.findIndex(value => {
				return value.idGynecoReason === element.idGynecoReason
			})
			if (index !== -1) {
				value += this.translationPipe.transform(data[index].name) + ",";
			}
		});
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}
	
	getPregnancyReasonList(pregnancyReasonList: any) {
		if (pregnancyReasonList.length === 0 || pregnancyReasonList === undefined) {
			return undefined;
		}
		let value = "";
		let data = this.dataSharedService.consultationSettings.pregnancyReasonList;
		pregnancyReasonList.forEach(element => {
			let index = data.findIndex(value => {
				return value.idPregnancyReason === element.idPregnancyReason
			})
			if (index !== -1) {
				value += this.translationPipe.transform(data[index].name) + ",";
			}
		});
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}
	
	getFertilityTherapeutiqueList(fertilityTherapeutiqueList: any) {
		if (fertilityTherapeutiqueList.length === 0 || fertilityTherapeutiqueList === undefined) {
			return undefined;
		}
		let value = "";
		let data = this.dataSharedService.fertilitySettings.fertilityTherapeutiqueList;
		fertilityTherapeutiqueList.forEach(element => {
			let index = data.findIndex(value => {
				return value.idFertilityTherapeutique === element.idFertilityTherapeutique
			})
			if (index !== -1) {
				value += this.translationPipe.transform(data[index].name) + ",";
			}
		});
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}
	
	getFertilityReasonList(fertilityReasonList: any) {
		if (fertilityReasonList.length === 0 || fertilityReasonList === undefined) {
			return undefined;
		}
		let value = "";
		let data = this.dataSharedService.fertilitySettings.fertilityReasonList;
		fertilityReasonList.forEach(element => {
			let index = data.findIndex(value => {
				return value.idFertilityReason === element.idFertilityReason
			})
			if (index !== -1) {
				value += this.translationPipe.transform(data[index].name) + ",";
			}
		});
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}

	getOvairePresumptionList(presumptionList: any) {
		if (presumptionList.length === 0 || presumptionList === undefined) {
			return undefined;
		}
		let value = "";
		let data = this.dataSharedService.consultationSettings.gynecoCystOvaryList;
		presumptionList.forEach(element => {
			let index = data.findIndex(value => {
				return value.idGynecoCystOvary === element.idGynecoCystOvary
			})
			if (index !== -1) {
				value += this.translationPipe.transform(data[index].name) + ",";
			}
		});
		if (value !== "") {
			value = value.trim().slice(0, value.lastIndexOf(','));
			return value;
		}
		return undefined;
	}

	getDrugList(drugList: any[], drugFormList: Data[]) {
		if (drugFormList.length == 0 || drugFormList == undefined) {
			return undefined;
		}
		let drugFormMap = {};
		drugFormList.forEach(drugForm => {
			drugFormMap[drugForm.id] = this.translationPipe.transform(drugForm.name);
		});
		return drugList.map(drug => {
			let drugFormLabel = drug.form ? drugFormMap[drug.form.id] : '--';
			let drugUnitDose = drug.unitDose ? drug.unitDose : '--';
			drug.name = drug.tradeName + " ( " + drugFormLabel + " / " + drugUnitDose + " )";
			return drug;
		});
	}
	
	getPregnancyUltrasoundDate(element: any) {
		let doesDateExist: boolean;
		doesDateExist = (element.otherDoctor !== undefined && element.otherDoctor !== null) || (element.dateOtherDoctor !== undefined && element.dateOtherDoctor !== null) ? true : false;
		if (doesDateExist) {
			return null
		}
		if (element?.consultationPregnancy?.date) {
			return element.consultationPregnancy.date;
		}
		else {
			return null;
		}
	}
	
	getGynecoUltrasoundDate(element: any) {
		let doesDateExist: boolean;
		doesDateExist = (element.otherDoctor !== undefined && element.otherDoctor !== null) || (element.dateOtherDoctor !== undefined && element.dateOtherDoctor !== null) || (element.date !== undefined && element.date !== null) ? true : false;
		if (doesDateExist) {
			return null;
		} else {
			if (element?.consultationFertility?.date !== undefined) {
				return element.consultationFertility.date;
			}
			if (element?.consultationGyneco?.date !== undefined) {
				return element.consultationGyneco.date;
			}
		}
		return null;
	}
	
	onFormatCardiologyUltrasoundEmptyList(consultationCardiologyUltrasoundEmptyList: any) {
		for (let i = 0; i < consultationCardiologyUltrasoundEmptyList.length; i++) {
			let element = consultationCardiologyUltrasoundEmptyList[i];
			this.onFormatCardiologyUltrasoundEmpty(element);
		}
	}

	onFormatCardiologyUltrasoundEmpty(element: any) {
		let date: any;
		if (element.consultationCardiology && element.consultationCardiology !== null) {
			date = element.consultationCardiology.date !== undefined && element.consultationCardiology.date !== null ? element.consultationCardiology.date : null;
		}
		let cardiologyUltrasoundEmptySection = {
			sectionName: date !== null && this.generateDefaultData === true ? this.translationPipe.transform("lblCardiologyUltrasoundEmpty") + " (" + this.dateUtcTimezonePipe.transform(date) + ")" : this.translationPipe.transform("lblCardiologyUltrasoundEmpty"),
			style: { fontStyle: 'bold' },
			columnHeader: [
				{ key: "ultrasound", value: this.translationPipe.transform("lblUltrasound") },
				{ key: "ultrasoundRoute", value: this.translationPipe.transform("lblUltrasoundRoute") },
				{ key: "comment", value: undefined }
			],
			viewStructure: [2, 1],
			dataList: [],
		}
		cardiologyUltrasoundEmptySection.dataList = [];
		if (this.generateDefaultData === false) {
			cardiologyUltrasoundEmptySection['generateDefaultData'] = this.generateDefaultData;
		}
		let data = {
			ultrasound: this.getUltrasound(element?.ultrasound),
			ultrasoundRoute: this.getElementFromList("id", element?.ultrasoundRoute?.id, UtrasoundRouteEnum.getUtrasoundRouteList(), "", "unique"),
			comment: element?.comment?.replace(/\n/g, '<br>')
		}
		cardiologyUltrasoundEmptySection.dataList?.push(data)
		if (data.comment !== undefined || data.ultrasound !== undefined || data.ultrasoundRoute !== undefined) {
			this.dataExportList.push(cardiologyUltrasoundEmptySection);
		}
	}

}
