export class AppEnum {

	public static readonly GENERAL_MEDICINE: any = { id: 1, name: "general-medicine", code: "general", prefix: "doc" };
	public static readonly GYNECO: any = { id: 2, name: "gyneco", code: "gyneco", prefix: "gyn" };
	public static readonly PSYCHOLOGY: any = { id: 3, name: "general-medicine", code: "psy", prefix: "psy" };
	public static readonly CARDIOLOGY: any = { id: 4, name: "cardiology", code: "cardiology", prefix: "car" };

    public static getAppList() {
        return [AppEnum.GENERAL_MEDICINE, AppEnum.GYNECO, AppEnum.PSYCHOLOGY, AppEnum.CARDIOLOGY];
    }

}
